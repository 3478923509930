import React from "react";
import Login from "../../Templates/login/login";

import "./login.scss";
const AdminLogin = () => {
  const data = { url: "users/signin", key: "admin" };

  return (
    <>
      <Login data={data} />
    </>
  );
};

export default AdminLogin;
