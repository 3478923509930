import React, { useEffect, useState } from "react";
import { AuthGet} from "../../common_var/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

function AllPatients() {
  const [patientData, setPatientData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPatientList();
  }, []);

  const getPatientList = async () => {
    await AuthGet(`incomplete`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          setPatientData(res?.data);
          setPage(Math.ceil(patientData.length / 10));
          setCurrentPage(1);
        }
      })
  }

  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: '50px',
      }
    },
    { dataField: "firstname", text: 'Name', sort: true, 
    headerStyle: { width: '100px', } }, 
    { dataField: "email", text: 'Email', sort: true, headerStyle: { width: '250px', } }, 
    { dataField: "phno", text: 'Phone Number', sort: true, headerStyle: { width: '150px', } },
     { dataField: "regstatus", text: 'Loan Status', sort: true, headerStyle: { width: '150px', } }, 
     { dataField: "practicename", text: 'Provider', sort: true, headerStyle: { width: '100px', } }, 
     { dataField: "createddate", text: 'Registered on', sort: true, headerStyle: { width: '150px', } }];

  const defaultSorted = [
    {
      dataField: 'firstname',
      order: 'desc'
    },
    {
      dataField: 'email',
      order: 'desc'
    },
    {
      dataField: 'phno',
      order: 'desc'
    },
    {
      dataField: 'regstatus',
      order: 'desc'
    },
    {
      dataField: 'practicename',
      order: 'desc'
    },
    {
      dataField: 'createddate',
      order: 'desc'
    }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
     }
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (<div>
    <div className="breadcrumbsection">
      <div>
        <div className="titleText "> Manage Contacts</div>
      </div>
    </div>

    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={patientData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  </div>);
}

export default AllPatients;







