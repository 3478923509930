import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { AuthFile, AuthGet, AuthPost } from "../../common_var/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import CustomCustomerModal from "../../Components/Modal/Customer-Modal.jsx";
import Toast from '../../Components/Toaster/Toast';
import { toast } from 'react-hot-toast';
import SmalleyeIcon from "../../assets/images/smalleye_icon.svg";
import SmalldeleteIcon from "../../assets/images/smallDelete_icon.svg";
import ClouduploadIcon from "../../assets/images/cloudUpload_icon.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from "react-bootstrap/Modal";
const UploadDoc = () => {

    const [userData, setUserData] = useState([]);
    const queryParams = useLocation()
    let params = queryParams.pathname.split('/');
    const merchant_id = params[params.length - 1];


    const [docId, setDocId] = useState([]);
    const [dynamicClass, setDynamicClass] = useState(false);
    const [upfile, setUpfile] = useState([]);
    const [show, setShow] = useState(false);
    const [filedoc, setFiledoc] = useState([]);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setUpfile([])
        formikUploadDoc.resetForm();
        setShow(false)
    };
    useEffect(() => {
        getlist();
    }, [queryParams]);

    useEffect(() => {
        getDocumentId();
    }, [])

    const getlist = () => {
        console.log("params Id: ", merchant_id);
        // console.log("stage: ", loanStage);

        AuthGet(`files/getdocuments/customer/${merchant_id}`, "admin")
            .then((res) => {
                if (res["statusCode"] == 200) {
                    // toast.success("Document Uploaded");
                    setUserData(res.data.files);

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const formikUploadDoc = useFormik({
        initialValues: ({
            docType: ""
        }),
        validationSchema: Yup.object({
            docType: Yup.string()
                .required('Please select document type')
        }),
        onSubmit: (values) => {
            console.log("Doc type: ", values.docType);
            if (values) {
                // setDoctype(values.docType);
                handleClose();
                subDoctype(values.docType);
                formikUploadDoc.resetForm();
            }
        }
    })
    const subDoctype = (doctype) => {
        console.log("Doc Type: ", doctype);
        if (doctype !== "") {
            let data = {
                files: upfile,
                documentsTypes: doctype,
            };

            setFiledoc([...filedoc, data]);
            handleClose();
        } else {
            console.log("Doc type not selected!");
            alert("please select doc type");
        }
    };
    const removeDocument = (name, index) => {
        console.log(name)
        setFiledoc(filedoc.filter((item, i) => (i != index)));
    }
    const view = (filename, key) => {
        console.log(filename);

        console.log("Admin key: ", key);
        if (filename) {
            filename = filename?.split("/");
            filename = filename[filename.length - 1];
          let a=  filename = `${filename}`;
        console.log(a)
            window.open(
                key == 'admin' ? process.env.REACT_APP_ADMIN_API_URL + "/files/download/" + filename : process.env.REACT_APP_BORROWER_API_URL + "/files/download/" + filename,
                "_blank"
            );
        }
    };

    const documentDelete = async (data) => {
       
       let senddata={
        documentId:data
      }   
      AuthPost(`uploadfiles/deletefile`,senddata,  "admin")
      .then((res)=>{
        if(res.statusCode==200){
            toast.success(res.message);  
            getlist()
        }
      console.log(res)
      })
       
    }
    const handleimage = (e) => {
        setDynamicClass(false)
        console.log("Inside handle image");
        console.log(e.target.files);
        const dimg = [e.target.files[0]]
        setUpfile(e.target.files[0]);
        console.log(dimg)
        if (e.target.files[0]) {
            handleShow();
        }
        e.target.value = ''
    };
    console.log(upfile);

    
//   const completed1 = () => {

//     let docId;
//     console.log(type);
//     if (type && type == "merchant") {
//       const result = doc.filter((e) => {
//         return e.type === doctype;
//       });
//       console.log(result[0].id, result[0].type);
//       docId = result[0].id;
//     } else {
//       docId = doctype === "Passport" ? doc[0].id : doc[1].id;
//     }
//   }


  const completed = async () => {

    
    const formData = new FormData();
    let did=JSON.stringify(docId)
    did=JSON.parse(did)
     let result=[]
     const lengthcheck=filedoc.length
    for (var i = 0; i < filedoc.length; i++) {
       did.filter((e) => {
            if( e.type === filedoc[i]['documentsTypes']){
                result.push(e)
               
            }
           return;
          });
          let document_type=[] 
          document_type.push(result[i]["id"]);
          if (filedoc[i].documentsTypes === "") {
            console.log("file doc---->", filedoc[i].documentsTypes);
            filedoc[i].documentsTypes = "";
            alert(
                `cannot upload, select document type for` + filedoc[i].documentsTypes
            );
            return false;
        }
        formData.append("files", filedoc[i].files);
       if(1==lengthcheck){
         formData.append("documentTypesID", (document_type));
         formData.append("documentTypesID", (document_type));
        
       }else{
        formData.append("documentTypesID", (document_type));
       }
    }
   
    formData.append("link_ID", merchant_id);
 

    if (filedoc.length > 0) {
        setDynamicClass(false)
        for (var i = 0; i < filedoc.length; i++) {
            if (filedoc[i].documentsTypes === "") {
                console.log("file doc---->", filedoc[i].documentsTypes);
                filedoc[i].documentsTypes = "";
                alert(
                    `cannot upload, select document type for` + filedoc[i].documentsTypes
                );
                return false;
            }
        }
        await AuthFile(`uploadfiles/uploads`, formData, "admin")
            .then((res) => {
                console.log(res);
                if (res.statusCode == 200) {
                    setFiledoc([])
                    getlist();
                    toast.success("Document Uploaded Sucessfully");
                }
                if (res.data != undefined) {
                    if (res.data.statusCode >= 400 && res.data.statusCode <= 410) {
                        toast.error(res.data.message);
                    }
                }
                console.log("res---->", res);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    else {
        toast.error('Please Upload a file')
        setDynamicClass(true)
    }
};




    const getDocumentId = () => {
        AuthGet('files/documenttype/merchant', "admin")
            .then((res) => {
                console.log("Doc Id: ", res);
                setDocId(res.data);
            })
            .catch((err) => {
                console.log("Doc Id error: ", err);
            });
    }

    // const viewdoc = (filename) => {
    //     console.log("File name: ", filename);
    //     let url = `files/download/${filename}`;

    //      AuthGet(`${url}`, 'admin')
    //     .then((res) => {
    //       console.log(res);
    //       const a = document.createElement('a');
    //       const file = new Blob([res], { type: 'application/pdf' });
    //       const objectUrl = URL.createObjectURL(file);
    //       a.href = objectUrl;
    //       a.download = filename;
    //       a.click()
    //       URL.revokeObjectURL(objectUrl);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };



    const viewdoc = (filename) => {
        console.log(filename);
        if (filename) {
            filename = filename?.split("/");
            filename = filename[filename.length - 1];

            window.open(
                process.env.REACT_APP_ADMIN_API_URL + "/files/download/" + filename,
                "_blank"
            );
        }
    };

    const columns = [
        {
            dataField: "", text: 'S.NO',
            formatter: (e, column, columnIndex, row, rowIndex) => {
                return ++columnIndex;
            },
            headerStyle: {
                width: "100px"
            }
        },
        {
            dataField: "originalname", text: 'Document',
            headerStyle: {

            },
            formatter: (e, column, columnIndex, row, rowIndex) => {
                return column.originalname;

            },
        },
        {
            dataField: "type", text: 'Document Type',
            headerStyle: {
                width: "150px"
            },
            formatter: (e, column, columnIndex, row, rowIndex) => {
                return column.type;

            },
        },
        {
            dataField: "filename", text: 'File',
            formatter: () => {
                return "View Document";
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log("doc event: ", row);
                    viewdoc(row.filename.split("/")[2]);
                },
            },
        },
    ];

    return (
        <>
            <Toast />
 {/* <div>
                    <div className='displayFlex spaceBetween AlignItemCenter mb20px'>
                        <h5>Upload Documents</h5>
                        <div>
                            <CustomCustomerModal btnName={"Upload Documents"} data={merchant_id} doc={docId} fun={getlist} type={"merchant"} />
                        </div>
                    </div>

                    {userData?.length > 0 ?
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                data={userData}
                                columns={columns} />
                        </div>
                        :
                        <div></div>
                    }
                </div> */}
            
        
            <div className="whiteFrame p16 mb_24">
                <h3 className="heading18 fontWeight500 mb_20">Uploaded Documents</h3>

            <div className="row">
                    <div className="row mb_24">
                        {userData?.map((d, i) => {

                            return (
                                <div className="col-md-6" key={i}>
                                    <div className="fileDoc_Item">
                                        <div className="fileDoc_left">
                                            <div>
                                                <div className="greenfolder"></div>
                                            </div>
                                            <div>
                                                <h5>{d.type}</h5>
                                                <small style={{ fontSize: "12px" }}>{d.originalname}</small>
                                                {/* <p>128 Kb</p> */}
                                            </div>
                                        </div>
                                        <div className="uploadDocAction">
                                            <button className="viewBtn mb_4" onClick={() => { view(d.filename, 'admin') }}>
                                                <img src={SmalleyeIcon} /> <span>View</span>
                                            </button>
                                            <button className="deleteBtn"  onClick={() => documentDelete(d.id)}>
                                                <img src={SmalldeleteIcon}  /> <span>Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                

                                

                            );
                            
                        })}
                    </div>
                </div>
                
                    <div className="whiteFrame mb_24">
                <div className="whiteFrameHeader">
                    <h3 className="heading18 fontWeight600">Upload Here</h3>
                </div>
                <div className="p16">
                    <div className="textAlignCenter mb_24">
                        <label htmlFor="document_img" className={dynamicClass ? "label-file-upload_err" : "Chooseuploadbox"}>
                            <img src={ClouduploadIcon} className="mb_12" />
                            <input
                                id="document_img"
                                name="image"
                                type="file"
                                onChange={(e) => handleimage(e)}
                                accept=".jpg,.jpeg,.png,application/pdf"
                                hidden
                            />
                            <h2>Click to upload</h2>
                        </label>
                    </div>
                    <div className="spreadsheetWrapper mb_24">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>File Name</th>
                                    <th>File Format</th>
                                    <th>File size</th>
                                    <th>Document Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {(filedoc !== null && filedoc.length > 0) ? (
                                    filedoc?.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className="greenfolder"></div>
                                                </td>
                                                <td>{data.files.name.split(".")[0]}</td>

                                                <td>{data.files.name.split(".")[1]}</td>

                                                <td>{(data.files.size / 1024).toFixed(2)} KB</td>

                                                <td>{data.documentsTypes}</td>
                                                <td>
                                                    <div className="uploadDocAction">
                                                        <button className="deleteBtn" onClick={() => removeDocument(data.files.name, i)}>
                                                            <img src={SmalldeleteIcon} /> <span>Delete</span>
                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className="textAlignCenter" colSpan={5}>
                                            <h5 className="nodataText">No file selected</h5>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="textAlignCenter">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={completed}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </div>
            </div>
           
            <div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    className="addnewpopup"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Select Document Type</Modal.Title> */}
                        <h4 className="heading24_bold">Select Document Type</h4>
                        {/* <div className="DisplayFlex flexWrap_wrap AlignItem_center mobilegap_12">
                    <button className="emptyBtn">
                        <img src="/images/modal_closeIcon.svg" />
                    </button>
                    </div> */}
                    </Modal.Header>
                    <div className="modelScroll">
                        <Modal.Body>
                            <form onSubmit={formikUploadDoc.handleSubmit}>
                                <div>
                                    <select
                                        name="docType"
                                        id="docType"
                                        // onChange={dropDoctype}
                                        className="MainInput"
                                        value={formikUploadDoc.values.docType}
                                        onChange={formikUploadDoc.handleChange}
                                        onBlur={formikUploadDoc.handleBlur}
                                    >
                                        <option value="">Select Document Type</option>
                                        <option value="passport">
                                        Passport
                                        </option>
                                        <option value="national id">National ID</option>
                                        <option value="trade license">Trade License</option>
                                        <option value="owner id ">Owner ID</option>
                                        <option value="other documents">Others</option>
                                    </select>
                                    {formikUploadDoc.touched.docType &&
                                        formikUploadDoc.errors.docType ? (
                                        <p className="error_text">
                                            {formikUploadDoc.errors.docType}
                                        </p>
                                    ) : null}
                                </div>
                                <Modal.Footer>
                                    <button variant="secondary" onClick={handleClose} type='button' className='dangerbutton'>
                                        Close
                                    </button>
                                    <button type='submit' variant="primary" className='addbutton' >
                                        Save Changes
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default UploadDoc
