import React, { useEffect, useState } from "react";
import EmploymentInformation from "../Templates/EmploymentInformation/EmploymentInformation";
import FinancialDetails from "../Templates/FinancialDetails/FinancialDetails";
import PersonalInformation from "../Templates/PersonalInformation/PersonalInformation";
import PropertyOwnership from "../Templates/PropertyOwnership/PropertyOwnership";
import Navbar from "../Components/Navbar/Navbar";
import MultistepProgressbar from "../Components/MultistepProgressbar/MultistepProgressbar";
import Checkbox from "../Components/Checkbox/Checkbox";
import Signature from "../Components/Signature/Signature";
import Button from "../Components/Button/Button";
import { useStateValue } from "../Context/StateProvider";
import { Get } from "../common_var/httpService";
import Plaid from "../Templates/PlaidConnection/Plaid";
import { useParams } from "react-router-dom";
import Offers from "../Templates/Offers/Offers";
import ContractSignature from "../Templates/ContractSignature/ContractSignature";

const Sales = () => {
  const [{ step }, dispatch] = useStateValue();
  const { loanid } = useParams();

  const [steps] = useState([
    {
      id: 1,
      name: "Personal",
      component: <PersonalInformation />,
      content:
        "Furnish your personal details which will help us reach out to you soon  ",
    },
    {
      id: 2,
      name: "Work",
      component: <EmploymentInformation />,
      content:
        "A complete history of employment status is needed from your end",
    },
    {
      id: 3,
      name: "Financials",
      component: <FinancialDetails />,
      content: "Furnish your latest monthly income and mortgage details",
    },
    {
      id: 4,
      name: "Property",
      component: <PropertyOwnership />,
      content: "Select your type of residence & share associated details",
    },
    {
      id: 5,
      name: "Bank Lookup",
      component: <Plaid />,
      content:
        "Connect securely to your Bank for us to generate the best offer for you.",
    },
    {
      id: 6,
      name: "Available Offers",
      component: <Offers />,
      content: "",
    },
    {
      id: 7,
      name: "Acceptance",
      component: <ContractSignature />,
      content: "",
    },
  ]);

  const getStep = async () => {
    try {
      const resp = await Get(`loan/getStage/${loanid}`, "admin");
      if (resp.statusCode === 200) {
        dispatch({
          type: "SET_STEP",
          payload: resp.data[0].step,
        });
        dispatch({
          type: "SET_CURRENT_STEP",
          payload: resp.data[0].step,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (id) => {
    if (id < steps.length && id < step) dispatch({ type: "SET_STEP", payload: id });
  };

  useEffect(() => {
    getStep();
  }, []);

  return (
    <div>
      <Navbar />
      <MultistepProgressbar />

      <div className="mb_40">
        <h2 className="heading30 mb_8 textAlignCenter">
          Your Loan Application
        </h2>
        <p className="paragraphText fontStyle_italic textAlignCenter">
          Please fill in the following sections completely & accurately to apply
          for your loan
        </p>
      </div>
      <div className="container">
        <section className="verticalStepbar">
          {steps.map((ele, index) => (
            <div
              key={index}
              className={`verticalStepbarItem ${
                step === ele.id ? "ready" : step > ele.id ? "completed" : ""
              }`}
            >
              <div className="Stepheader">
                <div className="circle" onClick={() => handleClick(ele.id)}>
                  <span>{ele.id}</span>
                </div>
                <div>
                  <h2>{ele.name}</h2>
                  <p className="fontStyle_italic">{ele.content}</p>
                </div>
              </div>
              <div className="stepbody">
                {index !== steps.length - 1 ? (
                  <div className="line"></div>
                ) : (
                  <></>
                )}
                <div className="stepContent">
                  {step === ele.id ? ele.component : <></>}
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Sales;
