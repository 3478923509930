import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost, AuthPut, AuthFile } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../Toaster/Toast";
import toast from "react-hot-toast";










const Nymcard = () => {
    const navigate = useNavigate();
    const [initialState, dispatch, authState, setAuthState] = useStateValue();

    const[cardData,setCarddata] = useState([])
    const[accountData,setAccountdata] = useState([])
    const[nymcard_user_id,setNymcard_user_id] = useState("")
    const[cardproductsdata,setCardproductsdata] = useState([])
    const[data,setData] = useState([])
    const id = 'd0b5e863-3245-4d29-a3a3-35e06d329127'
    const account_id =  ''
    const paymenttypes = ['PREPAID', 'DEBIT', 'CREDIT', 'SAVINGS']
    var createCarddata = {}
    var createNymcardAcc = {}
    const[productId,setProductId] = useState("")
    const[paymenttypef,setPaymentType]=useState("")
    const nymcard_id = "" ;






    const send = async()=>{

        await AuthGet(`customer/enablenymcard/${id}`,'admin')
        .then((res)=>{
            console.log("res---->",res)
            getCustomers()
        }).catch((err)=>{

            console.log("err---->",err)
        })

    }

  const get= async()=> {
    console.log("this nymcardid",nymcard_user_id,nymcard_id);
        if (nymcard_user_id) {
          getcard();
        }
      }

     const getcard= async()=> {
          await AuthGet(`nymcard/getcard/${id}`,'admin')
        .then((res)=>{
            console.log("res of card---->",res)
            if (res['statusCode'] == 200) {
              setCarddata(res['data']);
                // console.log(this.data);
              }
        }).catch((err)=>{

            console.log("err of cus---->",err)
        })
      }


    const getCustomers = async ()=>{
        await AuthGet(`customer/getcustomer/${id}`,'admin')
        .then((res)=>{
            console.log("res of cus---->",res)
            if (res['statusCode'] == 200) {
                let d = res['data'];
    
                setNymcard_user_id(d[0]['nymcard_user_id']);
                nymcard_id = d[0]['nymcard_user_id']
                get();
              }
        }).catch((err)=>{

            console.log("err of cus---->",err)
        })
    }

    const cardProduct = async () =>{
        
        await AuthGet(`nymcard/cardproducts`,'admin')
        .then((res)=>{
            console.log("res of cus---->",res)
            setCardproductsdata(res.data)
        }).catch((err)=>{

            console.log("err of cus---->",err)
        })
    }

    const createAccount = async (e) =>{
        // this.f2.user = this.nymcard_user_id;
          e.preventDefault()
         createNymcardAcc = {
          customer_id:id,
          product_id:productId,
          user:nymcard_user_id,
          payment_type:paymenttypef
        }
        console.log(createNymcardAcc);
        await AuthPost('nymcard/cardproducts/createAccount', createNymcardAcc, 'admin')
        .then((res)=>{
            console.log("res---->",res);
        })
        .catch((err)=>{
            console.log("err---->",err)
        })
    }

    const getCard = async ()=>{
        await AuthGet(`nymcard/getcard/${id}`,'admin')
        .then((res)=>{
            console.log("res of card---->",res)
        }).catch((err)=>{

            console.log("err of card---->",err)
        })
    }

    const getAccount = async ()=>{
        
        await AuthGet(`nymcard/getaccount/${id}`,'admin')
        .then((res)=>{
            console.log("res of card---->",res)
            if (res['statusCode'] == 200 && res['data'].length > 0) {
                 setAccountdata(res['data']);
                // this.template === 1;
                createCarddata = {
                  nymcard_user_id:res['data'][0].nymcard_user_id,
                  product_id: res['data'][0].product_id,
                  payment_type:res['data'][0].payment_type
                }
                  account_id = res['data'][0].account_id;
               getaccountbalance()
              }
        }).catch((err)=>{

            console.log("err of card---->",err)
        })
    }

    const getaccountbalance = async()=>{
            
              await AuthGet(`nymcard/getaccountbalance/${account_id}`,'admin')
              .then((res)=>{
                  console.log("res of card---->",res)
                  if (res['statusCode'] == 200) {
                    // this.balance = res['data']['balances']
                }
              }).catch((err)=>{
      
                  console.log("err of card---->",err)
              })

    }

    const onCreateCard = async()=>{

      await AuthPost(`nymcard/createcard/${id}`,createCarddata,'admin')
      .then((res)=>{
          console.log("res of card---->",res)
          if (res['statusCode'] == 200) {
            setData(res['data'])
          getcard()
          }
      }).catch((err)=>{

          console.log("err of card---->",err)
      })
    } 

    const   getVelocity = async() =>{
          await AuthGet('nymcard/velocitylimits','admin')
          .then((res)=>{
            // this.data2 = res['data'];
            //     console.log("data recived for velocity",this.data2);
          })
          .catch((err)=>{
            console.log("err--->",err)
          })
      }

    const getnymtrans = async ()=>{

        await AuthGet(`nymcard/getdnymetails/${id}`,'admin')
        .then((res)=>{
            console.log("res---->",res)
            if (res['statusCode'] == 200 && res['data'].length > 0) {
                // this.data3 = res['data'];
                // console.log("d3",this.data3);
              }
        })
        .catch((err)=>{
            console.log("err---->",err)
        })
        }

        const getBalance = async () =>{
    
            await AuthGet(`nymcard/getaccountbalance/${account_id}`,'admin')
            .then((res)=>{
                    console.log("res--->",res)
                    if (res['statusCode'] == 200) {
                        // this.balance = res['data']['balances']
                    }
            })
            .catch((err)=>{
                console.log("err----->",err)
            })
        }
        
        
        const  addvelocity = async(d)=>{
            //
            // this.data1;
            console.log("data1",d);
            console.log("data velocity",d.velocity_id);
            
              await AuthGet(`nymcard/linkcardvelocitylimit/${d.velocity_id}/${this.data[0].card_id}`, 'admin')
              .then((res)=>{
                console.log("res--->",res)
              }).catch((err)=>{
                console.log("err---->",err)
              })
          }

          const handleChangeProduct = (e)=>{
            console.log("handleChangeProduct",e.target.value)
            setProductId(e.target.value)
          }

          const handleChangePayment = (e)=>{
            setPaymentType(e.target.value)
          }

          useEffect(()=>{
            cardProduct()
          },[])



        return (
    <>
      <div className="frameshadow">
        <div>
          <div className="mainTitle">Add Nymcard</div>
        </div>

        <div className="mb50px">
          {/* *ngIf="!nymcard_user_id" */}
          <div
            className="p10px"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            {/* *ngIf="!nymcard_user_id" */}
            <button
              type="button"
              className="btn btn-success btn-user-control"
              onClick={send}
            >
              Enable Nymcard
            </button>
          </div>

      {   nymcard_user_id && cardData.length > 0 && (

          <div className="p10px">
            <div className="list">
              <ul className="h700" style={{ height: "auto!important" }}>
                <li>
                  <div className="displayFlex listdetails">
                    <div className="fontWeight">Product ID</div>
                    <div>
                      {/* routerLink="/admin/nymcard/productmanagement/{{
                    data[0]['product_id']
                  }}" */}
                      <a>{/* { data[0]["product_id"] } */}</a>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="displayFlex listdetails">
                    <div className="fontWeight">Last Four</div>
                    <div>{/* { data[0]["last_four"] } */}</div>
                  </div>
                </li>

                <li>
                  <div className="displayFlex listdetails">
                    <div className="fontWeight">Expiry Date</div>
                    <div>{/* { data[0]["expiry_date"] } */}</div>
                  </div>
                </li>

                <li>
                  <div className="displayFlex listdetails">
                    <div className="fontWeight">Status</div>
                    <div>{/* { data[0]["status"] } */}</div>
                  </div>
                </li>
                <li>
                  <div className="displayFlex listdetails">
                    <div className="fontWeight">Velocity</div>
                    <div>
                      {/* routerLink="/admin/nymcard/velocitylimits/{{
                    data[0]['velocity_id']
                  }}" */}
                      <a>{/* { data[0]["velocity_id"] } */}</a>
                    </div>
                  </div>
                </li>

                {/* *ngFor="let item of balance | keyvalue" */}
                <li>
                  <div className="displayFlex listdetails">
                    <div className="fontWeight">{/* {item.key} */}</div>
                    <div>{/* {item.value} */}</div>
                  </div>
                </li>
                <li>

                {/* onClick={viewCard} */}
                  <button
                  
                    className="btn btn-primary"
                    style={{ marginTop: "10px" }}
                  >
                    View Card Number
                  </button>
                  &nbsp;&nbsp;

                  {/* onClick={() => setvelocity(addvelo)} */}
                  <button
                    className="btn btn-primary"
                    style={{ marginTop: "10px" }}
                  >
                    Set Velocity Limit
                  </button>
                  &nbsp;&nbsp;

                  {/* onClick={deactive} */}
                  <button
                 
                    className="btn btn-primary"
                    style={{ marginTop: "10px" }}
                  >
                    Deactivate Card
                  </button>
                </li>
              </ul>
            </div>
          </div>
          
          )}

          {nymcard_user_id && cardData.length == 0&&(
          <div className="p10px">

           {accountData.length == 0?( 
           <div>
              <form name="form">
                <div className="row text-center">
                  <div className="col-12 p-3">
                
                    <select id="product_id"name="Product Id"
                    // onClick={(e) => handleChangeProduct(e)}
                    onChange={handleChangeProduct}
                     > 
                    <option defaultValue value={null} > {" "}select product</option> 
                    {cardproductsdata?.map((e,i)=>{
                      return <option key={i} value={e.product_id}> {e.description} 
                      </option> }) }
                     </select>
                  </div>
                  <div className="col-12 p-3">
                    <select id="product_id"name="Product Id"
                    // onClick={(e) => handleChangeProduct(e)}
                    onChange={handleChangePayment}
                     > 
                    <option defaultValue value={null} > {" "}select product</option> 
                    {paymenttypes?.map((e,i)=>{
                      return <option key={i} value={e}> {e} 
                      </option> }) }
                     </select>
                  </div>
                  <div className="col-12 p-3">
                    <button type="submit" className="btn btn-primary" onClick={createAccount}>
                      Create Account
                    </button>
                  </div>
                </div>
              </form>
            </div>
            ): accountData.length > 0 
            &&(
               <div>
               <form name="form">
                 <div className="row text-center">
                   <div className="col-12 p-3">
                     <button type="submit" className="btn btn-primary" onClick={onCreateCard}>
                       Create Card
                     </button>
                   </div>
                 </div>
               </form>
             </div>
            )}
          </div>
          )}
        </div>
      </div>

      <section>
        <div className="frameshadow">
          <div className="mainTitle">
            <div>Nymcard Transaction List</div>
          </div>

          <div className="p10">
            {/* *ngIf="data3.length > 0" datatable  */}
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nymcard Transaction Id</th>
                  <th scope="col">Loan amount</th>
                  <th scope="col"> Unload amount </th>
                  <th scope="col">Fee amount</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Note</th>
                  <th scope="col"> Status </th>
                  <th scope="col"> Status Description </th>
                  <th scope="col"> Status Reason </th>
                  <th scope="col"> Created At </th>
                </tr>
              </thead>
              <tbody>
                {/* *ngFor="let d of data3; let i = index" */}
                <tr>
                  <th scope="row">{1}</th>
                  {/* <td>{ d.nymcard_transaction_id }</td>
          <td>{ d.loadAmount }</td>          
          <td>{ d.unloadAmount}</td>
          <td>{ d.fee_amount }</td>
          <td>{ d.currency }</td>
          <td>{ d.note }</td>
          <td>{ d.status }</td>
          <td>{ d.status_description }</td>
          <td>{ d.status_reason }</td>
          <td>{ d.createdAt }</td> */}
                </tr>
              </tbody>
            </table>

            {/* *ngIf="data3.length == 0" datatable */}
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Nymcard Transaction Id</th>
                  <th scope="col">Loan amount</th>
                  <th scope="col">Fee amount</th>
                  <th scope="col"> Unload amount </th>
                  <th scope="col">Currency</th>
                  <th scope="col">Note</th>
                  <th scope="col"> Status </th>
                  <th scope="col"> Status Description </th>
                  <th scope="col"> Status Reason </th>
                  <th scope="col"> Created At </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </section>

      {/* <ng-template #addvelo> */}
      <div className="modal-body">


          {/* onClick={close} */}
        <button
          type="button"
          className="close pull-right"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5>set Velocity limit</h5>

        <div className="p10">
          {/* *ngIf="data2.length > 0" */}
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Id</th>
                <th scope="col">Description</th>
                <th scope="col">set Velocity limit</th>
              </tr>
            </thead>
            <tbody>
              {/* *ngFor="let d of data2; let i = index" */}
              <tr>
                <th scope="row">{1}</th>

                {/* <td>
                { d.velocity_id }
            </td>
            <td>
              { d.description }
            </td> */}
                <td>
                  {/* <!-- {{ d.status }} --> */}
                  <div>

                  {/* onClick={() => addvelocity(d)} */}
                    <button
                      type="button"
                      className="btn btn-success btn-user-control"
                    >
                      {/* <!-- onClick="add(addtemp)" --> */}
                      <i className="fa fa-user" aria-hidden="true"></i> set
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {/* *ngIf="data2.length == 0" */}
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Id</th>
                <th scope="col">Description</th>
                <th scope="col">set Velcity limit</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>

        {/* onClick={close} */}
        <button
          type="button"
          className="brw-btn closebutton mr30px w200px"
        >
          CANCEL
        </button>
        {/* //   <!-- <button type="submit" className="greenbutton upperCase w200px">Update</button> --> */}
      </div>
    </>
  );
};

export default Nymcard;
