import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Get, Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../Components/Button/Button";
import Toast from "../../Components/Toaster/Toast";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";

const ContractSignature = () => {
  const [htmldata, setHtmlData] = useState(null);
  const [showSignPad, setSignPad] = useState(false);
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(true)
  const [{ signature }, dispatch] = useStateValue();
  const { loanid } = useParams();
  const signRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPromissoryNote();
  }, []);

  const getPromissoryNote = async () => {
    try {
      let res = await Get(`loan/getPromissoryNote/${loanid}`, "admin");
      if (res.statusCode === 200) {
        setHtmlData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSignPad(false);
    if (!signRef.current._sigPad._isEmpty) {
      console.log(signRef.current.getTrimmedCanvas().toDataURL());
      let data = signRef.current.getTrimmedCanvas().toDataURL();
      try {
        let res = await Post(
          `loan/signContract/${loanid}`,
          { signature: data },
          "admin"
        );

        if (res.statusCode === 200) {
          setHtmlData(<></>);
          setHtmlData(res.data);
          setEnableSubmitBtn(false)
          toast.success("Success");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Please sign");
    }
  };

  const handleClear = () => {
    signRef.current.clear();
  };

  // const submitApp = async () => {
  //   try {
  //     let res = await Get(
  //       `loan/mailTriggering/promissoryNote/${loanid}`,
  //       "admin"
  //     );
  //     if (res.statusCode === 200) {
  //       toast.success(res.data);
  //       // setTimeout(() => {
  //       navigate("/thankyou");
  //       // }, 2000);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSaveContract = async () => {
    try {
      let res = await Post(`loan/savePromissoryNote/${loanid}`, {}, "admin");
      if (res.statusCode === 200) {
        dispatch({ type: "SET_STEP", payload: 8 });
        toast.success("Success");

        toast.success(res.data);
        setTimeout(() => {
          navigate("/thankyou");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    maxHeight: "500px",
    overflowY: "auto",
    marginTop: "30px",
    border: "1px solid black",
  };

  return (
    <>
      <Toast />
      <div>
        <div style={style} dangerouslySetInnerHTML={{ __html: htmldata }}></div>

        <div className="displayFlex mt_20">
          <Button
            value="Click here to Sign"
            type="button"
            handleClick={() => setSignPad(true)}
          />{" "}
          &nbsp;
          <Button disabled={enableSubmitBtn}
            type="submit"
            value="Submit Application"
            handleClick={handleSaveContract}
          />
        </div>
      </div>
      <Modal
        show={showSignPad}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="closebuttonblock">
            {" "}
            <button
              onClick={() => setSignPad(false)}
              className="btn btn-danger canvasclosebutton"
            >
              X
            </button>
          </div>

          <div>
            <SignatureCanvas
              ref={signRef}
              canvasProps={{ width: 500, height: 250 }}
            />
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  type={"button"}
                  value="Clear"
                  handleClick={handleClear}
                />
                <Button
                  type={"submit"}
                  value="Continue"
                  handleClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <div></div>
    </>
  );
};

export default ContractSignature;
