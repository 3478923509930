import React, { useState, useEffect } from 'react'
import { AuthGet, AuthPost } from '../../common_var/httpService';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-hot-toast';
import Toast from '../../Components/Toaster/Toast';
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup"

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [paymentTerm, setPaymentTerm] = useState();
    const [checked, setChecked] = useState(true);
    const [checkbox, setCheckbox] = useState({ paylater: false, splitpay: true })
    const { id } = useParams()
    useEffect(() => {
        getSettings(id)
        getPaymentTerm(id)
    }, [])

    const getSettings = () => {
        AuthGet(`practice/settings/${id}`, "admin").then((res) => {
            if (res.statusCode === 200) {
                setSettings(res.data.settings)
            }
        }).catch((err) => {
            //  console.log("err--->", err);
            toast.error(err.message)
        })
    }
    const getPaymentTerm = () => {
        AuthGet(`practice/paymentterm/${id}`, "admin").then((res) => {
            //  console.log("paymentterm", res.data)
            if (res.statusCode === 200) {
                setPaymentTerm(res.data[0])
            }
        }).catch((err) => {
            // console.log("err--->", err);
            toast.error(err.message)
        })
    }
    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        // const formik = useFormik({
        initialValues: {
            fixed_fee: settings[0]?.fixed_fee,
            pay_in: settings[0]?.pay_in,
            variable_fees: settings[0]?.variable_fees,
            variablefees_type: settings[0]?.variablefees_type,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            //   console.log("123", values)
            AuthPost(`practice/settings/${id}`, values, "admin")
                .then((res) => {
                    if (res.statusCode === 200) {
                        toast.success("Merchant Settings Updated");
                        getSettings()
                    }
                })
                .catch((err) => {
                })
        }
    })
    //   console.log("v", values)

    const checkboxValue = (e) => {
        console.log(e.target.value)
        setPaymentTerm({ ...paymentTerm, [`${e.target.value}`]: e.target.checked })
    }

    const updatePayment = () => {
        //  console.log(paymentTerm)
        AuthPost(`practice/updatepaymentterm/${id}`, paymentTerm, "admin")
            .then((res) => {
                if (res.statusCode === 200) {
                    toast.success("Payment Term Updated Successfully");
                    getPaymentTerm()
                }
            })
            .catch((err) => {
            })

    }

    // console.log(e.target.value)


    return (
        <div>
            <Toast />
            <div className='container-fluid' >
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='frameshadow'>
                            <h5 className='mb20px'>Payment Term</h5>
                            <div>
                                <div>
                                    <div className='employeblock'>
                                        <div>Pay Later</div>
                                        <input type="checkbox"
                                            name='paylater'
                                            checked={paymentTerm?.enablepaylater === true ? true : false}
                                            value='enablepaylater'
                                            onChange={(e) =>
                                                checkboxValue(e)
                                            }
                                        />
                                    </div>
                                    <div className='employeblock'>
                                        <div>Split Pay</div>
                                        <input type="checkbox"
                                            name='payin'
                                            onChange={(e) =>
                                                checkboxValue(e)
                                            }
                                            value='enablepayin'
                                            checked={paymentTerm?.enablepayin === true ? true : false} />
                                    </div>
                                </div>
                                <button className="btn btn-primary" type='button' onClick={() => {
                                    updatePayment()
                                }} >UPDATE PAYMENT TERMS</button>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='frameshadow'>
                            <form onSubmit={handleSubmit}>
                                <h5 className='mb20px'>Settings</h5>
                                <div>
                                    <div>
                                        <div className='employeblock'>
                                            <div className='w200px'>Variable Fee Type</div>
                                            {/* <div>{settings[0]?.fixed_fee}</div> */}
                                            <input
                                                type="number"
                                                name="fixed_fee"
                                                className="MainInput"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.fixed_fee}
                                            />
                                        </div>
                                        <div className='employeblock'>
                                            <div className='w200px'>Variable Fees</div>
                                            {/* <div>{settings[0]?.fixed_fee}</div> */}
                                            <input
                                                type="number"
                                                name='pay_in'
                                                className="MainInput"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.pay_in}
                                            />
                                        </div>
                                        <div className='employeblock'>
                                            <div className='w200px'>Fixed Fee</div>
                                            {/* <div>{settings[0]?.fixed_fee}</div> */}
                                            <input
                                                type="number"
                                                name='variable_fees'
                                                className="MainInput"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.variable_fees}
                                            />
                                        </div>
                                        <div className='employeblock'>
                                            <div className='w200px'>Split Pay</div>
                                            {/* <div>{settings[0]?.fixed_fee}</div> */}
                                            <input
                                                type="text"
                                                name='variablefees_type'
                                                className="MainInput"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                readOnly={true}
                                                value={values.variablefees_type}
                                            />
                                        </div>
                                    </div>
                                    <button className="btn btn-primary" type="submit">UPDATE SETTINGS</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <div className='container-fluid' >
                <div className='row'>

                </div>
            </div>
        </div>
    )
}

export default Settings