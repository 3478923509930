import React, { useEffect, useState } from 'react'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BankAccounts from "../MerchantTabs/Bankaccounts"
import Category from "../MerchantTabs/Category"
import Comments from "../MerchantTabs/Comments"
import DocumentCenter from "../MerchantTabs/DocumentCenter"
import MerchantInfo from "../MerchantTabs/MerchantInfo"
import Offer from "../MerchantTabs/Offers and Deals"
import Settings from "../MerchantTabs/Settings"
import Transactions from "../MerchantTabs/Transactions"
import { AuthGet, AuthPost } from '../../common_var/httpService'
import { useParams } from 'react-router-dom';

const MerchantView = () => {
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <div className="breadcrumbsection">
        <div>   <div className="titleText ">
          Merchant Details
        </div>
        </div>
      </div>
      <div className=" ">

        <Tabs
          transition={true}
          id="noanim-tab-example"
          className="mb-3 tabblock  "
          activeKey={activeTab}
          unmountOnExit={true}
          mountOnEnter={true}
          onSelect={handleTabClick}
        >
          <Tab title="Merchant Information" eventKey={1}>
            <MerchantInfo />
          </Tab>
          <Tab title="Transactions" eventKey={3}>
            <Transactions />
          </Tab>
          <Tab title="Bank Accounts" eventKey={4}>
            <BankAccounts id={id} />
          </Tab>
          <Tab title="Doc Vault" eventKey={5}>
            <DocumentCenter />
          </Tab>
          <Tab title="Ask Customer" eventKey={7}>
            <Comments />
          </Tab>
          <Tab title="Offer & Deals" eventKey={8}>
            <Offer />
          </Tab>
          <Tab title="Category" eventKey={10}>
            <Category />
          </Tab>
          <Tab title="Settings" eventKey={11}>
            <Settings />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default MerchantView