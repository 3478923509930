import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";

const PhoneValidator = ({ name, value, labelname, isTouched }) => {
  const [{ formData, errors, formSubmitted }, dispatch] = useStateValue();
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        setMessage(`${labelname} is required`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (value.replace(/[^\d]/g, "").length !== 10) {
        setMessage(`${labelname} should have 10 characters`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `should have 10 characters` },
        });
      } else {
        setMessage("");
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);
  return <>{message ? <p className="requiredText">{message}</p> : <></>}</>;
};

export default PhoneValidator;
