import React from 'react'
import logo from "../../assets/images/logo.png";
import footerlogo from "../../assets/images/footer-logo.svg";

const Navbar = () => {
  return (
    <header className="startApplicationHeader">
      <div className="container">
        <nav>
          <div className="logoBrand">
            <img src={footerlogo} />
            {/* <img src="." /> */}
            {/* <h1>Logo</h1> */}
          </div>
          <div>
            {/* <button className="MainButton btn_Primary">Login</button> */}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
