import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";

const Toast = () => {
  return (
    <div>
      <Toaster position="top-right" toastOptions={{duration: 3000}}  />
    </div>
  );
};

export default Toast;
