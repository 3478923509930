import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Get, Post } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";

const PropertyOwnership = ({ setStep }) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const { loanid } = useParams();
  const type_of_residence = [
    { key: "self-owned", value: "Self-Owned" },
    { key: "co-owner", value: "Co-Owner" },
    { key: "rented", value: "Rented" },
    { key: "others", value: "Others" },
  ];

  const getDetails = async () => {
    try {
      let res = await Get(`loan/getpropertyinfo/${loanid}`, 'admin');
      if (res.statusCode === 200) {
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
// debugger
  useEffect(() => {
    getDetails();
    dispatch({
      type: "CLEAR_ERRORS",
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    try {
      const bodyData = {
        ...formData,
        loan_id: loanid,
      };
      if (Object.keys(errors).length === 0) {
        const resp = await Post("loan/propertyinfo", bodyData, "admin");
        if (resp.statusCode === 200) {
          toast.success(resp.message);
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          // setTimeout(() => {
          dispatch({
            type: "SET_STEP",
            payload: 5,
          });
          // }, 500);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Toast />
      <form onSubmit={handleSubmit} className="row">
        <Dropdown required={true}
          data={type_of_residence}
          labelName={"Type of Residence"}
          name={"type_of_residence"}
          handleChange={handleChange}
        />
        <Button type={"submit"} value={"Continue"} handleClick={handleSubmit} />
      </form>
    </>
  );
};

export default PropertyOwnership;
