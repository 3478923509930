import React, { useEffect, useState } from "react";
import breadcrumb_dash from '../../assets/new/breadcrumb_dash.svg';
import file_open_active from '../../assets/new/file_open_active.svg';
import search from '../../assets/new/search.svg';
import plus from '../../assets/new/plus.svg';
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from "react-bootstrap/Modal";
import updateIcon from "../../assets/images/updateIcon.svg";

function PracticeManagement() {
  const navigate = useNavigate();
  const [editData, setEditData] = useState({
    contactName: '',
    email: '',
    practiceName: '',
    practiceUrl: '',
    practiceHomeUrl: '',
    practiceLinkToForm: 'linktoForm',
    locationName: '',
    streetaddress: '',
    city: '',
    stateCode: '',
    zipcode: '',
    phoneNumber: '',
    practiceSettings: '',
    practiceMainColor: '',
    pacticeSecondaryColor: '',
    practicelogo: '',
    practicepoweredbylogo: '',
  });
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [form, setForm] = useState({
    contactName: '',
    email: '',
    practiceName: '',
    practiceUrl: '',
    practiceHomeUrl: '',
    practiceLinkToForm: 'linktoForm',
    locationName: '',
    streetaddress: '',
    city: '',
    stateCode: '',
    zipcode: '',
    phoneNumber: '',
    practiceSettings: '',
    practiceMainColor: '',
    pacticeSecondaryColor: '',
    practicelogo: '',
    practicepoweredbylogo: '',
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  }
  const [rules, setRules] = useState([]);


  let stateList = [
    { id: 'AL', displayName: 'Alabama' },
    { id: 'AK', displayName: 'Alaska' },
    { id: 'AZ', displayName: 'Arizona' },
    { id: 'CA', displayName: 'California' },
    { id: 'CO', displayName: 'Colorado' },
    { id: 'CT', displayName: 'Connecticut' },
    { id: 'DE', displayName: 'Delaware' },
    { id: 'GA', displayName: 'Georgia' },
    { id: 'HI', displayName: 'Hawaii' },
    { id: 'ID', displayName: 'Idaho' },
    { id: 'IL', displayName: 'Illinois' },
    { id: 'IN', displayName: 'Indiana' },
    { id: 'IA', displayName: 'Iowa' },
    { id: 'KS', displayName: 'Kansas' },
    { id: 'KY', displayName: 'Kentucky' },
    { id: 'LA', displayName: 'Louisiana' },
    { id: 'ME', displayName: 'Maine' },
    { id: 'MA', displayName: 'Massachusetts' },
    { id: 'MI', displayName: 'Michigan' },
    { id: 'MS', displayName: 'Mississippi' },
    { id: 'MO', displayName: 'Missouri' },
    { id: 'MN', displayName: 'Minnesota' },
    { id: 'MT', displayName: 'Montana' },
    { id: 'NE', displayName: 'Nebraska' },
    { id: 'NV', displayName: 'Nevada' },
    { id: 'NH', displayName: 'New Hampshire' },
    { id: 'NJ', displayName: 'New Jersey' },
    { id: 'NM', displayName: 'New Mexico' },
    { id: 'NY', displayName: 'New York' },
    { id: 'NC', displayName: 'North Carolina' },
    { id: 'ND', displayName: 'North Dakota' },
    { id: 'OH', displayName: 'Ohio' },
    { id: 'OK', displayName: 'Oklahoma' },
    { id: 'OR', displayName: 'Oregon' },
    { id: 'PA', displayName: 'Pennsylvania' },
    { id: 'SC', displayName: 'South Carolina' },
    { id: 'TN', displayName: 'Tennessee' },
    { id: 'TX', displayName: 'Texas' },
    { id: 'UT', displayName: 'Utah' },
    { id: 'VA', displayName: 'Virginia' },
    { id: 'WA', displayName: 'Washington' },
    { id: 'WV', displayName: 'West Virginia' },
    { id: 'WI', displayName: 'Wisconsin' },
    { id: 'WY', displayName: 'Wyoming' },
  ];


  useEffect(() => {
    getPracticeList();
    getPracticerules();
  }, []);



  const getPracticeList = async () => {
    await AuthGet(`practice`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          setLoanData(res?.allPractice);
          setPage(Math.ceil(loanData.length / 10));
          setCurrentPage(1);
        }
      })
  }

  const getPracticerules = async () => {
    await AuthGet(`practicerules/getAllPracticeRules`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          setRules(res?.data);
        }
      })
  }

  const getEditPractice = async (id) => {
    await AuthGet(`practice/${id}`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          console.log("DATA___MANAGE", res?.practice)
          let data = res?.practice;
          setEditData({
            contactName: data?.contactname,
            email: data?.email,
            practiceName: data?.practicename,
            practiceUrl: data?.practiceurl,
            practiceHomeUrl: data?.practicehomeurl,
            practiceLinkToForm: 'linktoForm',
            locationName: data?.locationname,
            streetaddress: data?.streetaddress,
            city: data?.city,
            stateCode: data?.statecode,
            zipcode: data?.zipcode,
            phoneNumber: data?.phonenumber,
            practiceSettings: data?.practicesettings,
            practiceMainColor: data?.practicemaincolor,
            pacticeSecondaryColor: data?.practicesecondarycolor,
            practicelogo: data?.practicelogo,
            practicepoweredbylogo: data?.practicepoweredbylogo,
            id: data?.id
          });
          setShowEdit(true);
        }
      })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputEdit = (e) => {
    const { name, value } = e.target;
    setEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleSubmit = async (e, method) => {
    e.preventDefault();
    console.log("MANAGE_DATA_SUBMIT", form)


    if (method === "POST") {
      let sendData = {
        contactName: form?.contactName,
        email: form?.email,
        practiceName: form?.practiceName,
        practiceUrl: form?.practiceUrl,
        practiceHomeUrl: form?.practiceHomeUrl,
        practiceLinkToForm: 'linktoForm',
        locationName: form?.locationName,
        streetaddress: form?.streetaddress,
        city: form?.city,
        stateCode: form?.stateCode,
        zipcode: form?.zipcode,
        phoneNumber: form?.phoneNumber,
        practiceSettings: form?.practiceSettings,
        practiceMainColor: form?.practiceMainColor,
        pacticeSecondaryColor: form?.pacticeSecondaryColor,
        practicelogo: form?.practicelogo,
        practicepoweredbylogo: form?.practicepoweredbylogo,
      };
      await AuthPost(`practice/create`, sendData, 'admin')
        .then((res) => {
          if (res?.statusCode === 200) {
            handleCloseEdit();
            handleClose();
            getPracticeList();
            setForm({
              contactName: '',
              email: '',
              practiceName: '',
              practiceUrl: '',
              practiceHomeUrl: '',
              practiceLinkToForm: 'linktoForm',
              locationName: '',
              streetaddress: '',
              city: '',
              stateCode: '',
              zipcode: '',
              phoneNumber: '',
              practiceSettings: '',
              practiceMainColor: '',
              pacticeSecondaryColor: '',
              practicelogo: '',
              practicepoweredbylogo: '',
            });
          }
        })
    } else if (method === "PUT") {
      console.log("EDITDATA____MANAGE", editData)
      let sendData = {
        contactName: editData?.contactName,
        email: editData?.email,
        practiceName: editData?.practiceName,
        practiceUrl: editData?.practiceUrl,
        practiceHomeUrl: editData?.practiceHomeUrl,
        practiceLinkToForm: 'linktoForm',
        locationName: editData?.locationName,
        streetaddress: editData?.streetaddress,
        city: editData?.city,
        stateCode: editData?.stateCode,
        zipcode: editData?.zipcode,
        phoneNumber: editData?.phoneNumber,
        practiceSettings: editData?.practiceSettings,
        practiceMainColor: editData?.practiceMaincolor,
        pacticeSecondaryColor: editData?.pacticeSecondaryColor,
        practicelogo: editData?.practicelogo,
        practicepoweredbylogo: editData?.practicepoweredbylogo,
      };
      await AuthPost(`practice/edit/${editData?.id}`, sendData, 'admin')
        .then((res) => {
          if (res?.statusCode === 200) {
            handleCloseEdit();
            handleClose();
            getPracticeList();
            setForm({
              contactName: '',
              email: '',
              practiceName: '',
              practiceUrl: '',
              practiceHomeUrl: '',
              practiceLinkToForm: 'linktoForm',
              locationName: '',
              streetaddress: '',
              city: '',
              stateCode: '',
              zipcode: '',
              phoneNumber: '',
              practiceSettings: '',
              practiceMainColor: '',
              pacticeSecondaryColor: '',
              practicelogo: '',
              practicepoweredbylogo: '',
            });
          }
        })
    }
  }



  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: '50px',
      }
    },
    {
      dataField: "practicename", text: 'Provider Name', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "contactname", text: 'Business Contact', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "email", text: 'Business Email', sort: true,
      headerStyle: {
        width: '250px',
      }
    },
    {
      dataField: "locationname", text: 'Address', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "city", text: 'City', sort: true,
      headerStyle: {
        width: '100px',
      }
    },
    {
      dataField: "statecode", text: 'State', sort: true,
      headerStyle: {
        width: '100px',
      }
    },
    {
      dataField: "practiceurl", text: 'Provider App link', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Action', sort: true,
      formatter: () => {
        return <button className="btn deleteIcon">
        <img src={updateIcon} />
        </button>
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("MANAGE_DATA", row)
          getEditPractice(row?.id);
        },
      },
      headerStyle: {
        width: '100px',
      }
    },
  ];

  const defaultSorted = [
    {
      dataField: 'practicename',
      order: 'desc'
    },
    {
      dataField: 'contactname',
      order: 'desc'
    },
    {
      dataField: 'email',
      order: 'desc'
    },
    {
      dataField: 'locationname',
      order: 'desc'
    },
    {
      dataField: 'city',
      order: 'desc'
    },
    {
      dataField: 'statecode',
      order: 'desc'
    },
    {
      dataField: 'practiceurl',
      order: 'desc'
    }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;





  return (<div>


    <div className="breadcrumbsection">

      <div>
        <div className="titleText "> Manage Providers</div>
      </div>
      <button
        className="btn btn-primary"
        onClick={() => handleShow()}
      >
        Add Provider
      </button>
    </div>



    <Modal show={show} onHide={handleClose} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Add New Provider</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >
              <form>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Business Contact"
                        formControlName="contactName"
                        id="contactName"
                        name="contactName"
                        class="adminInput"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Business Email"
                        formControlName="email"
                        class="adminInput"
                        name="email"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Provider Name"
                        formControlName="practiceName"
                        id="practiceName"
                        class="adminInput"
                        name="practiceName"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Provider App Link"
                        formControlName="practiceUrl"
                        id="practiceUrl"
                        class="adminInput"
                        name="practiceUrl"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Provider Website"
                        formControlName="practiceHomeUrl"
                        id="practiceHomeUrl"
                        class="adminInput"
                        name="practiceHomeUrl"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Address"
                        formControlName="locationName"
                        id="locationName"
                        onkeydown="return /[a-z ]/i.test(event.key)"
                        class="adminInput"
                        name="locationName"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        type="text"
                        formControlName="mobileNumber"
                        class="adminInput"
                        placeholder="Phone Number"
                        mask="+0 (000) 000-0000"
                        name="phoneNumber"
                        onChange={handleInputChange}
                      />

                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Street"
                        formControlName="streetaddress"
                        class="adminInput"
                        name="streetaddress"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="City"
                        formControlName="city"
                        class="adminInput"
                        onkeydown="return /[a-z ]/i.test(event.key)"
                        name="city"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <select
                        class="adminInput"
                        formControlName="stateCode"
                        name="stateCode"
                        onChange={handleInputChange}
                      >
                        <option value="null" disabled selected>State</option>
                        {stateList?.map((data) => {
                          const { id, displayName } = data;
                          return (
                            <option value={id}>{displayName}</option>
                          )
                        })}
                      </select>

                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Zipcode"
                        class="adminInput zipbox"
                        formControlName="zipcode"
                        mask="00000"
                        maxlength="5"
                        name="zipcode"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb_24">
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <select
                        class="adminInput"
                        formControlName="practiceSettings"
                        name="practiceSettings"
                        onChange={handleInputChange}
                      >
                        <option value="null" disabled="disabled" selected>
                          Provider Settings </option>
                          <option value="88">Surgery</option>
                        {/* {rules?.map((data) => {
                          const { ref_no, setting_name } = data;
                          return (
                            <option value={ref_no}>{setting_name}</option>
                          )
                        })} */}
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <label>Provider Logo</label>

                    <input
                      type="file"
                      // #myInput
                      class="file"
                      name="filename"
                      id="fileControl"
                      formControlName="practicelogo"
                      // (change)="dropped($event.target.files)"
                      accept="image/x-png,image/jpg,image/jpeg"
                    />

                  </div>
                </div>
              </form>
            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={(e) => handleSubmit(e, "POST")}
          >
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>

    <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Update Provider</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >
              <form>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Business Contact"
                        formControlName="contactName"
                        id="contactName"
                        class="adminInput"
                        name="contactName"
                        onChange={handleInputEdit}
                        defaultValue={editData?.contactName}
                      />
                    </div>
                  </div>

                  <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Business Email"
                        formControlName="email"
                        class="adminInput"
                        name="email"
                        onChange={handleInputEdit}
                        defaultValue={editData?.email}

                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Provider Name"
                        formControlName="practiceName"
                        id="practiceName"
                        class="adminInput"
                        name="practiceName"
                        onChange={handleInputEdit}
                        defaultValue={editData?.practiceName}
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Provider App Link"
                        formControlName="practiceUrl"
                        id="practiceUrl"
                        class="adminInput"
                        name="practiceUrl"
                        onChange={handleInputEdit}
                        defaultValue={editData?.practiceUrl}
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Provider Website"
                        formControlName="practiceHomeUrl"
                        id="practiceHomeUrl"
                        class="adminInput"
                        name="practiceHomeUrl"
                        onChange={handleInputEdit}
                        defaultValue={editData?.practiceHomeUrl}

                      />
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Address"
                        formControlName="locationName"
                        id="locationName"
                        onkeydown="return /[a-z ]/i.test(event.key)"
                        class="adminInput"
                        name="locationName"
                        onChange={handleInputEdit}
                        defaultValue={editData?.locationName}

                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        type="text"
                        name="phoneNumber"
                        formControlName="mobileNumber"
                        class="adminInput"
                        placeholder="Phone Number"
                        mask="+0 (000) 000-0000"
                        onChange={handleInputEdit}
                        defaultValue={editData?.phoneNumber}

                      />

                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Street"
                        formControlName="streetaddress"
                        class="adminInput"
                        onChange={handleInputEdit}
                        defaultValue={editData?.streetaddress}
                        name="streetaddress"

                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="City"
                        formControlName="city"
                        class="adminInput"
                        onkeydown="return /[a-z ]/i.test(event.key)"
                        onChange={handleInputEdit}
                        defaultValue={editData?.city}
                        name="city"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <select
                        class="adminInput"
                        formControlName="stateCode"
                        onChange={handleInputEdit}
                        defaultValue={editData?.stateCode}
                        name="stateCode"
                      >
                        <option value="null" disabled selected>State</option>
                        {stateList?.map((data) => {
                          const { id, displayName } = data;
                          return (
                            <option value={id}>{displayName}</option>
                          )
                        })}
                      </select>

                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Zipcode"
                        class="adminInput zipbox"
                        formControlName="zipcode"
                        mask="00000"
                        maxlength="5"
                        onChange={handleInputEdit}
                        defaultValue={editData?.zipcode}
                        name="zipcode"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb_24">
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <select
                        class="adminInput"
                        formControlName="practiceSettings"
                        onChange={handleInputEdit}
                        defaultValue={editData?.practiceSettings}

                      >
                        {/* <option value="null" disabled="disabled" selected>
                        Provider Settings </option> */}
                        <option value='88' selected>Surgery</option>
                        {/* {rules?.map((data) => {
                          const { ref_no, setting_name } = data;
                          return (
                            <option value={ref_no}>{setting_name}</option>
                          )
                        })} */}
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <label>Provider Logo</label>

                    <input
                      type="file"
                      // #myInput
                      class="file"
                      name="filename"
                      id="fileControl"
                      formControlName="practicelogo"
                      // (change)="dropped($event.target.files)"
                      accept="image/x-png,image/jpg,image/jpeg"
                    />

                  </div>
                </div>
              </form>
            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn main_hover_btn" onClick={handleCloseEdit}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn main_hover_btn"
            type="submit"
            onClick={(e) => handleSubmit(e, "PUT")}
          >
            Update
          </button>
        </div>
      </Modal.Footer>
    </Modal>






    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={loanData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>

  </div>);
}

export default PracticeManagement;







