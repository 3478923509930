import React from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import logo from "../../assets/images/logo.svg";
import Toast from "../../Components/Toaster/Toast";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";

const Login = ({ data: Values }) => {
  const navigate = useNavigate();
  const [initialState, dispatch, authState, setAuthState] = useStateValue();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Mail Address Is Required"),
      password: Yup.string()
        .required("Password Is Required")
    }),
    onSubmit: async (values) => {
      let data = {
        email: values.email,
        password: values.password
      };

      await Post(`${Values.url}`, data, Values.key)
        .then((res) => {
          console.log("test", res);
          if (res["statusCode"] === 200) {

            const auth = {
              token: res.jwtAccessToken,
              user: res.resuser,
            };
            if (Values.key === "borrower") {
              sessionStorage.setItem("UserId", res["resuser"]["id"]);
              sessionStorage.setItem("UD_firstName", res["resuser"]["firstname"]);
              sessionStorage.setItem("UD_lastName", res["resuser"]["lastname"]);
              sessionStorage.setItem("UD_email", res["resuser"]["email"]);
              if (res["resuser"]["twofactorauth"] === "N") {
                sessionStorage.setItem("borrower_token", res["jwtAccessToken"]);
                navigate("/borrower/payment-schedule");

              } else {
                navigate("/borrower/twofactorauth");
              }
            } else if (Values.key === "admin") {
              sessionStorage.setItem("resuser", JSON.stringify(res["resuser"]));
              sessionStorage.setItem("pages", JSON.stringify(res["pages"]));
              sessionStorage.setItem("tabs", JSON.stringify(res["tabs"]));
              sessionStorage.setItem("UserId", res["resuser"]["id"]);
              if (res["resuser"]["twofactorauth"] === "N") {
                sessionStorage.setItem("admin_token", res["jwtAccessToken"]);
                gopage(res["pages"][0], res["tabs"]);
              } else {
                // navigate("/admin/dashboard");
                let ndata = {
                  token: res.jwtAccessToken,
                  user: data.user
                }
                setAuthState(ndata)
                sessionStorage.setItem("admin_token", res["jwtAccessToken"]);
                gopage(res["pages"][0], res["tabs"]);
              }
            }
            dispatch({
              type: "SET_AUTH",
              payload: auth,
            });
          } else {
            toast.error(res["message"]);
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: res["message"],
            //   toastType: "error",
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });




    },
  })

  const gopage = (list, tabs) => {
    switch (list.name) {
      case "Dashboard":
        navigate("/admin/dashboard");
        break;
      case "Approved Application":
        navigate("/admin/approved");
        break;
      case "Pending Application":
        navigate("/admin/pendings");
        break;
      case "Incomplete Application":
        navigate("/admin/incomplete");
        break;
      case "Denied Application":
        navigate("/admin/dashboard");
        break;
      case "Funded Contracts":
        navigate("/admin/funded-contracts");
        break;
      case "Settings":
        gosetting(tabs[list.id]);
        break;
      case "Installer Management":
        navigate("/admin/installer");
        break;
      case "Users":
        navigate("/admin/users");
        break;
      case "Start Application":
        navigate("/admin/start-application");
        break;
      case "Funding Contracts":
        navigate("/admin/funding-contracts");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  const gosetting = (list) => {
    switch (list.name) {
      case "Audit Log":
        navigate("/admin/settings/auditlog");
        break;
      case "Questions":
        navigate("/admin/settings/questions");
        break;
      case "Admin Security":
        navigate("/admin/settings/admin-security");
        break;
      case "Roles":
        navigate("/admin/settings/roles");
        break;
      case "DecisionEngine":
        navigate("/admin/settings/decisionengine");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  return (
    <>
      <Toast />
      <section className="logincomponent">
        <div className=" ">
          <div className="loginSection">
            <div className="login-form">
              <div className="main-div">
                <div className="panel">
                  <div>
                    <img src={logo} alt='' style={{ marginBottom: "40px" }} />
                  </div>

                </div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      value={values.email}
                      onChange={handleChange}
                      id="email"
                      type='email'
                      className="form-control"
                      placeholder="Email Address"
                      onBlur={handleBlur}
                    />
                    <br />
                    {errors.email && touched.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                  </div>

                  <div className="form-group">
                    <input
                      value={values.password}
                      onChange={handleChange}
                      id="password"
                      type='password'
                      className="form-control"
                      placeholder="Password"
                      onBlur={handleBlur}
                    />
                    <br />
                    {errors.password && touched.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                  </div>
                  <div className="forgot">
                    <span className="cursorpointer" onClick={() => {
                      navigate(`/${Values.key}/forgot-password`)
                    }}>Forgot password?</span>
                  </div>
                  <button type="submit" className="button">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
