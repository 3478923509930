import React from "react";
import { useState } from "react";
import Email from "../../Components/Email/Email";
import Phone from "../../Components/Phone/Phone";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import { AuthPost } from "../../common_var/httpService";
import Modal from "react-bootstrap/Modal";
import CirclecloseIcon from "../../assets/images/circleClose_icon.svg";
import logo from "../../assets/images/logo.png";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import { useEffect } from "react";

const CreateAccount = ({ navLink, apiUrl }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [{ errors, formData }, dispatch] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (Object.keys(errors).length === 0) {
        formData.phone = formData.phone.replace(/[^\d]/g, "");
        dispatch({
          type: "FORM_SUBMIT",
          payload: true,
        });
        const resp = await AuthPost("users/getstarted", formData, "borrower");
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          toast.success(resp.message);
          handleClose();
        } else if (resp.statusCode === 400) {
          toast.error(resp.message[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toast />
      <Modal show={show} onHide={handleClose} className="getstartedPopup">
        <div className="textAlignRight p16 popupcloseButton">
          <button className="emptyButton" onClick={handleClose}>
            <img src={CirclecloseIcon} />
          </button>
        </div>

        <Modal.Body>
          <div className="popheader">
            <div>
              <img src={logo} className="logomtop " />
            </div>
            <div></div>
          </div>
          <h2 className="textAlignCenter">
            Tell us more about yourself
            {/* or Share more about yourself or Provide
            additional information about you */}
          </h2>

          <p className="textAlignCenter mb_24">
            Let us create and verify your identity
          </p>
          <form onSubmit={handleSubmit} className="customeColum">
            <Email
              name="email"
              labelName={"Email Address "}
              placeholder={"Email Address"}
              handleChange={handleChange}
              required={true}
            />
            <Phone
              name="phone"
              labelName={"Cell Phone Number"}
              placeholder={"(000) 000-0000"}
              handleChange={handleChange}
              required={true}
            />
            <div className="textAlignCenter">
              <Button type={"submit"} value="Continue" />
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div></div>
      <div></div>
    </>
  );
};

export default CreateAccount;
