import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import logo from "../../assets/images/whitelogo.svg";
import dashboardicon from "../../assets/images/dashboard_icon.svg";
import incompleteicon from "../../assets/images/incomplete-icon.svg";
import opportunities from "../../assets/images/pending-application-icon.svg";
import dealicon from "../../assets/images/deal-icon.svg";
import funddisbursedicon from "../../assets/images/fund-disbursed.svg";
import balancelimit_icon from "../../assets/new/balancelimit_icon.svg"
import fees_icon from "../../assets/new/fees_icon.svg"
import velocitylimit_icon from "../../assets/new/velocitylimit_icon.svg"
import productmanage_icon from "../../assets/new/productmanage_icon.svg"
import nymcard_icon from "../../assets/new/nymcard_icon.svg"
import merchant_manageicon from "../../assets/new/merchant_manageicon.svg"
import merchantuser_icon from "../../assets/new/merchantuser_icon.svg"



import inactiveicon from "../../assets/images/inactive-icon.svg";
import aanoticeicon from "../../assets/images/aa-notice.svg";
import usersicon from "../../assets/images/users-icon.svg";

import contacticon from "../../assets/images/contact-icon.svg";
import manageicon from "../../assets/images/manage-icon.svg";
import logicon from "../../assets/images/log-icon.svg";
import { useEffect } from 'react';



const Adminsidebar = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [log, setLog] = useState(false)

  const queryParams = useLocation()
  const loanParams = queryParams.pathname.split("/")
  // const [path, setPath] = useState(loanParams);

  console.log("Admin side bar loan params: ", loanParams);

  const logout = () => {
    navigate("/admin/login")
    sessionStorage.clear()
  }

  const showSideBar = () => {
    setShow(!show);
    console.log("Show: ", show);
  }

  // useEffect(() => {
  //   setPath(loanParams)
  // }, [path])

  let Admin = JSON.parse(sessionStorage.getItem('resuser')).maininstallerid;

  return (
    <div className="AdminSidemenu">
      <div className=" sidebarmenuTitle">

        Admin Portal

        <div id="sidenavToggler " className="cursorpointer sidenavToggler">
          <i className="far fa-chevron-left"
            onClick={() => showSideBar()}
          ></i>
        </div>
      </div>

      <div className='menuBlock '>

        <ul>
          <li onClick={() => navigate("/admin/dashboard")} className={loanParams[2] === 'dashboard' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={dashboardicon} /></div>
            <div> Heads Up Display</div>
          </li>

          <li onClick={() => navigate("/admin/customer-management")} className={loanParams[2] === 'customer-management' ? 'menuActive' : "menuIcon"}>
            <div className='menuIcon'>   <img src={merchantuser_icon} /></div>
            <div> Customer Management</div>
          </li>

          <li onClick={() => navigate("/admin/merchant")} className={loanParams[2] === 'merchant' ? 'menuActive' : "menuIcon"}>
            <div className='menuIcon'>   <img src={merchant_manageicon} /></div>
            <div> Merchant Management</div>
          </li>
          <li onClick={() => navigate("/admin/merchant-admin")} className={loanParams[2] === 'merchant-admin' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={manageicon} className=" " /></div>
            <div> Merchant Users </div>
          </li>
          {/* <li onClick={() => navigate("/admin/productmanage")} className={loanParams[2] === 'customer-managemen' ? 'menuActive' : "menuIcon"}>
            <div className='menuIcon'>   <img src={usersicon} /></div>
            <div> Product Management</div>
          </li>
          <li onClick={() => navigate("/admin/Balancelimit")} className={loanParams[2] === 'customer-managemen' ? 'menuActive' : "menuIcon"}>
            <div className='menuIcon'>   <img src={usersicon} /></div>
            <div> Balance Limits </div>
          </li>
          <li onClick={() => navigate("/admin/fees")} className={loanParams[2] === 'customer-managemen' ? 'menuActive' : "menuIcon"}>
            <div className='menuIcon'>   <img src={usersicon} /></div>
            <div> Fees </div>
          </li>
          <li onClick={() => navigate("/admin/velocity")} className={loanParams[2] === 'customer-managemen' ? 'menuActive' : "menuIcon"}>
            <div className='menuIcon'>   <img src={usersicon} /></div>
            <div>Velocity Limit </div>
          </li> */}



          <li onClick={() => setShow(!show)}>
            <div>
              {/* <img src={SettingIcon} /> */}
              <img className='menuIcon' src={nymcard_icon} />
              <span>Nym Card</span>
              {/* <img className="ml_auto" src={ArrowdownIcon} /> */}
            </div>
          </li>
          {show && (
            // adminsubmenu
            <ul className="Active"> 

              <li onClick={() => navigate("/admin/productmanage")} className={loanParams[2] === 'productmanage' ? 'menuActive' : "menuIcon"}>
                <div className='menuIcon'>   <img src={productmanage_icon} /></div>
                <div> Product Management</div>
              </li>
              <li onClick={() => navigate("/admin/Balancelimit")} className={loanParams[2] === 'Balancelimit' ? 'menuActive' : "menuIcon"}>
                <div className='menuIcon'>   <img src={balancelimit_icon} /></div>
                <div> Balance Limits </div>
              </li>
              <li onClick={() => navigate("/admin/fees")} className={loanParams[2] === 'fees' ? 'menuActive' : "menuIcon"}>
                <div className='menuIcon'>   <img src={fees_icon} /></div>
                <div> Fees </div>
              </li>
              <li onClick={() => navigate("/admin/velocity")} className={loanParams[2] === 'velocity' ? 'menuActive' : "menuIcon"}>
                <div className='menuIcon'>   <img src={velocitylimit_icon} /></div>
                <div>Velocity Limit </div>
              </li>


            </ul>
          )}


          {/* <li onClick={() => navigate("/admin/pending")} className={loanParams[2] === 'pending' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={opportunities} className=" " /></div>
            <div> Opportunities</div>
          </li>

          <li onClick={() => navigate("/admin/approved")} className={loanParams[2] === 'approved' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={dealicon} className=" " /></div>
            <div> Deals</div>
          </li> */}

          {/* <li onClick={() => navigate("/admin/fundingcontract")} className={loanParams[2] === 'fundingcontract' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={funddisbursedicon} className=" " /></div>
            <div> Fund Disbursed </div>
          </li>

          <li onClick={() => navigate("/admin/archived")} className={loanParams[2] === 'archived' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={inactiveicon} className=" " /></div>
            <div> Inactive Deals </div>
          </li> */}

          {/* <li onClick={() => navigate("/admin/denied")} className={loanParams[2] === 'denied' ? "menuActive" : "menuIcon"}>
            <div className='menuIcon'>   <img src={aanoticeicon} className=" " /></div>
            <div> AA Notice  </div>
          </li> */}
        </ul>
        <div className=' '>
          <div className=' '>
            {/* <div className='othertitle'> Configurations   </div> */}
            <ul>
              {/* {Admin?.length > 0 ? null : (
              <li onClick={() => navigate("/admin/users")} className={loanParams[2] === 'users' ? "menuActive" : "menuIcon"}>
                <div className='menuIcon'>   <img src={usersicon} className=" " /></div>
                <div> Portal Users </div>
              </li>
            )} */}

              {/* <li onClick={() => navigate("/admin/patients")} className={loanParams[2] === 'patients' ? "menuActive" : "menuIcon"}>
                <div className='menuIcon'>   <img src={contacticon} className=" " /></div>
                <div> Contacts</div>
              </li>
              
              {Admin?.length > 0 ? null : (
              <li onClick={() => navigate("/admin/practice-management")} className={loanParams[2] === 'practice-management' ? "menuActive" : "menuIcon"}>
                <div className='menuIcon'>   <img src={manageicon} className=" " /></div>
                <div> Manage Providers </div>
              </li>
              )} */}

              {/* <li onClick={() => navigate("/admin/practice-rules")}>
                <div className='menuIcon'>   <img src={dashboardicon} className=" " /></div>
                <div> Practice Rules</div>
              </li> */}

              {/* <li onClick={() => navigate("/admin/practice-admin")}>
                <div className='menuIcon'>   <img src={dashboardicon} className=" " /></div>
                <div> Practice Admin</div>
              </li> */}

              {/* <li  >
                <div className='menuIcon'>   <img src={dashboardicon} className=" " /></div>
                <div> Settings</div>
              </li> */}

              {/* <li onClick={() => navigate("/admin/admin-security")}>
                <div className='menuIcon'>   <img src={dashboardicon} className=" " /></div>
                <div> Admin Security</div>
              </li> */}


              {/* <li onClick={() => navigate("/admin/roles")}>
                <div className='menuIcon'>   <img src={dashboardicon} className=" " /></div>
                <div> Roles</div>
              </li> */}
              {/* {Admin?.length > 0 ? null : (
              <li onClick={() => navigate("/admin/auditlog")} className={loanParams[2] === 'auditlog' ? "menuActive" : "menuIcon"}>
                <div className='menuIcon'>   <img src={logicon} className=" " /></div>
                <div> Logs</div>
              </li>
              )} */}
            </ul>
          </div>
        </div >
      </div >
      <div style={{ clear: 'both' }}></div>
    </div >

  )
}

export default Adminsidebar
