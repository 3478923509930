import React, { useState, useEffect } from "react";
import "./login.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";

const UserDetail = () => {
    const navigate = useNavigate();
    const queryParams = useLocation();
    const [userData, setUserData] = useState({});
    let loanParams = queryParams.pathname.split('/');
    loanParams = loanParams[loanParams.length-1];

    useEffect(() => {
        getUserData();
    }, []);

    console.log("User data: ", userData);

    const getUserData = async () => {
        console.log("params: ", loanParams);
        await AuthGet(`users/${loanParams}`, "admin")
        .then((res) => {
            if (res["statusCode"] == 200) {
                console.log("Res Data: ", res.data[0]);
                setUserData(res.data[0]);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    return (
        <>
            {/* <section>
                <ol className="breadcrumb">
                    <li onClick={() => navigate("/admin/dashboard")}>
                        <a>
                            Dashboard
                        </a>
                    </li>
                    <li className="active">
                        User Information Details</li>
                    <li className="active">View User Details</li>
                </ol>
            </section> */}

            <div>      
                <div className="titleText ">
                    View User Details
                </div>
            </div>

            <div class="userinfotable">
                <table class="userinfotable">
                    <tbody>
                        <tr>
                            <td>Name </td>
                            <td> {userData.firstname} {userData.lastname}</td>
                        </tr>
                        <tr>
                            <td>Email </td>
                            <td> {userData.email}</td>
                        </tr>
                        <tr>
                            <td>Role </td>
                            <td> {userData.role}</td>
                        </tr>
                        <tr>
                            <td>Status </td>
                            <td> USR {userData.ref_no}</td>
                        </tr>
                        <tr>
                            <td>User Reference </td>
                            <td> {userData.active_flag === "Y" ? "Active" : "Inactive"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default UserDetail;
