import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import banner from "../../assets/images/banner.png";
import listicon from "../../assets/images/tick.svg";
import howitwork from "../../assets/images/howitwork.png";
import benifts from "../../assets/images/benfits.jpg";
import check from "../../assets/images/check_circle.svg";
import footerlogo from "../../assets/images/footer-logo.svg";
import "./landing.css";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import StartPage from "./StartPage";

function Landing() {
  const [loanPopup, setLoanPopup] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleClick = () => {
    setLoanPopup(true);
    setShow(true)
  };

  const handleLogin = () => {
    navigate('/borrower/login');
  }

  return (
    <div className="landingPage">
      {loanPopup && (
        <StartPage
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
        />
      )}
      <nav class="navbar navbar-expand-lg logoheader">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img src={logo} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#howitwork">
                  How it works
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#benefit">
                  Benefits
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">
                  Contact us
                </a>
              </li>
            </ul>
          </div>

            <form class="d-flex nav-item">
              <div className="applybutton cursorpointer" onClick={handleClick}> Start Now</div>
              <div className="applybutton cursorpointer" onClick={handleLogin}> Login</div>
              {/* <Button className="applybutton" type="button" value={"Apply Now"} handleClick={handleClick} />
            <Button className="applybutton" type="button" value={"Login"} handleClick={navigate('/borrower/login')} /> */}
            </form>
        </div>


      </nav >
      <div className="container-fluid" id="home">
        <div className="row">
          <div className="col-md-6 bannerimg plr0">
            <img src={banner} />
          </div>

          <div className="col-md-6 bannercontentsection pr100">
            <div className="bannertitlefont">
              Enabling affordable & instant medical loans
            </div>
            <div className="pb30">
              A smarter and cost effective loan to make your medical treatments
              independent of bargains. Our functionally scalable solutions
              achieves your financial needs effectively.
            </div>
            <Button className="blueButton" type="button" value={"Start Now"} handleClick={handleClick} />
          </div>
        </div>
      </div>

      <section className="pt80" id="howitwork">
        <div className="container-fluid  ">
          <div className="row">
            <div className="col-md-6 bannercontentsection pl150">
              <p className="sectionsubtitle   pb10 "> How it works?</p>

              <p className="sectiontitle pb30">
                We have simplified the process to empower you to avail medical
                loans quickly.
              </p>

              <div className="sectionlist">
                <ul>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Get yourself pre-qualified</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Apply with your financial information</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Underwriting of application</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Funding of contract</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>ACH or Check payments</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6  plr0">
              <img src={howitwork} className="w100per" />
            </div>
          </div>
        </div>
      </section>

      <section className="pt80" id="benefit">
        <div className="container  ">
          <div className="row ">
            <div className="col-md-6  ">
              <p className="sectionsubtitle   pb10 "> Benefits</p>
              <p className="sectiontitle pb30">
                We have simplified the process to empower you to avail medical
                loans quickly.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 bannercontentsection  ">
              <img src={benifts} />
            </div>
            <div className="col-md-6 pr150">
              <div className="benefitBlock">
                <div className="displayFlex alignItemcenter">
                  <div className="benefittitle"> Saves time and money</div>
                  <div>
                    <img src={check} />
                  </div>
                </div>
                <p className="benefitcontent">
                  Our latest technology and optimized processes have been
                  specifically designed to increase efficiency and reduce costs.
                </p>
              </div>

              <div className="benefitBlock">
                <div className="displayFlex alignItemcenter">
                  <div className="benefittitle">
                    Flexible repayment timeline
                  </div>
                  <div>
                    <img src={check} />
                  </div>
                </div>
                <p className="benefitcontent">
                  We offer tailored and stress-free repayment plans which will
                  best suit your budget and needs.
                </p>
              </div>
              <div className="benefitBlock">
                <div className="displayFlex alignItemcenter">
                  <div className="benefittitle"> Zero collateral required</div>
                  <div>
                    <img src={check} />
                  </div>
                </div>
                <p className="benefitcontent">
                  We avoid collaterals which acts as a barrier for you to meet
                  your medical needs. Credit worthiness is the factor we
                  consider for loans.
                </p>
              </div>

              <div className="benefitBlock mb0">
                <div className="displayFlex alignItemcenter">
                  <div className="benefittitle"> Minimal paperwork</div>
                  <div>
                    <img src={check} />
                  </div>
                </div>
                <p className="benefitcontent">
                  Our online medical loan application process is designed to be
                  as simple and straight forward which minimizes the dependency
                  on paperwork.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt80 mb50" id="contact">
        <div className="container">
          <div className="row ">
            <div className="col-md-12  ">
              <div className="pb15 sectiontitle textaligncenter">
                Contact us
              </div>
              <div className="contacttext">
                As we envision the future in light of the challenges and
                opportunities of the present, we see the need As we envision the
                future in light of the challenges and opportunities of the
                present, we see the need.
              </div>
            </div>
          </div>

          <div className="row flexcolumnmob">
            <div className="col-md-6 w100mob ">
              <div className="whiteframe">
                <div className="contacttitle">Call Us Now</div>
                <div className="contactcontect">
                  Need help with your Login? No problem! <br /> Our team is here
                  to help you. <br /> 508.475.9013
                </div>
                <div className="divcenter">
                  <button className="outlineButton"> Call Now</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 w100mob">
              <div className="whiteframe">
                <div className="contacttitle">Request a Quote</div>
                <div className="contactcontect">
                  If you prefer to communicate via email, please click below.
                  <br />
                  Please feel free to ask any questions may you have, <br /> we
                  would love to hear from you.
                </div>
                <div className="divcenter">
                  <button className="outlineButton"> Let's Talk</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt80 mb50">
        <div className="container">
          <div className="row ">
            <div className="col-md-12  ">
              <img src={footerlogo} className=" " />
            </div>
            <div className="col-md-12">
              <div className="row spacebetween alignend flexcolumnmob">
                <div className="col-md-6 ">
                  <div className="footerlist">
                    <ul className="displayFlex">
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#">How it works?</a>
                      </li>
                      <li>
                        <a href="#">Benefits</a>
                      </li>
                      <li>
                        <a href="#">Contact us</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className=" footerinput ">
                    <div>
                      <label for="exampleInputEmail1"> Get the freshest news from us</label>
                      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your email address…"></input>
                    </div>
                    <div>
                      <button className="">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="footerline ">
        </div>
        <div className="row fs12">
          <div className="col-md-6 ">
            <div>
              Copyright © 2019-2022 Theecode Technologies Private Limited. All Rights Reserved.

            </div>
          </div>
          <div className="col-md-6 displayFlex justifyend">
            <div className="footerbottomlist">
              <ul className="">
                <li><a href="#">Terms & Conditions</a></li>
                <li>|</li>
                <li><a href="#">Privacy Policy</a></li>
                <li>|</li>
                <li><a href="#">Accessibility</a></li>
                <li>|</li>
                <li><a href="#">Legal</a></li>
              </ul>

            </div>
          </div>
        </div>
      </section>
    </div >
  );
}

export default Landing;
