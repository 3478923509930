import React, { useEffect, useRef, useState } from "react";
import DateValidator from "./dateValidator";
import { useStateValue } from "../../Context/StateProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

const Date = ({
  name,
  handleChange,
  labelName,
  placeholder,
  required,
  maxDate,
  minDate,
  minYear,
}) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);

  const handleDOB = (val) => {
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val?.$d ? val.$d : "" },
    });
  };

  return (
    <div className="col-md-6 mb_24">
      {errors[name] === "Invalid" ? (
        <label htmlFor={name} className="labelText">
          <span className="requiredText">{errors[name]}</span> {labelName}
        </label>
      ) : errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          openTo="year"
          minDate={minDate}
          id={name}
          className="MainInput customDatapicker"
          name={name}
          value={formData[name] === undefined ? null : formData[name]}
          maxDate={maxDate}
          onChange={(newValue) => handleDOB(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={() => setIsTouched(true)}
              style={errors[name] ? { border: "1px solid #f44336" } : null}
            />
          )}
        />
      </LocalizationProvider>
      {required ? (
        <div style={{ display: "none" }}>
          <DateValidator
            minYear={minYear}
            name={name}
            value={formData[name]}
            isTouched={isTouched}
            labelName={labelName}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Date;
