import React, { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from "react-bootstrap/Modal";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import breadcrumb_dash from '../../assets/new/breadcrumb_dash.svg';
import file_open_active from '../../assets/new/file_open_active.svg';
import search from '../../assets/new/search.svg';
import plus from '../../assets/new/plus.svg';
import { useNavigate } from 'react-router-dom';

const Roles = () => {
  const navigate = useNavigate();
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    name: ''
  });
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  }
  let [editValue, setEditValue] = useState({});
  useEffect(() => {
    getPracticeList();
    // getPracticeName();
  }, []);


  const getPracticeList = async () => {
    await AuthGet(`roles/getroles`, 'admin')
      .then((res) => {
        setLoanData(res?.data);
        setPage(Math.ceil(loanData.length / 10));
        setCurrentPage(1);
      })
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    const input_name = e.target.name;
    const input_value = e.target.value;
    setForm({ ...form, [input_name]: input_value });
  };

  const handleShowEdit = (v) => {
    console.log("EDIT_DATA", v)
    setEditValue(v);
    setForm({
      name: v?.name,
      id: v?.id,
    });
    setShowEdit(true);
    return;
  };

  const deleteRole = async (v) => {
    await AuthGet(`roles/delete/${v?.id}`, 'admin')
      .then((res) => {
        console.log(res)
        getPracticeList();
      })
  }

  const login = async (e, method) => {
    console.log("",)
    e.preventDefault();

    if (method === 'POST') {
      console.log("POSTTT", form)
      let sendData = {
        name: form?.name.toString(),
      }
      await AuthPost(`roles/addroles`, sendData, 'admin')
        .then((res) => {
          if (res?.statusCode === 200) {
            setForm({
              name: ''
            })
            handleClose();
            getPracticeList();
          }
        })
    } else if (method === 'PUT') {
      console.log("PUTTT", form)
      let sendData = {
        name: form?.name.toString(),
        id: form?.id,
      }
      await AuthPost(`roles/updateroles`, sendData, 'admin')
        .then((res) => {
          if (res?.statusCode === 200) {
            setForm({
              name: ''
            })
            handleCloseEdit();
            getPracticeList();
          }
        })
    }
  }



  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: '50px',
      }
    },
    {
      dataField: "name", text: 'Roles', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Edit', sort: true,
      formatter: () => {
        return "edit";
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("MANAGE_DATA", row)
          handleShowEdit(row);
        },
      },
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Delete', sort: true,
      formatter: () => {
        return "delete";
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("MANAGE_DATA", row)
          deleteRole(row);
        },
      },
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Permission', sort: true,
      formatter: () => {
        return "permission";
      },
      headerStyle: {
        width: '100px',
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'desc'
    }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;






  return (<div>

    <div className="breadcrumbsection">

      <div>
        <div className="titleText "> Roles List</div>
        {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /  Roles List */}


      </div>
      <button
        className="btn btn-primary"
        onClick={() => handleShow()}
      >
        Add Roles
      </button>

    </div>




    <Modal show={show} onHide={handleClose} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Add Roles</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >

              <form
                className="formclass signupForm"
                style={{ width: '100%' }}
              >
                <div className="row">
                  <div className="mb_24 col-md-6">
                    <div className="float-label">
                      <input
                        placeholder="Role Name"
                        className="adminInput form-application"
                        id="name"
                        name="name"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

              </form>
            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={(e) => login(e, "POST")}
          >
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>



    <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Update Roles</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >

              <form
                className="formclass signupForm"
                style={{ width: '100%' }}
              >
                <div className="row">
                  <div className="mb_24 col-md-6">
                    <div className="float-label">
                      <input
                        placeholder="Role Name"
                        className="adminInput form-application"
                        id="name"
                        name="name"
                        onChange={handleInputChange}
                        defaultValue={form?.name}
                      />
                    </div>
                  </div>
                </div>

              </form>
            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn main_hover_btn" onClick={handleCloseEdit}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn main_hover_btn"
            type="submit"
            onClick={(e) => login(e, 'PUT')}
          >
            Update
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    {/* 

<section className="content-header pt48_plrb_25">
  <div className="displayFlex spaceBetween AlignItemCenter">
    <div>
      <h1>Roles List</h1>

      <ol className="breadcrumb">
        <li onClick={() => navigate("/admin/dashboard")} 
        className="current">
          <a>
                <img src={breadcrumb_dash} alt=''/> Dashboard
            <i className="fal fa-angle-right"></i
          ></a>
        </li>
        <li className="active">
          <img src={file_open_active} alt='' />Roles List
        </li>
      </ol>
      <button
        className="btn Whitebtn mt_24"
    onClick={() => handleShow()}
      >
        Add Roles
      </button>
    </div>
    <div>
    <Modal show={show} onHide={handleClose} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Add Roles</h4>
          </div>
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
            <section
  id="createApplicationFromBackend"
  className="start_application"
>

    <form
      className="formclass signupForm"
     style={{width: '100%'}}
    >
      <div className="row">
        <div className="mb_24 col-md-6">
          <div className="float-label">
            <input
              placeholder="Role Name"
              className="adminInput form-application"
              id="name"
              name="name"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

    </form>
</section>
            </Modal.Body>
          </div>
        </form>
        <Modal.Footer>
          <div>
            <button className="btn main_hover_btn" onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div>
            <button
              className="btn main_hover_btn"
              type="submit"
               onClick={(e) => login(e, 'POST')}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
   
    
    </div>
  </div>
</section> */}




    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={loanData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>

  </div>)
};

export default Roles;
