import React, { useState } from "react";
import CreateAccount from "../../Templates/CreateAccount/CreateAccount";

const StartApplication = () => {
  return (
    <>
      <CreateAccount navLink={""} apiUrl={""} />
    </>
  );
};

export default StartApplication;
