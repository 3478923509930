import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../Toaster/Toast";
import logo from "../../assets/images/logo.svg";
import toast from "react-hot-toast";
const ForgotPassword = ({ portal: key }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err, seterr] = useState(false);
  const [errorval, setErrorVal] = useState(false);
  const [{ }, dispatch] = useStateValue();
  const inputRef = useRef("")
  const navigate = useNavigate()
  const forgotpass = async (e) => {
    e.preventDefault()
    let data = {
      email: username,
    };

    await Post(`users/forgot-password`, data, key)
      .then((res) => {
        //console.log("test", res);  
        if (res.statusCode == 200) {
          toast.success(res.message[0]);
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res.message[0],
          //   toastType: "success",
          // });
        }

        if (res.statusCode == 100) {
          toast.error(res.message[0]);
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res.message[0],
          //   toastType: "error",
          // });
          // setTimeout(()=>{
          //   navigate('/borrower/login')
          // },(1000))
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Toast />
      <section className="borrower_forgotpassword">

        <div className=" ">
          <div className="loginSection">
            <div className="login-form">
              <div className="main-div">
                <div className="panel">
                  <div>
                    <img src={logo} alt='' style={{ marginBottom: "20px" }} />
                  </div>
                  <p className="pb20">Enter your email address to get reset password link </p>
                </div>
                <form
                  id="Login"
                  name="form"
                  onSubmit={forgotpass}
                >
                  <div className="form-group">
                    <input type="text" placeholder="enter your email" value={username}
                      ref={inputRef} className='form-control'
                      onChange={(e) => {
                        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        setUsername(e.target.value);
                        if (reg.test(inputRef.current.value) === false) {
                          // console.log(inputRef.current.value)
                          seterr(true);
                          return false;
                        } else {
                          // console.log(inputRef.current.value)
                          seterr(false);
                          setErrorVal(false);
                          return true;
                        }

                      }}
                      onBlur={(e) => {
                        // console.log("e---->",e)
                        if (inputRef.current.value < 1) {
                          setErrorVal(true)
                        } else {
                          setErrorVal(false)
                        }
                      }}

                    />
                    {err === true && <span style={{ color: "red" }}>Invalid email</span>}
                    {errorval === true && (
                      <p style={{ color: "red" }}>Email Required</p>
                    )}
                  </div>

                  <br />
                  <button type="submit" className="button mb20px">
                    Reset Password
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                  <span className="cursorpointer" onClick={() => {
                    navigate(`/${key}/login`)
                  }} >Back to login</span>
                </form>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default ForgotPassword;
