import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";

const Transactions = () => {
  const [userData, setUserData] = useState({});
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  const merchantId = params[params.length - 1];

  const page = 1;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getlist();
  }, [queryParams]);

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
    },
    {
      dataField: "merchant_name",
      text: "Merchant Name",
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "amount",
      text: "(AED)",
      formatter: (cell) => {
        return cell + "AED";
      },
    },
    {
      dataField: "payment_method",
      text: "Payment Term",
    },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const getlist = () => {
    AuthGet(
      `customer-mgt/get_transaction_portal_list/merchant/${merchantId}`,
      "admin"
    )
      .then((res) => {
        if (res["statusCode"] == 200) {
          setUserData({ transactions: res.data });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <div>
          <h5>Transactions</h5>
        </div>
      </div>

      <div className="frameshadow mb20px">
        {/* <div>
          <input type="text" name="" id="" />
          <input type="text" name="" id="" />
          <button>Export Excel</button>
          <button>Export PDF</button>
        </div> */}

        <div>
          <div className="frameshadow mb20px">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                pagination={pagination}
                data={
                  userData?.transactions?.length > 0
                    ? userData.transactions
                    : []
                }
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
