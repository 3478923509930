import React from 'react'

const Checkbox = ({name, labelName, handleChange, checked}) => {
  return (
    <div className="customCheckbox">
      <input type="checkbox" checked={checked} id={name} name={name} onChange={handleChange} />
      <label htmlFor={name}>{labelName}</label>
    </div>
  );
}

export default Checkbox