import React from "react";

const Button = ({ type, value, handleClick, disabled }) => {
  return (
    <div>
      <button disabled={disabled}
        type={type}
        onClick={handleClick}
        className="MainButton btn_Primary"
      >
        {value}
      </button>
    </div>
  );
};

export default Button;
