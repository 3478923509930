import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import thankyou from "../../assets/images/thankyou-banner.svg";

const Thankyou = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      sessionStorage.clear();
      navigate("/borrower/login")
      window.location.reload();
    }, 3000)
  }, [])

  return (
    <div className='thankyoublock' >
      <img src={thankyou} />

      <div className='thankyoutext'>Your application
        has been submitted, thank you!</div>

    </div>
  )
}

export default Thankyou