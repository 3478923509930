import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import StateValidator from "./StateValidator";

const States = ({ name, labelName, placeholder, handleChange, required }) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  const allStates = [
    { key: "AL", name: "Alabama" },
    { key: "AK", name: "Alaska" },
    { key: "AZ", name: "Arizona" },
    { key: "AR", name: "Arkansas" },
    { key: "CA", name: "California" },
    { key: "CO", name: "Colorado" },
    { key: "CT", name: "Connecticut" },
    { key: "DE", name: "Delaware" },
    { key: "FL", name: "Florida" },
    { key: "GA", name: "Georgia" },
    { key: "HI", name: "Hawaii" },
    { key: "ID", name: "Idaho" },
    { key: "IL", name: "Illinois" },
    { key: "IN", name: "Indiana" },
    { key: "IA", name: "Iowa" },
    { key: "KS", name: "Kansas" },
    { key: "KY", name: "Kentucky" },
    { key: "LA", name: "Louisiana" },
    { key: "ME", name: "Maine" },
    { key: "MD", name: "Maryland" },
    { key: "MA", name: "Massachusetts" },
    { key: "MI", name: "Michigan" },
    { key: "MN", name: "Minnesota" },
    { key: "MS", name: "Mississippi" },
    { key: "MO", name: "Missouri" },
    { key: "MT", name: "Montana" },
    { key: "NE", name: "Nebraska" },
    { key: "NV", name: "Nevada" },
    { key: "NH", name: "New Hampshire" },
    { key: "NJ", name: "New Jersey" },
    { key: "NM", name: "New Mexico" },
    { key: "NY", name: "New York" },
    { key: "NC", name: "North Carolina" },
    { key: "ND", name: "North Dakota" },
    { key: "OH", name: "Ohio" },
    { key: "OK", name: "Oklahoma" },
    { key: "OR", name: "Oregon" },
    { key: "PA", name: "Pennsylvania" },
    { key: "RI", name: "Rhode Island" },
    { key: "SC", name: "South Carolina" },
    { key: "SD", name: "South Dakota" },
    { key: "TN", name: "Tennessee" },
    { key: "TX", name: "Texas" },
    { key: "UT", name: "Utah" },
    { key: "VT", name: "Vermont" },
    { key: "VA", name: "Virginia" },
    { key: "WA", name: "Washington" },
    { key: "WV", name: "West Virginia" },
    { key: "WI", name: "Wisconsin" },
    { key: "WY", name: "Wyoming" },
  ];
  return (
    <div className="col-md-6 mb_24">
      {errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <select
        value={formData[name]}
        className="MainInput"
        name={name}
        onChange={handleChange}
        onBlur={() => setIsTouched(true)}
        style={errors[name] ? { border: "1px solid #f44336" } : null}
      >
        <option value="">{placeholder}</option>

        {allStates.map((e, i) => (
          <option value={e.key} key={i}>
            {e.name}
          </option>
        ))}
      </select>
      {required ? (
        <div style={{ display: "none" }}>
          <StateValidator
            value={formData[name]}
            name={name}
            labelName={labelName}
            isTouched={isTouched}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default States;
