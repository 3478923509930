import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../Context/StateProvider';

const DropdownValidator = ({ name, value, isTouched, labelName }) => {
  const [{ formData, formSubmitted }, dispatch] = useStateValue();

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        setMessage(`${labelName} is required`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else {
        setMessage("");
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);
  return <>{message ? <p className="requiredText">{message}</p> : <></>}</>;
}

export default DropdownValidator