import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import toast from "react-hot-toast";
import CustomCustomerModal from "../../Components/Modal/Customer-Modal.jsx";

const UserInfromation = () => {
  const [userData, setUserData] = useState([]);
  const queryParams = useLocation();
  const navigate = useNavigate();
  let params = queryParams.pathname.split("/");
  const userId = params[params.length - 1];
  const loanStage = params[params.length - 2];
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    getlist();
  }, []);

  const getlist = () => {
    console.log("params Id: ", userId);
    console.log("stage: ", loanStage);

    AuthGet(`customer-mgt/customer/${userId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setUserData({
            data: res.data[0],
          });
        } else if (res["statusCode"] == 500) {
          toast.error(res["message"]);
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res["message"],
          //   toastType: "error",
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("User data: ", userData);

  return (
    <>
      <Toast />
      {/* <div>
        <h6>User Information</h6>
      </div> */}
      <div className="p20 frameshadow">
        <div
          className="displayFlex spaceBetween AlignItemCenter mb20px"
          style={{ width: "200px" }}
        >
          <div>
            <CustomCustomerModal
              btnName={"Edit"}
              data={userData?.data}
              fun={getlist}
            />
          </div>
          <div>
            <CustomCustomerModal
              btnName={"Change Status"}
              data={userData?.data}
              fun={getlist}
            />
          </div>
        </div>
        <table border={1} className="userinfotable">
          <tbody>
            {/* <tr>
              <td className="w30percent">User Reference</td>
              <td> USR {userData.data?.ref_no}</td>
              <td> </td>
            </tr> */}
            <tr>
              <td>Credit Limit </td>
              <td>
                {userData?.data?.wallet_creditlimit === null ? (
                  <strong> 0 AED</strong>
                ) : (
                  <strong>{userData?.data?.wallet_creditlimit} AED</strong>
                )}
              </td>
              <td> </td>
            </tr>
            <tr>
              <td>First Name </td>
              <td>{userData?.data?.firstname}</td>
              <td> </td>
            </tr>
            <tr>
              <td>Last Name </td>
              <td>{userData?.data?.lastname}</td>
              <td> </td>
            </tr>
            <tr>
              <td> Email </td>
              <td>{userData.data?.email}</td>
              <td> </td>
            </tr>

            <tr>
              <td> Phone Number</td>
              <td>{userData.data?.phone}</td>
              <td> </td>
            </tr>

            <tr>
              <td> Date of Birth </td>
              <td>{userData.data?.date_of_birth}</td>
              <td> </td>
            </tr>

            <tr>
              <td> National ID </td>
              <td>{userData.data?.national_id}</td>
              <td> </td>
            </tr>

            {/* <tr>
              <td> Address </td>
              <td>{userData.data?.streetAddress} {userData.data?.city}</td>
              <td> </td>
            </tr>

            <tr>
              <td> State </td>
              <td>{userData.data?.state}</td>
              <td> </td>
            </tr> */}

            <tr>
              <td> Signed Up On </td>
              <td>{userData.data?.created_at?.split("T")[0]}</td>
              <td> </td>
            </tr>
            {/* <tr>
              <td> Last Profile Update Date </td>
              <td>{userData.data?.updated_at}</td>
              <td> </td>
            </tr> */}

            <tr>
              <td> Annual Income </td>
              <td>AED {userData.data?.annualIncome}</td>
              <td> </td>
            </tr>
            <tr>
              <td> Status </td>
              <td>{userData.data?.customer_status}</td>
              <td> </td>
            </tr>
            <tr>
              <td> Created By </td>
              <td>{userData.data?.created_by}</td>
              <td> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div></div>
    </>
  );
};

export default UserInfromation;
