import React, { useState, useEffect } from "react";
import BanksImage from "../../assets/images/bank.png";
import Image from "../../Components/Image/Image";
import Button from "../../Components/Button/Button";
import { usePlaidLink } from "react-plaid-link";
import { Get, Post } from "../../common_var/httpService";
import { useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";

const Plaid = () => {
  const [plaidToken, setPlaidToken] = useState(null);
  const [{ }, dispatch] = useStateValue();
  const { loanid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    getToken();
  }, []);

  const getToken = async () => {
    try {
      let res = await Get("loan/plaidLinkToken/" + loanid, "admin");
      if (res.statusCode === 200) {
        setPlaidToken(res.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: async (public_token, metadata) => {
      var sendData = { public_token: public_token };
      console.log(metadata);

      await Post("loan/plaidsavetoken/" + loanid, sendData, "admin").then(
        (res) => {
          if (res["statusCode"] == 200) {
            getSystemGenerateOffers();
          } else {
            let msg;
            try {
              msg = res["message"][0];
            } catch (e) {
              msg = "Something problem try later.";
            }
            // this.service.showError(msg);
          }
        },
        (err) => {
          this.service.showError("Something problem try later.");
          console.log(err);
        }
      );
    },
    onEvent: (eventName, metadata) => {
      console.log(eventName);
    },
    onExit: (error, metadata) => {
      console.log(error);
    },
  });

  const getSystemGenerateOffers = async () => {
    let res = await Get(`loan/generateOffer/${loanid}`, "admin");
    console.log(res);
    if (res["statusCode"] == 200) {
      let data = res["data"];
      if (data.length > 0) {
        dispatch({
          type: "SET_OFFERS",
          payload: data,
        });
        toast.success("Success");
        // setTimeout(() => {
        dispatch({
          type: "SET_STEP",
          payload: 6,
        });
        // }, 500);
      }
    } else {
      console.log("Invalid");
    }
  };

  return (
    <div>
      <Toast />
      <div>
        <Image src={BanksImage} alt={"bank_banner"} classname="imagewidth" />
        <Button
          type={"submit"}
          value={"Click to link your Bank "}
          handleClick={() => open()}
        />
      </div>
    </div>
  );
};

export default Plaid;
