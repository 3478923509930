import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthGet } from "../../common_var/httpService"
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import CustomCustomerModal from '../../Components/Modal/Customer-Modal';

const Loanlist = () => {
    const navigate = useNavigate()
    const queryParams = useLocation()
    const loanParams = queryParams.pathname.slice(7)
    const loanStatus = `${loanParams.charAt(0).toUpperCase() + loanParams.slice(1)} `;
    const [loanData, setLoanData] = useState([]);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getlist();
    }, [loanParams]);

    const getlist = async () => {
        //console.log("params: ", loanParams);
        await AuthGet(`customer-mgt/customers`, "admin")
            .then((res) => {
                if (res["statusCode"] == 200) {
                    console.log("Res Data: ", res);
                    setLoanData(res.data);
                    setPage(Math.ceil(loanData.length / 10));
                    setCurrentPage(1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            dataField: "", text: 'S.NO', sort: true,
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                //.log("Total: ", total);
                return slNo;
            },
            headerStyle: {
                width: '50px',
            }
        },
        // {
        //     dataField: "ref_no", text: 'Ref No', sort: true,
        //     headerStyle: {
        //         width: '300px',
        //     }
        // },
        {
            dataField: "firstname", text: 'Contact Name', sort: true,
            formatter: (cell, row) => {
                // console.log("Cell for first name: ", row);
                if(row.firstname === null) {
                    return "--Yet To Determine--"
                }
                return `${row.firstname} ${row.lastname}`;
            },
            headerStyle: {
                width: '150px',
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    sessionStorage.setItem("loan_id", row.loan_id);
                    sessionStorage.setItem("procedure_date", JSON.stringify({}));
                    navigate(`/admin/customer-management/${row.user_id}`);
                },
            },
            style: {
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline'
            }
        },
        {
            dataField: "email", text: 'Email', sort: true,
            headerStyle: {
                width: '300px',
            }
        },
        {
            dataField: "customer_status", text: 'Status', sort: true,
            headerStyle: {
                width: '300px',
            }
        },
    ];

    const defaultSorted = [
        {
            dataField: 'created_at',
            order: 'desc'
        },
        {
            dataField: 'firstname',
            order: 'desc'
        },
        {
            dataField: 'email',
            order: 'desc'
        }
    ];

    const pagination = paginationFactory({
        page: page,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
            setCurrentPage(page);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    const { SearchBar, ClearSearchButton } = Search;

    return (
        <div>
            <div className="breadcrumbsection">

                <div><div className="titleText "> {
                    loanStatus === "Waiting " ? "Prospects " :
                    loanStatus === "Pending " ? "Opportunities " :
                    loanStatus === "Approved " ? "Deals " :
                    loanStatus === "Fundingcontract " ? "Fund Disbursed " :
                    loanStatus === "Archived " ? "Inactive Deals " :
                    loanStatus === "Denied " ? "AA Notice " : ""}
                    Application Details</div>
                </div>
            </div>

            <div className="frameshadow">
                <div style={{marginBottom: "10px"}}>
                    <CustomCustomerModal btnName={"Add Customer"} fun={getlist} />
                </div>
                <ToolkitProvider
                    bootstrap4
                    keyField='id'
                    data={loanData}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <div>
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                                <hr />
                                <BootstrapTable
                                    bootstrap4
                                    key='customer_id'
                                    defaultSorted={defaultSorted}
                                    pagination={pagination}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        </div>
    )
}

export default Loanlist
