import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import SSNFormatter from "./Formatter";
import SSNValidator from "./SSNValidator";

const SSN = ({ name, labelName, placeholder, handleChange, required }) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div className="col-md-6 mb_24">
      {errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <input
        maxLength={9}
        type="text"
        id={name}
        placeholder={placeholder}
        name={name}
        className="MainInput"
        onChange={(e) => handleChange(e)}
        onBlur={() => setIsTouched(true)}
        value={formData[name]}
        style={errors[name] ? { border: "1px solid #f44336" } : null}
      />
      {required ? (
        <div style={{ display: "none" }}>
          <SSNValidator
            value={formData[name]}
            name={name}
            labelName={labelName}
            isTouched={isTouched}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SSN;
