import React, { useState, useRef, useEffect } from "react";
import {
  useRoutes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Toast from "../../Components/Toaster/Toast";
import { toast } from "react-hot-toast";

const SaltEdge = () => {
  const [{ connectionId }] = useStateValue();

  const queryParams = useLocation();
  const pathname = queryParams.pathname;
  const { secretkey } = useParams();
  const [url, setUrl] = useState("");
  const [searchParams] = useSearchParams();
  console.log("pathname---->", queryParams.pathname);
  const [connectionid, setConnectionid] = useState("");
  const [connectbtn, setConnectbtn] = useState(false);
  const [frame, setFrame] = useState(false);
  const elem = useRef(null);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  console.log("param--->", searchParams.get("connection_id"));

  const getUrl = async () => {
    await AuthGet("saltedge/checkurl/" + secretkey, "admin")
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          setId(res.id);
          checkbank(res.id);
        }
        if (res.statusCode === 400) {
          toast.error(res.message);
          setTimeout(() => {
            navigate(process.env.REACT_APP_CUSTOMER_UI_HOME_URL);
          }, 2000);
        }
      })
      .catch((err) => {});
  };

  const checkbank = async (idz) => {
    console.log("id--->", connectionid);

    await AuthGet(`saltedge/checkdata/${idz}`, "admin")
      .then((res) => {
        console.log("res salt---->", res.data, res.cusdata);
        if (res.data.length > 0) {
          setFrame(false);
          setConnectbtn(false);
        } else {
          setConnectbtn(true);
        }
      })
      .catch((err) => {
        console.log("err salt---->", err);
      });
  };

  const send = async () => {
    console.log("id--->", id);
    await AuthGet(`saltedge/getdata/${id}`, "admin")
      .then((res) => {
        setFrame(true);
        setUrl(res.data);
      })
      .catch((err) => {
        console.log("err salt---->", err);
      });
  };

  const closeFrame = () => {
    // console.log("ref----->",elem.current.closeFrame)
    var conget = sessionStorage.getItem("connectionid");
    const dupframe = document.getElementById("dupeframe");
    if (dupframe != null) {
      dupframe.remove();
    }
    if (conget != null) {
      createConn(conget);
    }
  };

  const createConn = async (conget) => {
    await AuthGet(`saltedge/saveconid/${conget}/${id}`, "admin")
      .then((res) => {
        console.log("res---->", res);
        if (res.statusCode === 200) {
          toast.success("bank connected");
          setTimeout(() => {
            navigate("https://bnpl-dev-customer.theecentral.com/home");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    var getcid = sessionStorage.getItem("connectionid");

    getUrl();
  }, []);

  useEffect(() => {
    var pramstring = searchParams.get("connection_id");
    sessionStorage.setItem("connectionid", pramstring);
    var conget = sessionStorage.getItem("connectionid");
    var conset = "";
    if (pramstring || conget) {
      var paramk;
      if (pramstring) {
        paramk = pramstring;
      } else if (conget) {
        paramk = conget;
      }
      // if(paramk != null){
      //   createConn(paramk);
      // }
    }
  }, [searchParams.get("connection_id")]);

  return (
    <>
      <Toast />
      <div className="frameshadow">
        <div>
          {frame == true && (
            <button className="MainButton btn_Primary" onClick={closeFrame}>
              Save Data
            </button>
          )}
          <div className="mainTitle">Add Bank Details</div>
        </div>

        <div className="mb50px">
          {connectbtn == true ? (
            <div
              className="p10px"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <button
                type="button"
                className="btn btn-success btn-user-control"
                onClick={send}
              >
                Connect Your Bank
              </button>
            </div>
          ) : (
            <></>
          )}
          {url?.length > 0 ? (
            <>
              <div
                id="dupeframe-n"
                className="frame_view"
                style={{ top: "50px", position: "absolute" }}
              >
                <iframe
                  ref={elem}
                  id="dupeframe"
                  src={url}
                  width={1000}
                  height={500}
                  title="Salt-Edge Connect Bank Frame"
                ></iframe>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {/* {accData?.length > 0 ? (
          <div>
            <div className="tabHeader">
              <div>
                <h5>Bank Account</h5>
              </div>
            </div>
            <div className="customTabContent">
              <div className="customAccordion">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Plaid-Chase</Accordion.Header>
                    <Accordion.Body>
                      <h5 className="heading16 fontWeight500 mb_12">
                        User Information
                      </h5>

                      <div className="commondetailTable tableResponsive">
                        <table>
                          <tr>
                            <th>Name</th>
                            <td>
                              {cusData[0]?.firstname} {cusData?.lastname}
                            </td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{cusData[0]?.email}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>{cusData[0]?.phone}</td>
                          </tr>
                          <tr>
                            <th>Address </th>
                            <td>{cusData[0]?.streetaddress}</td>
                          </tr>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Chase</Accordion.Header>
                    <Accordion.Body>
                      {accData.map((item, index) => {
                        return (
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                {item?.bankdetails.edge_acc_name}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="commondetailTable tableResponsive">
                                  <table>
                                    <tr>
                                      <th> Account Holder Name</th>
                                      <td>{item?.bankdetails.acc_holder}</td>
                                    </tr>
                                    <tr>
                                      <th>Account Type</th>
                                      <td>{item?.bankdetails.acc_nature}</td>
                                    </tr>
                                    <tr>
                                      <th>Account Currency Code</th>
                                      <td>
                                        {item?.bankdetails.acc_currency_code}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Account Number</th>
                                      <td>{item?.bankdetails.acc_number}</td>
                                    </tr>
                                    <tr>
                                      <th>Account Current Balance</th>
                                      <td>{item?.bankdetails.acc_balance}</td>
                                    </tr>
                                    <tr>
                                      <th>Account Available Balance</th>
                                      <td>{item?.bankdetails.acc_balance}</td>
                                    </tr>
                                  </table>
                                </div>
                                <div className="whiteFrame">
                                  <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={item?.transactiondetails}
                                    columns={columns}
                                    search
                                  >
                                    {(props) => (
                                      <>
                                        <div className="mb_12 whiteFrameHeader">
                                          <h3 className="heading18 fontWeight600 textTransform">
                                            Transaction Details -last 90 Days
                                          </h3>
                                        </div>
                                        <div className="p16">
                                          <div className="mb_12 displayFlex justifyConentEnd">
                                            <SearchBar {...props.searchProps} />
                                            <ClearSearchButton
                                              {...props.searchProps}
                                            />
                                          </div>
                                          <div className="TableWrapper tableResponsive">
                                            <BootstrapTable
                                              bootstrap4
                                              key="loan_ref"
                                              defaultSorted={defaultSorted}
                                              pagination={pagination}
                                              {...props.baseProps}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
};

export default SaltEdge;
