import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import PasswordValidator from "./PasswordValidator";

const Password = ({
  name,
  labelName,
  maxLen,
  minLen,
  placeholder,
  handleChange,
  required,
}) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div>
      {errors[name] === "is required" || "Mismatch" ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : errors[name] === "Invalid" ? (
        <label htmlFor={name} className="labelText">
          <span className="requiredText">{errors[name]}</span> {labelName}
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <input
        type="password"
        name={name}
        id={name}
        className="MainInput"
        maxLength={maxLen}
        minLength={minLen}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={() => setIsTouched(true)}
        style={errors[name] ? { border: "1px solid #f44336" } : null}
      />
      {required ? (
        <div style={{ display: "none" }}>
          <PasswordValidator
            value={formData[name]}
            name={name}
            labelName={labelName}
            isTouched={isTouched}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Password;
