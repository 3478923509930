import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import CirclecloseIcon from "../../assets/images/circleClose_icon.svg";
import logo from "../../assets/images/logo.png";
import footerlogo from "../../assets/images/footer-logo.svg";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";

const SelectLoanAmount = ({ show, handleClose, setEmailPhonePopup }) => {
  const [loanAmount, setLoanAmount] = useState(1000);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (loanAmount >= 1000) {
      localStorage.setItem("loan_amount", loanAmount);
      handleClose();
      setEmailPhonePopup(true);
    } else {
      toast.error("Select loan amount alteast 1000");
    }
  };
  return (
    <div>
      <Toast />
      <Modal show={show} onHide={handleClose} className="getstartedPopup">
        <div className="textAlignRight p16 popupcloseButton">
          <button className="emptyButton" onClick={handleClose}>
            <img src={CirclecloseIcon} />
          </button>
        </div>
        <Modal.Body>
          <div className="popheader">
            <div>
              <img src={logo} className="logomtop " />
            </div>
            <div></div>
          </div>

          <h2 className="textAlignCenter">Start Now</h2>
          <p className="textAlignCenter mb_24">
            We will offer ideal financing option that fits your needs{" "}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="howMuch_progress">
              <h3 className="textAlignCenter mb_24">
                Let us know your loan expectations
              </h3>
              <div className="loanAmountText mb_24">${loanAmount}</div>
              <div className="displayFlex mb_24 AlignItemCenter">
                <div className="pr20">$1000</div>
                <div className="slidecontainer">
                  <input clasName="slider" style={{ width: "100%" }}
                    type="range"
                    defaultValue={1000}
                    step={500}
                    min={0}
                    max={20000}
                    onChange={(e) => setLoanAmount(+e.target.value)}
                  />
                </div>






                <div>$20,000</div>
              </div>
            </div>
            <div className="mb24">
              <small>
                &nbsp;&nbsp;&nbsp;<span style={{color: 'red'}}>*</span> This amount is indicative. The final loan amount
                will vary based on your eligiblity and submitted documents{" "}
              </small>
            </div>
            <div className="textAlignCenter">
              <button type="submit" className="MainButton btn_Primary">
                Continue
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SelectLoanAmount;
