import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";

const DateValidator = ({ name, value, isTouched, labelName, minYear }) => {
  const [{ formData, formSubmitted }, dispatch] = useStateValue();
  const [message, setMessage] = useState("");
  let d = new Date(value);
  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        setMessage(`${labelName} is required`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (formData[name] == "Invalid Date") {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `Invalid` },
        });
      } else if (d > new Date() || d.getFullYear() < minYear) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `Invalid` },
        });
      } else if (name === "date_of_birth" || name === "coSignor_date_of_birth") {
        if (Math.abs(new Date() - d) <= 567993600000) {
          setMessage(`${labelName} is required`);
          dispatch({
            type: "SET_ERRORS",
            payload: { [name]: `Should be greater than 18 years` },
          });
        } else {
          dispatch({
            type: "REMOVE_ERROR",
            payload: name,
          });
        }
      } else {
        setMessage("");
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);
  return <>{message ? <p className="requiredText">{message}</p> : <></>}</>;
};

export default DateValidator;
