import React, { useEffect, useState } from "react";
import breadcrumb_dash from '../../assets/new/breadcrumb_dash.svg';
import file_open_active from '../../assets/new/file_open_active.svg';
import search from '../../assets/new/search.svg';
import plus from '../../assets/new/plus.svg';
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from "react-bootstrap/Modal";
import { env_variables } from "../../../src/common_var/env";

function PracticeRules() {
  const navigate = useNavigate();
  const [practiceName, setPracticeName] = useState("");
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    setting_name: ''
  });
  const [ruleCheck, setRuleCheck] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });

  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  }
  let [editValue, setEditValue] = useState({});
  const [ruleEdit, setRuleEdit] = useState({});


  useEffect(() => {
    getPracticeList();
    getPracticeName();
  }, []);

  // const  getDERuleSetIframe = () => {
  //  let DERulesSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${env_variables.creditscore}/93`)
  // }

  const getPracticeList = async () => {
    await AuthGet(`practicerules/getAllPracticeRules`, 'admin')
      .then((res) => {
        setLoanData(res?.data);
        setPage(Math.ceil(loanData.length / 10));
        setCurrentPage(1);
      })
  }

  const getPracticeName = async () => {
    await AuthGet(`practice`, 'admin')
      .then((res) => {
        setPracticeName(res?.allPractice);

      })
  }

  const handleShowEdit = (v) => {
    console.log("EDIT_DATA", v)
    setEditValue(v);
    setForm({
      setting_name: v?.setting_name
    });
    setRuleCheck({
      checkedA: v?.deny_tiers,
      checkedB: v?.enable_transunion,
      checkedC: v?.enable_plaid,
    });
    setShowEdit(true);
    return;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    setRuleCheck((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleInputEdit = (e) => {
    const { name, value } = e.target;
    setEditValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckEdit = (e) => {
    const { name, checked } = e.target;
    setRuleEdit((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };





  const deleteRule = async (v) => {
    console.log("DELETE_RULE", v)
    await AuthPost(`practicerules/deletePracticeRules/${v?.ref_no}`, loanData, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          getPracticeList();
          getPracticeName();
        }
      })
  }

  const login = async (e, method) => {
    e.preventDefault();
    if (method === 'POST') {
      let sendData = {
        setting_name: form?.setting_name,
        isDefault: false,
        deny_tiers: ruleCheck?.checkedA,
        enable_transunion: ruleCheck?.checkedB,
        enable_plaid: ruleCheck?.checkedC,
      };

      console.log("POSTDATA", sendData)
      await AuthPost(`practicerules/addSetting`, sendData, 'admin')
        .then((res) => {
          if (res?.statusCode === 200) {
            handleClose();
            getPracticeList();
            getPracticeName();
          }
        })
    }
    else if (method === 'PATCH') {
      console.log("IDK", editValue)
      console.log("IDK_CHECK", ruleEdit)

      let sendData = {
        setting_name: editValue?.setting_name,
        isDefault: false,
        deny_tiers: ruleEdit?.checkedA ? ruleEdit.checkedA : editValue?.deny_tiers,
        enable_transunion: ruleEdit?.checkedB ? ruleEdit.checkedB : editValue?.enable_transunion,
        enable_plaid: ruleEdit?.checkedC ? ruleEdit.checkedC : editValue?.enable_plaid,
      };

      console.log("POSTDATA", sendData)
      await AuthPost(`practicerules/updatePracticeRules/${editValue?.ref_no}`, sendData, 'admin')
        .then((res) => {
          if (res?.statusCode === 200) {
            handleCloseEdit();
            getPracticeList();
            getPracticeName();
          }
        })
    }

  }



  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: '50px',
      }
    },
    {
      dataField: "setting_name", text: 'Settings Name', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "lastname", text: 'Is Default', sort: true,
      headerStyle: {
        width: '100px',
      }
    },
    {
      dataField: "updatedat", text: 'Updated Date', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Edit', sort: true,
      formatter: () => {
        return "edit"
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleShowEdit(row);
        },
      },
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Remove', sort: true,
      formatter: () => {
        return "remove"
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          deleteRule(row);
        },
      },
      headerStyle: {
        width: '150px',
      }
    },
  ];

  const defaultSorted = [
    {
      dataField: 'setting_name',
      order: 'desc'
    },
    {
      dataField: 'lastname',
      order: 'desc'
    },
    {
      dataField: 'updatedat',
      order: 'desc'
    },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;





  return (<div>



    <div className="breadcrumbsection">

      <div>
        <div className="titleText "> Practice Rules</div>
        {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /  Practice Rules */}


      </div>

      <button
        className="btn btn-primary  "
        onClick={() => handleShow()}
      >
        Create Rules
      </button>

    </div>






    <Modal show={show} onHide={handleClose} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Create Rules</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>

            <section
              id="createApplicationFromBackend"
              class="p25"
            >

              <form>
                <div class="mb_24">
                  <input
                    type="hidden"
                  />
                  <input
                    placeholder="Enter settings Name"
                    formControlName="settingsName"
                    id="settingsName"
                    class="adminInput"
                    name="setting_name"
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Is Default</label>
                  <div>
                    <span class="sw-text mr16">default settings already exists</span>
                    <input
                      type="checkbox"
                      formControlName="isDefault"
                      disabled
                      name="isDefault"
                    />
                    <label class="slider round"></label>

                  </div>
                </div>


                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Deny specific tiers</label>
                  <div>
                    <input
                      type="checkbox"
                      name="checkedA"
                      onChange={handleCheckChange}
                    />
                    <label class="slider round"></label>
                  </div>
                </div>

                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Enable transunion</label>
                  <div>
                    <input
                      type="checkbox"
                      name="checkedB"
                      onChange={handleCheckChange}
                    />
                    <label class="slider round"></label>
                  </div>
                </div>
                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Enable Plaid</label>
                  <div>
                    <input type="checkbox" name="checkedC"
                      onChange={handleCheckChange}
                    />
                    <label class="slider round"></label>
                  </div>
                </div>
              </form>
              <br />
              {/* <iframe class="DecisionEngine" [src]="DERulesSrc" frameborder="0" width="1200" height="1200"></iframe> */}

            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={(e) => login(e, 'POST')}
          >
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>

    <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Update Rules</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>

            <section
              id="createApplicationFromBackend"
              class="p25"
            >

              <form>
                <div class="mb_24">
                  <input
                    type="hidden"
                  />
                  <input
                    placeholder="Enter settings Name"
                    formControlName="settingsName"
                    id="settingsName"
                    class="adminInput"
                    name="setting_name"
                    defaultValue={editValue?.setting_name}
                    onChange={handleInputEdit}
                  />
                </div>
                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Is Default</label>
                  <div>
                    <span class="sw-text mr16">default settings already exists</span>
                    <input
                      type="checkbox"
                      formControlName="isDefault"
                      disabled
                      name="isDefault"
                    />
                    <label class="slider round"></label>

                  </div>
                </div>


                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Deny specific tiers</label>
                  <div>
                    <input
                      type="checkbox"
                      formControlName="denyspecifictier"
                      name="checkedA"
                      onChange={handleCheckEdit}
                      defaultChecked={editValue?.deny_tiers}
                    />
                    <label class="slider round"></label>
                  </div>
                </div>

                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Enable transunion</label>
                  <div>
                    <input
                      type="checkbox"
                      formControlName="transunion"
                      name="checkedB"
                      onChange={handleCheckEdit}
                      defaultChecked={editValue?.enable_transunion}
                    />
                    <label class="slider round"></label>
                  </div>
                </div>
                <div class="mb_24 displayFlex AlignItemCenter spaceBetween">
                  <label class="ruleslabel">Enable Plaid</label>
                  <div>
                    <input type="checkbox"
                      name="checkedC"
                      onChange={handleCheckEdit}
                      defaultChecked={editValue?.enable_plaid}
                    />
                    <label class="slider round"></label>
                  </div>
                </div>
              </form>
              <br />
              {/* <iframe class="DecisionEngine" [src]="DERulesSrc" frameborder="0" width="1200" height="1200"></iframe> */}

            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn main_hover_btn" onClick={handleCloseEdit}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn main_hover_btn"
            type="submit"
            onClick={(e) => login(e, 'PATCH')}
          >
            Update
          </button>
        </div>
      </Modal.Footer>
    </Modal>




    {/* <section className="content-header pt48_plrb_25">
      <div className="displayFlex spaceBetween AlignItemCenter">
        <div>
          <h1>Practice Rules</h1>

          <ol className="breadcrumb">
            <li onClick={() => navigate("/admin/dashboard")}
              className="current">
              <a>
                <img src={breadcrumb_dash} alt='' /> Dashboard
                <i className="fal fa-angle-right"></i
                ></a>
            </li>
            <li className="active">
              <img src={file_open_active} alt='' />Practice Rules
            </li>
          </ol>
          <button
            className="btn Whitebtn mt_24"
            onClick={() => handleShow()}
          >
            Create Rules
          </button>
        </div>
        <div>


        </div>

      </div>
    </section> */}


    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={loanData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>


    <br />

    {/* <iframe src={DERulesSrc} /> */}

  </div>);
}

export default PracticeRules;







