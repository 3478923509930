import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { AuthPost, AuthGet } from '../../common_var/httpService';
import { useFormik } from "formik";
import * as Yup from "yup"
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Toast from '../../Components/Toaster/Toast';
import { toast } from 'react-hot-toast';

const Bankaccounts = ({ id }) => {
    const [countrylist, setCountryList] = useState([])
    const [bank, setBank] = useState([])
    const [bankbyId, setBankbyId] = useState([])
    const [bankdapi, setBankdapi] = useState([])
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const handleClose = () => {
        setShow(false) 
        formik.resetForm()};
    const handleShow = () => setShow(true);
    const numberonly='^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$'
    const onlyalphabets =/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g;
    console.log("lklk", id)
    const merchantCountries = async () => {
        let resp = await AuthGet("merchant/merchantCountries", "admin")
            .then((res) => {
                setCountryList(res.data)
            })
            .catch((err) => {
            })
    }

    //get bankaccount
    const bankAccount = async () => {
        let res = await AuthGet("bankaccounts/list", "admin")
            .then((res) => {
                setBank(res.data)
            })
            .catch((err) => {
            })
    }

    //addbank
    const addbankAccount = async () => {
        let res = await AuthGet(`bankaccounts/merchant/${id}`, "admin")
            .then((res) => {
                setBankbyId(res.data)
            })
            .catch((err) => {
            })
    }

    useEffect(() => {
        merchantCountries()
        bankAccount()
        addbankAccount()
    }, [])
    const formik = useFormik({
        initialValues: {
            IBAN: "",
            accountNumber: "",
            bankName: "",
            branchAddress: "",
            branchName: "",
            city: "",
            country: "",
            name: "",
            state: "",
            streetaddress: "",
            swiftCode: "",
        },
        validationSchema: Yup.object({
            IBAN: Yup.string()
                .required('Required')
                .matches(/[A-Z][0-9]{1,20}/,"Invalid IBAN")
                .min(30,"Inavlid IBAN")
                ,
            accountNumber: Yup.string()
                .required('Required')
                .matches(numberonly,"Enter a valid account number")
                .min(12,"Enter a valid account number")
                ,
            // bankName: Yup.string()
            //     .required(''),
            branchAddress: Yup.string()
                .required('Required'),
            branchName: Yup.string()
                .required('Required')
                .matches(onlyalphabets,"Enter a valid name")
                ,
            city: Yup.string()
                .required('Required'),
            country: Yup.string()
                .required('Required'),
            name: Yup.string()
                .required('Required')
                .matches(onlyalphabets,"Enter a valid name")
                ,
            state: Yup.string()
                .required('Required')
                ,
            streetaddress: Yup.string()
                .required('Required')
                ,
            swiftCode: Yup.string()
                .required('Required')
                .min(8,'Invalid swiftCode')
                .max(11,'Invalid swiftCode')
                ,
        }),
        onSubmit: (values) => {
            console.log("123", values)
            AuthPost(`bankaccounts/add/merchant/${id}`, values, "admin")
                .then((res) => {
                    if (res.statusCode === 200) {
                        toast.success("Bank Added");
                        addbankAccount()
                        setShow(false)
                    }
                })
                .catch((err) => {
                    console.log("1445", err)
                })
        }
    })

    const columns = [
        {
            dataField: "", text: '#', sort: true,
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            headerStyle: {
                width: '50px',
            }
        },
        {
            dataField: "name", text: 'Name', sort: true,
            headerStyle: {
                width: '150px',
            }
        },
        {
            dataField: "streetaddress", text: 'Street Address', sort: true,
            headerStyle: {
                width: '150px',
            }
        },
        {
            dataField: "city", text: 'City', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "state", text: 'State', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "accountNumber", text: 'Account Number', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "IBAN", text: 'IBAN', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "swiftCode", text: 'Swift Code', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "bankName", text: 'Bank Name', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "branchName", text: 'Branch Name', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
        {
            dataField: "branchAddress", text: 'Branch Address', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
    ];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'desc'
        },
        {
            dataField: 'streetaddress',
            order: 'desc'
        },
        {
            dataField: 'city',
            order: 'desc'
        },
        {
            dataField: 'state',
            order: 'desc'
        },
        {
            dataField: 'accountNumber',
            order: 'desc'
        },
        {
            dataField: 'IBAN',
            order: 'desc'
        },
        {
            dataField: 'swiftCode',
            order: 'desc'
        },
        {
            dataField: 'bankName',
            order: 'desc'
        },
        {
            dataField: 'branchName',
            order: 'desc'
        },
        {
            dataField: 'branchAddress',
            order: 'desc'
        },
    ];

    const pagination = paginationFactory({
        page: page,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
            setCurrentPage(page);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    const { SearchBar, ClearSearchButton } = Search;

    return (
        <div>
            <Toast />
            <div className="tabHeader">
                <div>
                    <h5>Bank Account</h5>
                </div>
                <div className="displayFlex alignItemscenter">
                    <div>
                        <button className="btn btn-primary" onClick={handleShow}>
                            <img className="mr_8" />
                            <span>Add Bank Manually</span>
                        </button>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bank Account Information for Autopay</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb_24">
                            <label className="labelText">Name</label>
                            <input
                                type="text"
                                placeholder="Enter Name"
                                className="MainInput"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <p className="requiredText">{formik.errors.name}</p>
                            ) : null}
                        </div>
                        <div className="mb_24">
                            <label className="labelText">Street Address</label>
                            <input
                                type="text"
                                placeholder="Enter Street Address"
                                className="MainInput"
                                id="streetaddress"
                                name="streetaddress"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.streetaddress}
                            />
                            {formik.touched.streetaddress && formik.errors.streetaddress ? (
                                <p className="requiredText">{formik.errors.streetaddress}</p>
                            ) : null}
                        </div>
                        <div className="mb_24">
                            <label className="labelText">City</label>
                            <input
                                type="text"
                                placeholder="Enter City"
                                className="MainInput"
                                id="city"
                                name="city"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                            />
                            {formik.touched.city && formik.errors.city ? (
                                <p className="requiredText">{formik.errors.city}</p>
                            ) : null}
                        </div>
                        <div className="mb_24">
                            <label className="labelText">State</label>
                            <input
                                type="text"
                                placeholder="Enter State"
                                className="MainInput"
                                id="state"
                                name="state"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                            />
                            {formik.touched.state && formik.errors.state ? (
                                <p className="error_text">{formik.errors.state}</p>
                            ) : null}
                        </div>
                        <div>
                            <label className="labelText">Account Number</label>
                            <input
                                type="text"
                                placeholder="Enter Account Number"
                                className="MainInput"
                                id="accountNumber"
                                name="accountNumber"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.accountNumber}
                            />
                            {formik.touched.accountNumber &&
                                formik.errors.accountNumber ? (
                                <p className="requiredText">
                                    {formik.errors.accountNumber}
                                </p>
                            ) : null}
                        </div>
                        <div>
                            <label className="labelText">IBAN</label>
                            <input
                                type="text"
                                placeholder="Enter IBAN"
                                className="MainInput"
                                id="IBAN"
                                name="IBAN"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.IBAN}
                            />
                            {formik.touched.IBAN &&
                                formik.errors.IBAN ? (
                                <p className="requiredText">
                                    {formik.errors.IBAN}
                                </p>
                            ) : null}
                        </div>
                        <div>
                            <label className="labelText">Swift Code</label>
                            <input
                                type="text"
                                placeholder="Enter Swift Code"
                                className="MainInput"
                                id="swiftCode"
                                name="swiftCode"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.swiftCode}
                            />
                            {formik.touched.swiftCode &&
                                formik.errors.swiftCode ? (
                                <p className="requiredText">
                                    {formik.errors.swiftCode}
                                </p>
                            ) : null}
                        </div>
                        {/* <div className="col-md-6 mb_24"> */}
                        {/* <label htmlFor="bankName" className="labelText">
                                Bank Name
                            </label>
                            <select id="bankName"
                                className="MainInput"
                                type="text"
                                name="bankName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bankName}>
                                <option>--Select Bank---</option>
                            </select> */}
                        <label className="labelText">Bank Name</label>
                        <input
                            type="text"
                            placeholder="Enter Bank Name"
                            className="MainInput"
                            id="bankName"
                            name="bankName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankName}
                        />
                        {formik.touched.bankName && formik.errors.bankName ? (
                            <p className="error_text">{formik.errors.bankName}</p>
                        ) : null}
                        {/* </div> */}
                        <div>
                            <label className="labelText">Branch Name</label>
                            <input
                                type="text"
                                placeholder="Enter Branch Name"
                                className="MainInput"
                                id="branchName"
                                name="branchName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.branchName}
                            />
                            {formik.touched.branchName &&
                                formik.errors.branchName ? (
                                <p className="requiredText">
                                    {formik.errors.branchName}
                                </p>
                            ) : null}
                        </div>
                        <div>
                            <label className="labelText">Branch Address</label>
                            <input
                                type="text"
                                placeholder="Enter Branch Address"
                                className="MainInput"
                                id="branchAddress"
                                name="branchAddress"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.branchAddress}
                            />
                            {formik.touched.branchAddress &&
                                formik.errors.branchAddress ? (
                                <p className="requiredText">
                                    {formik.errors.branchAddress}
                                </p>
                            ) : null}
                        </div>
                        <div>
                            <label className="labelText">Country</label>
                            <input
                                type="text"
                                placeholder="Enter Country"
                                className="MainInput"
                                id="country"
                                name="country"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                            />
                            {formik.touched.country &&
                                formik.errors.country ? (
                                <p className="requiredText">
                                    {formik.errors.country}
                                </p>
                            ) : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="mainPrimary_btn aareRiverBrienzClr_hover"
                            type='button'
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <button
                            className="mainPrimary_btn aareRiverBrienz_Clr"
                            type="submit"
                        >
                            Add
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="frameshadow">
                <ToolkitProvider
                    bootstrap4
                    keyField='id'
                    data={bankbyId ? bankbyId : []}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <div>
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                                <hr />
                                <BootstrapTable
                                    bootstrap4
                                    key='id'
                                    defaultSorted={defaultSorted}
                                    pagination={pagination}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        </div>
    )
}

export default Bankaccounts