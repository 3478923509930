import React, { useEffect, useState } from "react";
import breadcrumb_dash from '../../assets/new/breadcrumb_dash.svg';
import file_open_active from '../../assets/new/file_open_active.svg';
import search from '../../assets/new/search.svg';
import plus from '../../assets/new/plus.svg';
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from "react-bootstrap/Modal";
import updateIcon from "../../assets/images/updateIcon.svg";
import Toast from '../../Components/Toaster/Toast';
import { toast } from 'react-hot-toast';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';

function MerchantAdmin() {
  const navigate = useNavigate();
  const [merchantname, setMerchantName] = useState("");
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const letterOnly=/^[A-Za-z ]*$/
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  
  const handleClose = () => 
  {
  setShow(false)
  formik.handleReset()
  };
  const handleShow = () => setShow(true);
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);

  }

   const formik=useFormik({
initialValues:{
  merchantname:'',
  email:'',
  role:'',
  firstName:'',
  lastName:'',
  locationName:'',
  phone:'',
  status:'',
},
validationSchema:Yup.object({
  merchantname:Yup.string()
  .required("* is required"),
  email:Yup.string()
  .required("* is required")
  .email('Enter a valid email'),
  role:Yup.string()
  .required("* is required"),
  firstName:Yup.string()
  .required("* is required")
  .matches(letterOnly, 'Please enter valid name'),
  lastName:Yup.string()
  .required("* is required")
  .matches(letterOnly, 'Please enter valid name'),
  locationName:Yup.string()
  .required("* is required"),
  phone:Yup.string()
  .required("* is required")
  .matches(phoneRegExp,"Phone number is not valid")
  .min(10,'Phone number is not valid')
  ,
  status:Yup.string()
  .required("* is required"),
}),
onSubmit:async(values)=>{
console.log(values)
console.log("mn", form?.merchantname)
var rs = merchantname.filter(
  (x) => x.merchantname === form?.merchantname
);
let sendData = {
  merchantid: values.merchantname,
  email: values?.email,
  role: values?.role,
  firstname: values?.firstName,
  lastname: values?.lastName,
  location: values?.locationName,
  mobile: values?.phone,
  status: values?.status,
  id:  form.id
};

console.log("FINAL_DATA", sendData)
await AuthPost(`practice/create-admin`, sendData, 'admin')
  .then((res) => {
    if (res?.statusCode === 200) {
      toast.success("Merchant User Created");
      handleCloseEdit();
      handleClose();
      getMerchantList();
      getMerchantName();
      setForm({
        merchantname: '',
        email: '',
        role: '',
        firstName: '',
        lastName: '',
        locationName: '',
        phone: '',
        status: ''
      });}})}})
  let [editValue, setEditValue] = useState({});
  const [form, setForm] = useState({
    merchantname: '',
    email: '',
    role: '',
    firstName: '',
    lastName: '',
    locationName: '',
    phone: '',
    status: '',
    id: ''
  });

  const handleShowEdit = (v) => {
    console.log("EDIT_DATA", v)
    setEditValue(v);
    setForm({
      merchantname: v.merchantname,
      email: v.email,
      role: v.role,
      firstName: v.firstname,
      lastName: v.lastname,
      locationName: v.locationname,
      phone: v.mobile,
      status: v.status,
      id: v?.id ? v.id : ''
    });
    setShowEdit(true);
    return;
  };

  let roleList = [
    { id: 'Merchant Admin', displayName: 'Merchant Admin' },
    { id: 'Merchant Member', displayName: 'Merchant Member' },
  ];

  useEffect(() => {
    getMerchantList();
    getMerchantName();
  }, []);



  const getMerchantList = async () => {
    await AuthGet(`practice/all-admins`, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          console.warn(res?.data)
          setLoanData(res?.data);
          setPage(Math.ceil(loanData.length / 10));
          setCurrentPage(1);
        }
      })
  }

  const getMerchantName = async () => {
    await AuthGet(`practice/getPracticeName`, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          console.warn(res)
          setMerchantName(res?.allPractice);
        }
      })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleSubmit = async (e, method) => {
    e.preventDefault();
    console.log("mn", form?.merchantname)
    var rs = merchantname.filter(
      (x) => x.merchantname === form?.merchantname
    );
    let sendData = {
      merchantid: rs[0]?.id ,
      email: form?.email,
      role: form?.role,
      firstname: form?.firstName,
      lastname: form?.lastName,
      location: form?.locationName,
      mobile: form?.phone,
      status: form?.status,
      id: form?.id ? form?.id : ''
    };

    console.log("FINAL_DATA", sendData)
    await AuthPost(`practice/create-admin`, sendData, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          toast.success("Merchant User Created");
          handleCloseEdit();
          handleClose();
          getMerchantList();
          getMerchantName();
          setForm({
            merchantname: '',
            email: '',
            role: '',
            firstName: '',
            lastName: '',
            locationName: '',
            phone: '',
            status: ''
          });
        }
      })
  }

  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: '50px',
      }
    },
    {
      dataField: "firstname", text: 'First Name', sort: true,
      headerStyle: {
        width: '100px',
      }
    },
    {
      dataField: "lastname", text: 'Last Name', sort: true,
      headerStyle: {
        width: '100px',
      }
    },
    {
      dataField: "email", text: 'Email', sort: true,
      headerStyle: {
        width: '250px',
      }
    },
    {
      dataField: "mobile", text: 'Phone Number', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    // {
    //   dataField: "merchantname", text: 'Merchant', sort: true,
    //   headerStyle: {
    //     width: '150px',
    //   }
    // },
    {
      dataField: "role", text: 'Role', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "createdat", text: 'Signed Up On', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "", text: 'Edit', sort: true,
      formatter: () => {
        return <button className="btn deleteIcon">
          <img src={updateIcon} />
        </button>
      },
      events: {
        onClick: (e, columns, columnIndex, row, rowIndex) => {
          handleShowEdit(row);

        },
      },
      headerStyle: {
        width: '100px',
      }
    },
  ];

  const defaultSorted = [
    {
      dataField: 'firstname',
      order: 'desc'
    },
    {
      dataField: 'lastname',
      order: 'desc'
    },
    {
      dataField: 'email',
      order: 'desc'
    },
    {
      dataField: 'phone',
      order: 'desc'
    },
    // {
    //   dataField: 'merchantname',
    //   order: 'desc'
    // },
    {
      dataField: 'role',
      order: 'desc'
    },
    {
      dataField: 'createdat',
      order: 'desc'
    }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;





  return (<div>
    <Toast />


    <div className="breadcrumbsection">

      <div>
        <div className="titleText "> Merchant Users</div>
        {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /   Practice Admin User */}


      </div>
      <button
        className="btn btn-primary "
        onClick={() => handleShow()}
      >
        Create Merchant User
      </button>

    </div>





    <Modal show={show} onHide={handleClose} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Create New Merchant User</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >

              <form
                className="formclass signupForm"
                style={{ width: '100%' }}
              >
                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <select
                        className="adminInput form-application"
                        name="merchantname"
                        onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.merchantname}
                        // onChange={handleInputChange}
                      >

                        <option
                          defaultValue value='' selected>
                          Merchant
                        </option>
                        {merchantname?.length > 0 && merchantname?.map((data) => {
                          const { id, merchantname } = data;
                          return (
                            <option value={id}>
                              {merchantname}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {formik.touched.merchantname &&
                  formik.errors.merchantname ? (
                    <p className="error_text">
                      {formik.errors.merchantname}
                    </p>
                  ) : null}
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Email"
                        className="adminInput form-application"
                        name="email"
                        onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                      />

                    </div>
                    {formik.touched.email &&
                  formik.errors.email ? (
                    <p className="error_text">
                      {formik.errors.email}
                    </p>
                  ) : null}
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <select
                        className="adminInput form-application"
                        name="role"
                        onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.role}
                      >
                        <option defaultValue value="">Select Merchant Role</option>
                        {roleList?.map((data) => {
                          const { id, displayName } = data;
                          return (
                            <option value={id}>
                              {displayName}
                            </option>

                          )
                        })}

                      </select>

                    </div>
                    {formik.touched.role &&
                  formik.errors.role ? (
                    <p className="error_text">
                      {formik.errors.role}
                    </p>
                  ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="First Name"
                        id="FirstName"
                        className="adminInput form-application"
                        name="firstName"
                        onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                      />

                    </div>
                    {formik.touched.firstName &&
                  formik.errors.firstName ? (
                    <p className="error_text">
                      {formik.errors.firstName}
                    </p>
                  ) : null}
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Last Name"
                        className="adminInput form-application"
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />

                    </div>
                    {formik.touched.lastName &&
                  formik.errors.lastName ? (
                    <p className="error_text">
                      {formik.errors.lastName}
                    </p>
                  ) : null}
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Location Name"
                        className="adminInput form-application"
                        name="locationName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.locationName}
                      />

                    </div>
                    {formik.touched.locationName &&
                  formik.errors.locationName ? (
                    <p className="error_text">
                      {formik.errors.locationName}
                    </p>
                  ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Cell Phone"
                        id="FirstName"
                        className="adminInput form-application"
                        mask="000-00-0000"
                        name="phone"maxLength={10}

                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />

                    </div>
                    {formik.touched.phone &&
                  formik.errors.phone ? (
                    <p className="error_text">
                      {formik.errors.phone}
                    </p>
                  ) : null}
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <select
                        className="adminInput form-application"
                        name="status"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.status}
                      >
                        <option value='' defaultValue>Status</option>
                        <option value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                    </div>
                    {formik.touched.status &&
                  formik.errors.status ? (
                    <p className="error_text">
                      {formik.errors.status}
                    </p>
                  ) : null}
                  </div>
                </div>

              </form>

            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={formik.handleSubmit}>
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>


    <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
      <Modal.Header>
        <div>
          <h4>Update Merchant Admin User</h4>
        </div>
      </Modal.Header>
      <form>
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >

              <form
                className="formclass signupForm"
                style={{ width: '100%' }}
              >
                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <select
                        className="adminInput form-application"
                        name="merchantname"
                        placeholder="merchantname"
                        onChange={handleInputChange}
                        defaultValue={editValue.merchantname}
                      >

                        <option
                          disabled="disabled">
                          Merchant
                        </option>
                        {merchantname?.length > 0 && merchantname?.map((data) => {
                          const { id, merchantname } = data;
                          return (
                            <option value={id}>
                              {merchantname}
                            </option>
                          )
                        })}
                      </select>

                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Email"
                        className="adminInput form-application"
                        name="email"
                        onChange={handleInputChange}
                        defaultValue={editValue.email}
                      />

                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <select
                        className="adminInput form-application"
                        name="role"
                        onChange={handleInputChange}
                        defaultValue={editValue.role}
                      >
                        <option disabled='disabled'>Select Provider Role</option>
                        {roleList?.map((data) => {
                          const { id, displayName } = data;
                          return (
                            <option value={id}>
                              {displayName}
                            </option>

                          )
                        })}
                      </select>

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="First Name"
                        id="FirstName"
                        className="adminInput form-application"
                        name="firstName"
                        onChange={handleInputChange}
                        defaultValue={editValue.firstname}
                      />

                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Last Name"
                        className="adminInput form-application"
                        name="lastName"
                        onChange={handleInputChange}
                        defaultValue={editValue.lastname}
                      />

                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Location Name"
                        className="adminInput form-application"
                        name="locationName"
                        onChange={handleInputChange}
                        defaultValue={editValue.locationname}
                      />

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <input
                        placeholder="Cell Phone"
                        id="FirstName"
                        className="adminInput form-application"
                        mask="000-00-0000"
                        name="phone"
                        onChange={handleInputChange}
                        defaultValue={editValue.mobile}
                      />

                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <select
                        className="adminInput form-application"
                        name="status"
                        onChange={handleInputChange}
                        defaultValue={editValue.status}
                      >
                        <option value="null" disabled="disabled">Status</option>
                        <option value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                      <div
                        className="invalid-feedback"
                      >
                        <div>
                          Status is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </form>

            </section>
          </Modal.Body>
        </div>
      </form>
      <Modal.Footer>
        <div>
          <button className="btn main_hover_btn" onClick={handleCloseEdit}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn main_hover_btn"
            type="submit"
            onClick={(e) => handleSubmit(e, "PUT")}
          >
            Update
          </button>
        </div>
      </Modal.Footer>
    </Modal>




    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={loanData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>





  </div>);
}

export default MerchantAdmin;







