import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";

const TextValidator = ({ name, value, isTouched, labelName, maxLen }) => {
  const [{ formData, formSubmitted }, dispatch] = useStateValue();

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        setMessage(`${labelName} is required`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (
        value.length < maxLen &&
        (name === "zipcode" || name === "coSignor_zipcode")
      ) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `should have ${maxLen} characters` },
        });
      } else if (name == "duration" && Number(value) <= 1 || name == "coSignor_duration" && Number(value) <= 1) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `should be greater 1 month` },
        });
      } else {
        setMessage("");
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);
  return <>{message ? <p className="requiredText">{message}</p> : <></>}</>;
};

export default TextValidator;
