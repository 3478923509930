import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useLocation,useParams,useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost, AuthPut, AuthFile } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toaster/Toast"
import { toast } from "react-hot-toast";
import Viewframe from './loadframe'



const Successdataedge = ({setconid})=>{
    const [{ connectionId }, dispatch] = useStateValue();

    const queryParams = useLocation();
    const [searchParams] = useSearchParams();
    var setParam = searchParams.get('connection_id')
    console.log("collect data---->",searchParams.get('connection_id'));
    const pathname = queryParams.pathname
    const { id } = useParams();
    const [connectionid,setConnectionid] = useState("")

    const closeFrame = ()=>{
      // console.log("ref----->",elem.current.closeFrame)
      const dupframe = document.getElementById('dupeframe')
            console.log("ref----->",dupframe)
      // if(dupframe != null){
      //   dupframe.remove();
      // }
    }

    if(setParam != null || setParam.length == 0){
    console.log("collect data in ---->",searchParams.get('connection_id'));

      sessionStorage.setItem("connectionid",searchParams.get('connection_id'))
      closeFrame()
    }


    return(
        <>
              <div className="frameshadow">

        <div className="mb50px">

            <h5>Bank Connected Successfully</h5>
          </div>
          </div>
        
        </>
    )






}

export default  Successdataedge;