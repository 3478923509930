const PhoneNumberFormatter = (num) => {
  if (!num) return num;

  const PhoneNumber = num.replace(/[^\d]/g, "");

  if (PhoneNumber.length === 0) {
    return null;
  } else if (PhoneNumber.length > 0 && PhoneNumber.length < 3) {
    return `(${PhoneNumber}`;
  } else if (PhoneNumber.length >= 4 && PhoneNumber.length < 6) {
    return `(${PhoneNumber.slice(0, 3)}) ${PhoneNumber.slice(3)}`;
  } else if (PhoneNumber.length >= 7) {
    return `(${PhoneNumber.slice(0, 3)}) ${PhoneNumber.slice(
      3,

      6
    )}-${PhoneNumber.slice(6)}`;
  }
};

export default PhoneNumberFormatter;
