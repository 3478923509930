import React, { useState, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider"
import { Post } from "../../common_var/httpService";
import { toast } from 'react-hot-toast';
import Toast from '../../Components/Toaster/Toast';
import logo from "../../assets/images/logo.svg";

const PasswordReset = ({ portal }) => {
  let req = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // console.log(searchParams.get("token"))
  const [initialState, dispatch, authState, setAuthState] = useStateValue();

  const newpassref = useRef("");
  const cnewpassref = useRef("");
  const navigate = useNavigate();
  console.log(req)

  const [nwpassword, setNwpassword] = useState("");
  const [password, setPassword] = useState("");
  const [passerr, setPasserr] = useState(false);
  const [passerr1, setPasserr1] = useState(false);
  const [errorval1, setErrorVal1] = useState(false);
  const [errorval, setErrorVal] = useState(false);
  const [matcherr, setMatcherr] = useState(false);

  const forgotPass = async (e) => {
    e.preventDefault()
    let data =
    {
      newpw: password,
      id: searchParams.get("id"),
      token: searchParams.get("token")
    }

    await Post('users/passwordReset', data, portal) 
    // let url = '/' + portal + '/login'
      .then((res) => {
        let url=`/${portal}`+'/login'
        console.log("test", res);
        if (res.statusCode == 200) {
          toast.success(res.message[0])
          setTimeout(() => {
            navigate(url)
          }, (1000))


        }
        if (res.statusCode == 100) {
          toast.error(res.message[0])
          setTimeout(() => {
            navigate(url)
          }, (1000))
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res.message[0],
          //   toastType: "error",
          // });
          // setTimeout(()=>{
          //   navigate('/borrower/login')
          // },(1000))

        }

        //  res.data.length > 0 ? navigate(`/tenant-dashboard/${tenantId}`) : navigate('/vertical')
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <Toast />
      <div className="logincomponent">
        <div className="loginSection">
          <div className="login-form" style={{ textAlign: "left" }}>
            <div className="main-div">
              <div className="panel">
                <div className='textAlignCenter'>
                  <img src={logo} alt='' style={{ marginBottom: "20px" }} />
                </div>
              </div>
              <form onSubmit={forgotPass}>

                <label htmlFor="newpw">New Password</label>
                <br />
                <input
                  type="password"
                  id="newpw"
                  className="MainInput mb20px"
                  value={password}
                  ref={newpassref}
                  onChange={(e) => {
                    console.log(newpassref.current.value.length)
                    let val = newpassref.current.value.length
                    let valN = newpassref.current.value
                    setPassword(e.target.value);
                    if (val < 8) {
                      setPasserr(true);
                    } else {
                      setPasserr(false);
                    }
                  }}
                  onBlur={() => {

                    let val = newpassref.current.value.length
                    console.log(newpassref.current.value.length)
                    if (newpassref.current.value.length < 1) {
                      setErrorVal(true)
                    } else {
                      setErrorVal(false)
                    }
                  }}
                />
                {errorval === true && (
                  <small className="text-danger">New Password is required</small>
                )}
                {passerr === true && (
                  <small className="text-danger">
                    Password should be at least 8 characters long and should contain
                    one uppercase, one lowercase, one number and one special character
                  </small>
                )}
                {
                  matcherr === true && (
                    <small className="text-danger">
                      New password should not be similar to current password
                    </small>
                  )
                }
                <br />
                <label htmlFor="cnewpw">Confirm New Password</label>
                <br />
                <input
                  type="password"
                  id="cnewpw"
                  className="MainInput mb20px"
                  value={nwpassword}
                  ref={cnewpassref}
                  onChange={(e) => {
                    setNwpassword(e.target.value);
                    let cval = cnewpassref.current.value
                    let nval = newpassref.current.value
                    if (nval !== cval) {
                      setPasserr1(true);
                    } else {
                      setPasserr1(false);
                    }
                  }}
                  onBlur={() => {

                    let val = cnewpassref.current.value.length
                    if (cnewpassref.current.value.length < 1) {
                      setErrorVal1(true)
                    } else {
                      setErrorVal1(false);

                    }
                  }}
                />
                {errorval1 === true && (
                  <>
                    <small className="text-danger">New Password is required</small>
                    <br />
                  </>
                )}
                {passerr1 === true && (
                  <small className="text-danger">
                    Password Does Not Matches the New Password
                  </small>
                )}
                <br />
                <br />
                <button type="submit" className="button mb20px"
                >Submit</button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}




export default PasswordReset