import { useRoutes, useLocation, Navigate } from "react-router-dom";
import { useStateValue } from "./Context/StateProvider";
import Admin from "./Admin/Admin";
import AdminLogin from "./Admin/Pages/Login";
import Dashboard from "./Admin/Pages/Dashboard";
import Loanlist from "./Admin/Pages/Loanlist";
import Productmanage from "./Components/Productmanage/Productmanage";
import "./styles.scss";
import Guard from "./Components/Guard/Guard";
import ForgotPassword from "./Components/forgot-password/forgot-password";
import Loandetails from "./Admin/Pages/Loandetails";
import UserDetail from "./Admin/Pages/UserDetail";
import StartApplication from "./Pages/StartApplication/StartApplication";
// import StartPage from "./Pages/StartPage/StartPage";
import Landing from "./Pages/StartPage/Landing";
import SetBorrowerPassword from "./Pages/SetPassword/SetBorrowerPassword";
import Sales from "./Sales/Sales";
import SelectDealer from "./Templates/SelectDealer/SelectDealer";
import Plaid from "./Templates/PlaidConnection/Plaid";
import Offers from "./Templates/Offers/Offers";
import TwoFacAuth from "./Templates/twofacauth/twofacauth";
import MerchantAdmin from "./Admin/Pages/MerchantAdmin";
import PracticeManagement from "./Admin/Pages/PracticeManagement";
import PracticeRules from "./Admin/Pages/PracticeRules";
import AllPatients from "./Admin/Pages/AllPatients";
import Users from "./Admin/Pages/Users";
import AdminSecurity from "./Admin/Pages/AdminSecurity";
import Roles from "./Admin/Pages/Roles";
import ContractSignature from "./Templates/ContractSignature/ContractSignature";
import AuditLog from "./Admin/Pages/AuditLog";
import Thankyou from "./Pages/Thankyou/Thankyou";
import OnboardingGuard from "./Components/OnboardingGuard/OnboardingGuard";
import PasswordReset from "./Templates/password-reset/password-reset";
import Nymcard from './Components/nymcard/nymcard'
import SaltEdge from './Components/saltedge/saltedge'
import Merchant from "./Admin/Pages/Merchant"
import MerchantView from "./Admin/Pages/MerchantView";
import Fees from "./Components/fees/Fees";
import Balancelimit from "./Components/Balancelimit/Balancelimit";
import Velocitynew from "./Components/Velocitynew/Velocity";
import Successdataedge from "./Components/saltedge/collectdata";
function App() {
  const value = useStateValue();
  const queryParams = useLocation();
  const a = "users/forgot-password";
  const b = "borrower";
  // console.log(value, queryParams.pathname)
  let admin_key = { key: "admin" };
  let borrower_key = { key: "borrower" };
  const routes = useRoutes([
    { path: "/setpassword/:token/:id", element: <SetBorrowerPassword /> },
    // { path: "/borrower/passwordReset", element: <PasswordReset portal={"borrower"}/> },
    {
      path: "/admin/passwordReset",
      element: <PasswordReset portal={"admin"} />,
    },
    { path: "/", element: <Navigate to="/admin/login" /> },
    { path: "/admin/login", element: <AdminLogin /> },
    // { path: "/borrower/login", element: <BorrowerLogin /> },
    {
      element: <OnboardingGuard />,
      children: [
        { path: "/selectprovider", element: <SelectDealer /> },
        { path: "/onboarding/:loanid", element: <Sales /> },
        { path: "/thankyou", element: <Thankyou /> },
      ],
    },
    {
      path: "/admin/forgot-password",
      element: <ForgotPassword portal={"admin"} />,
    },
    // {
    //   path: "/borrower/forgot-password",
    //   element: <ForgotPassword portal={"borrower"} />,
    // },
    // {
    //   path: "/borrower/twofactorauth",
    //   element: <TwoFacAuth portal={"borrower"} />,
    // },
    // {
    //   path: "/admin/twofactorauth",
    //   element: <TwoFacAuth portal={"admin"} />,
    // },
    {
      element: <Guard data={admin_key} />,
      children: [
        {
          path: "admin",
          element: <Admin />,
          children: [
            {
              path: "dashboard",
              element: <Dashboard />,
            },
            {
              path: "users/:id",
              element: <UserDetail />,
            },
            {
              path: "customer-management",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "customer-management/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "pending",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "pending/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "approved",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "approved/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "fundingcontract",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "fundingcontract/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "archived",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "archived/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "denied",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "denied/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "users",
              element: (
                <>
                  <Users />
                </>
              ),
            },
            {
              path: "patients",
              element: (
                <>
                  <AllPatients />
                </>
              ),
            },
            {
              path: "merchant",
              element: (
                <>
                  <Merchant />
                </>
              ),
            },
            {
              path: "merchant/:id",
              element: (
                <>
                  <MerchantView />
                </>
              ),
            },
            {
              path: "practice-management",
              element: (
                <>
                  <PracticeManagement />
                </>
              ),
            },
            {
              path: "practice-rules",
              element: (
                <>
                  <PracticeRules />
                </>
              ),
            },
            {
              path: "merchant-admin",
              element: (
                <>
                  <MerchantAdmin />
                </>
              ),
            },
            {
              path: "settings",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "admin-security",
              element: (
                <>
                  <AdminSecurity />
                </>
              ),
            },
            {
              path: "roles",
              element: (
                <>
                  <Roles />
                </>
              ),
            },
            {
              path: "auditlog",
              element: (
                <>
                  <AuditLog />
                </>
              ),
            },
            {
              path: "productmanage",
              element: (
                <>
                  {/* <AuditLog /> */}
                  <Productmanage />
                </>
              ),
            },
            {
              path: "fees",
              element: (
                <>
                  {/* <AuditLog /> */}
                  <Fees />
                </>
              ),
            },
            {
              path: "Balancelimit",
              element: (
                <>
                  {/* <AuditLog /> */}
                  <Balancelimit />
                </>
              ),
            },
            {
              path: "velocity",
              element: (
                <>
                  {/* <AuditLog /> */}
                  <Velocitynew />
                </>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "nymcard",
      element: <Nymcard />,
    },
    {
      path:'connectbank/:secretkey',element:<SaltEdge/>
    },
    {
      path: "connectbank/success",
      element: <Successdataedge />,
    },
    // {
    //   element: <Guard data={borrower_key} />,
    //   children: [
    //     {
    //       path: "/borrower",
    //       element: <Borrower />,
    //       children: [
    //         {
    //           path: "payment-schedule",
    //           element: (
    //             <>
    //               <Guard data={borrower_key} />
    //               <FinancingDetails />
    //             </>
    //           ),
    //         },
    //         {
    //           path: "document-center",
    //           element: (
    //             <>
    //               <Guard data={borrower_key} />
    //               <DocumentCenter />
    //             </>
    //           ),
    //         },

    //         {
    //           path: "make-payment",
    //           element: (
    //             <>
    //               <Guard data={borrower_key} />
    //               <MakePayment />
    //             </>
    //           ),
    //         },
    //         {
    //           path: "add-payment",
    //           element: (
    //             <>
    //               {" "}
    //               <Guard data={borrower_key} />
    //               <AddPayment />
    //             </>
    //           ),
    //         },
    //         {
    //           path: "settings",
    //           element: (
    //             <>
    //               <Guard data={borrower_key} />
    //               <Settings />
    //             </>
    //           ),
    //         },
    //       ],
    //     },
    //   ],
    // },
  ]);
  return routes;
}

export default App;
