import React from 'react';
import { toast } from 'react-hot-toast';
import Toast from '../../Components/Toaster/Toast';

const Employment = () => {
  return (
    <div>
      <Toast />
      <button
        className="btn btn-primary"
        onClick={() => toast.error('Card Key Is Invalid')}
      >
        Apply
      </button>
    </div>
  );
};

export default Employment;
