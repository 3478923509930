import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// import Modal from "react-bootstrap/Modal";
import updateIcon from "../../assets/images/updateIcon.svg";

import * as Yup from "yup";
import { useFormik } from "formik";
const Productmanage = ({}) => {
  const navigate = useNavigate();
  const [countrycodes, setcountrycodes] = useState([false]);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //formik addsubmit
  const formik = useFormik({
    initialValues: {
      Description: "",
      country_codes: "",
      bin: "",
      startrange: 0,
      endrange: 0,
    },
    validationSchema: Yup.object({
      Description: Yup.string().required("required"),
      country_codes: Yup.string().required("required"),
      bin: Yup.number().required("required"),
      startrange: Yup.number().required("required"),
      endrange: Yup.number().required("required"),
    }),
    onSubmit: async (values) => {
      // values.role = parseInt(values.role);
      // console.log(values);

      try {
        let post = await AuthPost("nymcard/cardproducts", values, "admin");
        console.log("postssss", post);
        if (post.statusCode == 200) {
          toast.success("Product added successfully");
          setShow(false);
        }
      } catch (error) {
        toast.error("Error");
        console.error("error", error);
      }
    },
  });

  useEffect(() => {
    getCurrency();
    get();
    // getRoles();
  }, []);

  const getCurrency = async () => {
    await AuthGet(`nymcard/allowedcountries`, "admin")
      .then((res) => {
        setData(res.data);
        setcountrycodes();
        console.log("res", res.data);
      })
      .catch((err) => {
        console.log("hgdfhfdh");
      });
  };

  const get = async () => {
    await AuthGet("nymcard/cardproducts", "admin")
      .then((res) => {
        if (res?.statusCode === 200) console.log("rest", res);
        setData1(res?.data);
        // setcountrycodes()
        console.log("res", res);
      })
      .catch((err) => {
        console.log("hgdfhfdh");
      });
  };

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      classes: "ref_link",
      events: {
        // onClick: (e, column, columnIndex, row, rowIndex) => {
        //   navigate(`/admin/productmanagement/${row.id}`);
        // },
      },
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "status",
      text: "status",
      sort: true,
      headerStyle: {
        width: "250px",
      },
    },
    // {
    //     dataField: "", text: 'Edit', sort: true,
    //     formatter: () => {
    //         return <button className="btn deleteIcon">
    //             <img src={updateIcon} />
    //         </button>
    //     },
    //     events: {
    //         onClick: (e, column, columnIndex, row, rowIndex) => {
    //             console.log("MANAGE_DATA", row)
    //             // getEditPractice(row?.id);
    //         },
    //     },
    //     headerStyle: {
    //         width: '100px',
    //     }
    // },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
    {
      dataField: "descrption",
      order: "desc",
    },
    {
      dataField: "status",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const { SearchBar, ClearSearchButton } = Search;
  return (
    <>
      <div className="whiteFrame">
        <div className="whiteFrameHeader displayFlex justifyConentEnd mb_12">
          <div>
            <button
              onClick={() => setShow(true)}
              className="mainPrimary_btn greenishTeal_Clr"
            >
              Add Product Management
            </button>
          </div>
        </div>
      </div>
      {/* Admin Add modal */}
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add Product Management</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                Description
              </label>

              <input
                type="text"
                placeholder="Enter Description"
                className="adminInput"
                id="Description"
                name="Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Description}
              />
              {formik.touched.Description && formik.errors.Description && (
                <p className="error_text">{formik.errors.Description}</p>
              )}
            </div>
            <div className="mb_24">
              <label htmlFor="type" className="labelText">
                currency codes
              </label>
               
              <select
                name="country_codes"
                id="country_codes"
                className="adminInput"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country_codes}
              >
                {/* <option>Yes Or No</option>

                <option>Free Style</option> */}
                <option defaultValue>Select currency</option>
                {data?.map((e, i) => {
                  console.log(e);
                  return (
                    <option key={i} value={e.value}>
                      {" "}
                      {e.currency_codes}{" "}
                    </option>
                  );
                })}
                {/* <option </option> */}
              </select>
            </div>

            <div className="mb_24">
              <label htmlFor="ContactName" className="labelText">
                Supported currency
              </label>
              <div class="col-6">
                <div class="form-check">{formik.values.country_codes}</div>
                {/* <div class="form-check">{res.data.f2.currency_codes}</div> */}
              </div>
            </div>
            <div className="mb_24">
              <label htmlFor="ContactName" className="labelText">
                settlement currency
              </label>
              <div class="col-6">
                <div class="form-check">{formik.values.country_codes}</div>
                {/* <div class="form-check">{res.data.f2.currency_codes}</div> */}
              </div>
            </div>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                Bin
              </label>

              <input
                type="number"
                placeholder="Enter bin"
                className="adminInput"
                id="bin"
                name="bin"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bin}
              />
              {formik.touched.bin && formik.errors.bin && (
                <p className="error_text">{formik.errors.bin}</p>
              )}
            </div>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                Start Range
              </label>

              <input
                type="number"
                placeholder="Enter startrange"
                className="adminInput"
                id="startrange"
                name="startrange"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.startrange}
              />
              {formik.touched.startrange && formik.errors.startrange && (
                <p className="error_text">{formik.errors.startrange}</p>
              )}
            </div>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                End Range
              </label>

              <input
                type="number"
                placeholder="Enter endrange"
                className="adminInput"
                id="endrange"
                name="endrange"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.endrange}
              />
              {formik.touched.endrange && formik.errors.endrange && (
                <p className="error_text">{formik.errors.endrange}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleClose}
              className="greenishTealClr_hover mainPrimary_btn"
              // disabled
            >
              Close
            </button>
            <button
              type="submit"
              className="mainPrimary_btn greenishTeal_Clr"
              disabled="disabled"
            >
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data1}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default Productmanage;
