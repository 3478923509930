import React, { useState, useEffect } from "react";
// import blobimg from "../../assets/public/borrower-img/blobs.png"
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import "./Dashboard.scss";
import approvedsvg from "../../assets/new/dash_approved.svg";
import dash_pending from "../../assets/new/dash_pending.svg";
import dash_incomplete from "../../assets/new/dash_incomplete.svg";
import dash_denied from "../../assets/new/dash_denied.svg";
import graph from "../../assets/images/graph.svg";
import graph2 from "../../assets/images/graph2.svg";
import allapplication from "../../assets/images/all-application.svg";
import uparrow from "../../assets/images/uparrow.svg";
import opportunitiesicon from "../../assets/images/opportunities-icon.svg";
import rightarrowicon from "../../assets/images/right-arrow.svg";

import calcicon from "../../assets/images/cal-icon.svg";
import chartImage from "../../assets/images/chart-image.png";
import piechart from "../../assets/images/pie-chart.png";

import { AuthGet } from "../../common_var/httpService";

const Dashboard = () => {
  const navigate = useNavigate();
  const [initialState, dispatch, authState] = useStateValue();
  console.log("auth--->", authState);
  const [data, setData] = useState({});
  useEffect(() => {
    getlist();
  }, []);

  const getlist = async () => {
    await AuthGet(`dashboard`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="breadcrumbsection">
        <div>
          <div className="titleText ">Heads Up Display</div>

        </div>
      </div>

      <section className="dashboard">
        <div className="row mb_15">

          <div className="col-md-9">

            <div className="row">
              <div className="col-md-4">
                <div className="dashboardcard" onClick={() => navigate("/admin/customer-management")}>
                  <div className="displayFlex spaceBetween mb30px">
                    <div className="displayFlex">
                      {" "}
                      <div className="dashboardcard-icon">
                        <img src={opportunitiesicon} className="w30px" />
                      </div>
                      <div className="cardblock">
                        <div className="cardtitle">Customer Management </div>
                        <div className="cardtext">
                          <div className="pr30"> $ {data.pendingSum} </div>
                          <div>
                            {" "}&nbsp;&nbsp;
                            <img src={uparrow} />{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ribbonlabel ribbonone"></div>
                  </div>

                  <div className="displayFlex spaceBetween AlignItemCenter">
                    <div className="textwhite">View All</div>
                    <div>
                      {" "}
                      <img src={rightarrowicon} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dashboardcard" onClick={() => navigate("/admin/merchant")}>
                  <div className="displayFlex spaceBetween mb30px">
                    <div className="displayFlex">
                      {" "}
                      <div className="dashboardcard-icon">
                        <img src={opportunitiesicon} className="w30px" />
                      </div>
                      <div className="cardblock">
                        <div className="cardtitle">Merchant Management</div>
                        <div className="cardtext">
                          <div className="pr30"> $ {data.approvedSum} </div>
                          <div>
                            {" "}&nbsp;&nbsp;
                            <img src={uparrow} />{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ribbonlabel ribbontwo"></div>
                  </div>

                  <div className="displayFlex spaceBetween AlignItemCenter">
                    <div className="textwhite">View All</div>
                    <div>
                      {" "}
                      <img src={rightarrowicon} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dashboardcard" onClick={() => navigate("/admin/merchant-admin")}>
                  <div className="displayFlex spaceBetween mb30px">
                    <div className="displayFlex">
                      {" "}
                      <div className="dashboardcard-icon">
                        <img src={opportunitiesicon} className="w30px" />
                      </div>
                      <div className="cardblock">
                        <div className="cardtitle">Merchant Users </div>
                        <div className="cardtext">
                          <div className="pr30"> $ {data.fundingContSum} </div>
                          <div>
                            {" "}&nbsp;&nbsp;
                            <img src={uparrow} />{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ribbonlabel ribbonthree"></div>
                  </div>

                  <div className="displayFlex spaceBetween AlignItemCenter">
                    <div className="textwhite">View All</div>
                    <div>
                      {" "}
                      <img src={rightarrowicon} />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">

                <div className="whitebg chartBlock minHeight360">

                  <div className="charttitle"> Monthly Activity Report</div>

                  <img src={chartImage} className="width100per" />


                </div>


              </div>

              <div className="col-md-4">

                <div className="whitebg chartBlock minHeight360">

                  <div className="charttitle"> Statewise </div>

                  <img src={piechart} className="width100per" />


                </div>


              </div>





            </div>







          </div>


          <div className="col-md-3">
            <div className="whitebg">
              <div className="recentactivity">
                <div className="recenttitle">Recent Activity</div>

                <div className="recentblock">
                  <div className="displayFlex">
                    <div className="calc-icon">
                      <img src={calcicon} />
                    </div>

                    <div className="recenttext">
                      <div className="fs12px wordbreak">thoddusamyg+2401_01@theecode.xyz - Customer</div>
                      <div className="fs12px">Personal Information updated.</div>
                      <div className="fontsize12">01-23-2023</div>
                    </div>
                  </div>
                </div>

                <div className="recentblock">
                  <div className="displayFlex">
                    <div className="calc-icon">
                      <img src={calcicon} />
                    </div>

                    <div className="recenttext">
                      <div className="fs12px">sjtaherahmed@gmail.com - Customer</div>
                      <div className="fs12px">Save EligibleOffer</div>
                      <div className="fontsize12">01-23-2023</div>
                    </div>
                  </div>
                </div>



                <div className="recentblock">
                  <div className="displayFlex">
                    <div className="calc-icon">
                      <img src={calcicon} />
                    </div>

                    <div className="recenttext">
                      <div className="fs12px">sjtaherahmed@gmail.com - Customer</div>
                      <div className="fs12px">Plaid Login.</div>
                      <div className="fontsize12">01-23-2023</div>
                    </div>
                  </div>

                </div>


                <div className="recentblock">
                  <div className="displayFlex">
                    <div className="calc-icon">
                      <img src={calcicon} />
                    </div>

                    <div className="recenttext">
                      <div className="fs12px">thoddusamyg+2401_01@theecode.xyz - Customer</div>
                      <div className="fs12px">Your Personal Information Updated.</div>
                      <div className="fontsize12">01-22-2023</div>
                    </div>
                  </div>

                </div>




              </div>
            </div>
          </div>



        </div>



      </section>
    </>
  );
};

export default Dashboard;
