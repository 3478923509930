import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthGet, AuthPost } from "../../common_var/httpService";

const AdminSecurity = () => {

  const navigate = useNavigate();
  const [practiceName, setPracticeName] = useState("");
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setForm] = useState({
    currentpw: '',
    newpw: '',
    cnewpw: ''
  });

  const [ruleCheck, setRuleCheck] = useState('');

  let login_user_id = JSON.parse(sessionStorage.getItem('resuser')).id;

  useEffect(() => {
    getPracticeList();
  }, []);

  const getPracticeList = async () => {
    await AuthGet(`users/getTwoFactorAuth/${login_user_id}`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          setRuleCheck(res?.data?.twofactorauth);
        }
      })
  }

console.log('statusCode', ruleCheck);

let fa2 = ruleCheck === 'N' ? false : true;



  const handleInputChange = (e) => {
    e.preventDefault();
    const input_name = e.target.name;
    const input_value = e.target.value;
    setForm({ ...form, [input_name]: input_value });
  };

  const handleChange = async (e) => {
    console.log("CHECKED", e.target.checked);
    let sendData = {
      value: e.target.checked
    };
    await AuthPost(`users/toggleTwoFactorAuth/${login_user_id}`, sendData ,'admin')
    .then( (res) => {
       if(res?.statusCode === 200){
        getPracticeList();
       }
    })
  }

  const login = async (e) => {
    e.preventDefault();
    let sendData = {
      currentpw: form?.currentpw.toString(),
      newpw: form?.newpw.toString(),
      cnewpw: form?.cnewpw.toString()
    }

    await AuthPost(`users/changepassword/${login_user_id}`, sendData, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          setForm({
            currentpw: '',
            newpw: '',
            cnewpw: ''
          })
          handleClose();
          getPracticeList();
        }
      })

  }



  return (<div>

    <div className="breadcrumbsection">

      <div>
        <div className="titleText "> Security</div>
        {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /  Admin Security */}


      </div>


    </div>




    <div className="container-fluid">
      <div className="row">




        <div className="col-md-6">

          <div className="frameshadow mb20px">
            <div className="titleText mb20px">
              Change password
            </div>
            <form>
              <label for="currentpw">Current Password</label><br />
              <input
                type="password"
                id="currentpw"
                name="currentpw"
                formControlName="currentpw"
                class="form-control mb20px"
                onChange={handleInputChange}
              />


              <label for="newpw">New Password</label><br />
              <input
                type="password"
                id="newpw"
                name="newpw"
                formControlName="newpw"
                class="form-control"
                onChange={handleInputChange}
              />

              <br />
              <label for="cnewpw">Confirm New Password</label><br />
              <input
                type="password"
                id="cnewpw"
                name="cnewpw"
                formControlName="cnewpw"
                class="form-control"
                onChange={handleInputChange}
              />
              <div >
              </div>
              <br />
              <button type="submit" class="btn btn-outline-primary"
                onClick={(e) => login(e)}
              >Done</button>
            </form>

          </div>





        </div>
        <div className="col-md-6">

          <div className="frameshadow mb20px">
            <div className="titleText mb20px">
              2FA (Two Factor Auth)
            </div>
            <div class="two_factor">
              <div >Enable 2FA</div>
              {/* <div class="switch"> */}
                <input 
                type="checkbox" 
                id="twofactorauth" 
                name="rulecheck"
                onChange={handleChange}
                checked ={fa2 === true}
                />
                {/* <label for="twofactorauth"></label> */}
              {/* </div> */}
            </div>

          </div>


        </div>
      </div>


    </div>



    {/* 
    <div class="content_part">
      <div class="view_content_part">

        <div class="setting_header">
          <h5>Change password</h5>
        </div>

        <div class="change_password">

        </div>

        <div class="setting_header">
          <h5>Two factor Authentication</h5>
        </div>
        <div class="two_factor">
          <div >Enable Two factor Authentication</div>
          <div class="switch">
            <input type="checkbox" id="twofactorauth" />
            <label for="twofactorauth"></label>
          </div>
        </div>
      </div>

    </div> */}




  </div>)
};

export default AdminSecurity;
