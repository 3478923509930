import React from 'react'
import { useRoutes, useLocation, useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { AuthGet, AuthPost } from '../../common_var/httpService';
import { toast } from 'react-hot-toast';
import Toast from '../../Components/Toaster/Toast';
import { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useFormik } from 'formik';
import * as Yup from "yup"
const Comments = () => {
    const [show, setShow] = useState(false);

    const [comments, setComments] = useState();
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const { id } = useParams()

    const formik = useFormik({
        initialValues: {
            subject: "",
            comments: "",
        },
        validationSchema: Yup.object({
            subject: Yup.string()
                .required('Required'),
            comments: Yup.string()
                .required('Required'),

        }),
        onSubmit: (values, { resetForm }) => {
            console.log("123", values)

            let data = { ...values, merchant_id: id }

            resetForm({ values: "" })

            AuthPost(`practice/addcomments`, data, "admin").then((res) => {
                console.log("Comments", res)
                if (res.statusCode === 200) {
                    toast.success("Comment added successfully")
                    getComments(id)
                    handleClose();

                }
                else{
                    toast.error("Please fill commemt ")
                  }
 
            }).catch((err) => {
                console.log("err--->", err);
                toast.error(err.message)
                toast.error("Please fill commemt ")
            })
        }
    })
    // const handleComment = async () => {
    //     let commentsData = {
    //         subject: commentsSubject.current.value,
    //         comments: commentsComment.current.value,
    //         merchant_id: id
    //     }
    //     console.log("data: ", commentsData);

    // }
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => setShow(false);

    useEffect(() => {
        getComments(id);
    }, [])

    const getComments = () => {
        AuthGet(`practice/getcomments/${id}`, "admin").then((res) => {
            console.log("Comments", res.data)
            if (res.statusCode === 200) {
                setComments(res.data)
            }
        }).catch((err) => {
            console.log("err--->", err);
            toast.error(err.message)
        })
    }

    const columns = [
        {
            dataField: "", text: 'S.NO', sort: true,
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            headerStyle: {
                width: '50px',
            }
        },
        {
            dataField: "subject", text: 'Subject', sort: true,
            headerStyle: {
                width: '150px',
            }
        },
        {
            dataField: "comments", text: 'Comments', sort: true,
            headerStyle: {
                width: '150px',
            }
        },
        {
            dataField: "created_at", text: 'Created Date', sort: true,
            headerStyle: {
                width: '100px',
            }
        },
    ];

    const defaultSorted = [
        {
            dataField: 'subject',
            order: 'desc'
        },
        {
            dataField: 'comments',
            order: 'desc'
        },
        {
            dataField: 'created_at',
            order: 'desc'
        },
    ];

    const pagination = paginationFactory({
        page: page,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
            setCurrentPage(page);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    const { SearchBar, ClearSearchButton } = Search;

    return (
        <>
            <Toast />
            <div>

                <button className="btn btn-primary" onClick={handleShow}>Ask Customer
                </button>

                <Modal show={show} onHide={handleClose}>
                    <form onSubmit={formik.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Comment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input
                                type="text"
                                placeholder="Subject"
                                id="subject"
                                name="subject"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.subject}
                                className="form-control inputField mb10px"
                            />
                            {formik.touched.subject && formik.errors.subject ? (
                                <p className="requiredText">{formik.errors.subject}</p>
                            ) : null} <br />
                            <textarea
                                cols="30"
                                rows="10"
                                placeholder="Comment"
                                className="form-control inputField mb10px"
                                id="comments"
                                name="comments"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.comments} />
                            {formik.touched.comments && formik.errors.comments ? (
                                <p className="requiredText">{formik.errors.comments}</p>
                            ) : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" type='button' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" type='submit'>
                                Add Comment
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <div className="frameshadow">
                    <ToolkitProvider
                        bootstrap4
                        keyField='id'
                        data={comments ? comments : []}
                        columns={columns}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <ClearSearchButton {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable
                                        bootstrap4
                                        key='id'
                                        defaultSorted={defaultSorted}
                                        pagination={pagination}
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
            </div>
        </>
    )
}

export default Comments