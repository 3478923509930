import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
// import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Toast from "../Toaster/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { EmailRegex, onlyNumbers } from "../../common_var/Regex";

const CustomCustomerModal = ({ btnName, data, doc, fun, type }) => {
  console.log(type);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(data?.customer_status);
  const [doctype, setDoctype] = useState([]);
  const [file, setFile] = useState("");
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  const letterOnly=/^[A-Za-z ]*$/
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const numberonly='^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$'
  const rejectSpclChar='/[(!@#$%^&*)]+/'
  console.log("Status: ", status);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setFile(null)
    formikEditUser.resetForm()
  }
  const formikEditUser = useFormik({
    initialValues: {
      wallet_creditlimit: data?.wallet_creditlimit,
      firstname: data?.firstname,
      lastname: data?.lastname,
      national_id: data?.national_id,
      email: data?.email,
      phone: data?.phone,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
    wallet_creditlimit: Yup.string()
      .required("Credit Limit is required")
      .matches(numberonly,"Credit Limit is not valid")
      ,
      firstname: Yup.string()
        .min(1, "First Name Must Be Between 1 and 50 Characters")
        .max(50, "First Name Must Be Between 1 and 50 Characters")
        .required("First Name is required")
        .matches(letterOnly, 'Please enter valid name'),
      lastname: Yup.string()
        .min(1, "First Name Must Be Between 1 and 50 Characters")
        .max(50, "First Name Must Be Between 1 and 50 Characters")
        .required("Last Name is required")
        .matches(letterOnly, 'Please enter valid name'),
      national_id: Yup.string()
      .required("National ID is required")
      .matches(/^[A-Za-z0-9]{1,}$/g,"Please enter a valid National ID")
      ,
      email: Yup.string()
        .matches(EmailRegex, "Enter  Valid Email Address")
        .required("Email is required"),
      phone: Yup.string()
        .test(
          "length",
          "Enter  Valid Phone Number",
          (val) => val?.toString().length === 10
        )
        .required("Phone is required")
        .matches(phoneRegExp,"Phone number is not valid")
        ,
    }),
    onSubmit: async (values) => {
      values.wallet_creditlimit = parseInt(values.wallet_creditlimit);
      console.log("Update customer value: ", values);
      // console.log("Data: ", data);

      await AuthPost(`customer-mgt/update-customer/${loanId}`, values, "admin")
        .then((res) => {
          if (res.statusCode == 200) {
            handleClose();
            fun();
            toast.success("User Updated Successfully");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    },
  });

  const dropStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdateStatus = () => {
    console.log("Status type: ", status);
    AuthPost(
      `customer-mgt/update-customer-status/${loanId}`,
      { customer_status: status },
      "admin"
    )
      .then((res) => {
        if (res.statusCode == 200) {
          handleClose();
          fun();
          toast.success("Status Updated Successfully");
        } else if (res.statusCode == 500) {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const dropDoctype = (e) => {
    console.log("Doc type: ", e.target.value);
    setDoctype(e.target.value);
  };

  const handleFileChange = (e) => {
    console.log("Uploaded file: ", e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const handleUploadDocument = () => {
    console.log("Document type: ", doctype);
    console.log("File: ", file);
    console.log("Doc Ids: ", doc);
    let docId;
    console.log(type);
    if (type && type == "merchant") {
      const result = doc.filter((e) => {
        return e.type === doctype;
      });
      console.log(result[0].id, result[0].type);
      docId = result[0].id;
    } else {
      docId = doctype === "Passport" ? doc[0].id : doc[1].id;
    }

    // let user_id = sessionStorage.getItem("UserId");

    const formData = new FormData();
    formData.append("documentTypesID", docId);
    // formData.append('loan_id', data);
    formData.append("files", file);
    formData.append("link_ID", loanId);
    console.log(formData, "Formdata");

    AuthFile(`uploadfiles/uploads`, formData, "admin")
      .then((res) => {
        // getDocs(id);
        if (res.statusCode === 200) {
          toast.success("File Uploaded");
          handleClose();
          fun();
        }
        else{
          toast.error("Please upload file ")
        }
        console.log("res---->", res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const formikCreateUser = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      national_id: "",
      email: "",
      phone: "",
      annualIncome: "",
      city: "",
      country: "",
      date_of_birth: "",
      // nymcard_allow: false,
      state: "",
      street_address: "",
      zipcode: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object({
      firstname: Yup.string()
        .min(1, "First Name Must Be Between 1 and 50 Characters")
        .max(50, "First Name Must Be Between 1 and 50 Characters")
        .required("First Name is required"),

      lastname: Yup.string()
        .min(1, "First Name Must Be Between 1 and 50 Characters")
        .max(50, "First Name Must Be Between 1 and 50 Characters")
        .required("Last Name is required"),
      national_id: Yup.string().required("National ID is required"),
      email: Yup.string()
        .matches(EmailRegex, "Enter A Valid Email Address")
        .required("Email is required"),
      phone: Yup.string()
        .test(
          "length",
          "Enter A Valid Phone Number",
          (val) => val?.toString().length === 10
        )
        .required("Phone is required"),
      annualIncome: Yup.string().required("Annual Income is required"),
      city: Yup.string()
        .min(1, "City Must Be Between 1 and 200 Characters")
        .max(200, "City Must Be Between 1 and 200 Characters")
        .required("City is required"),
      country: Yup.string().required("Country is required"),
      date_of_birth: Yup.date()
        .max(
          new Date(Date.now() - 567648000000),
          "You must be at least 18 years"
        )
        .required("DOB is required"),
      // .min(new Date()
      // nymcard_allow: Yup.boolean(),
      state: Yup.string()
        .min(1, "State Must Be Between 1 and 200 Characters")
        .max(200, "State Must Be Between 1 and 200 Characters")
        .required("State is required"),
      street_address: Yup.string()
        .min(1, "Street Address Must Be Between 1 and 200 Characters")
        .max(200, "Street Address Must Be Between 1 and 200 Characters")
        .required("Street Address is required"),
      zipcode: Yup.string()
        .required("Zipcode is required")
        .test(
          "length",
          "Enter  Valid Zipcode Number ",
          (val) => val?.toString().length === 6
        ),
    }),
    onSubmit: async (values) => {
      values.annualIncome = parseInt(values.annualIncome);
      // values.date_of_birth = new Date(values.date_of_birth);
      console.log("Create customer: ", values);
      // values.country_id = "db86913a-04d5-4de4-bfb2-cc184c165ff3" // need to be dynamic

      await AuthPost("customer-mgt/add-customer", values, "admin")
        .then((res) => {
          if (res.statusCode == 200) {
            window.location.reload();
            
            console.log("Res: ", res);
            handleClose();
            fun();
            toast.success("Customer Added Successfully");
            values.firstname = "";
            values.lastname = "";
            // values.national_id = "";
            values.email = "";
            values.phone = "";
            values.annualIncome = "";
            values.city = "";
            values.country = "";
            values.date_of_birth = "";
            values.state = "";
            values.street_address = "";
            values.zipcode = "";
          } else if (res.statusCode == 400) {
            toast.error(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    },
  });

  

  return (
    <>
      <Toast />
      <button className="btn btn-primary" onClick={handleShow}>
        {btnName}
      </button>

      {btnName === "Edit" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formikEditUser.handleSubmit}>
              <div>
                <div>
                  <label htmlFor="wallet_creditlimit">Credit Limit</label>
                  <input
                    type="text"
                    id="wallet_creditlimit"
                    name="wallet_creditlimit"
                    class="form-control inputField mb10px"
                    onChange={formikEditUser.handleChange}
                    value={formikEditUser.values.wallet_creditlimit}
                    onBlur={formikEditUser.handleBlur}
                    placeholder="Enter Credit Limit"
                  />
                  {formikEditUser.touched.wallet_creditlimit &&
                  formikEditUser.errors.wallet_creditlimit ? (
                    <p className="error_text">
                      {formikEditUser.errors.wallet_creditlimit}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    class="form-control inputField mb10px"
                    value={formikEditUser.values.firstname}
                    onChange={formikEditUser.handleChange}
                    onBlur={formikEditUser.handleBlur}
                    placeholder="Enter First Name"
                  />
                  {formikEditUser.touched.firstname &&
                  formikEditUser.errors.firstname ? (
                    <p className="error_text">
                      {formikEditUser.errors.firstname}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    class="form-control inputField mb10px"
                    value={formikEditUser.values.lastname}
                    onChange={formikEditUser.handleChange}
                    onBlur={formikEditUser.handleBlur}
                    placeholder="Enter First Name"
                  />
                  {formikEditUser.touched.lastname &&
                  formikEditUser.errors.lastname ? (
                    <p className="error_text">
                      {formikEditUser.errors.lastname}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="national_id">National ID</label>
                  <input
                    type="text"
                    id="national_id"
                    name="national_id"
                    class="form-control inputField mb10px"
                    value={formikEditUser.values.national_id}
                    onChange={formikEditUser.handleChange}
                    onBlur={formikEditUser.handleBlur}
                    placeholder="Enter First Name"
                    maxLength={15}
                  />
                  {formikEditUser.touched.national_id &&
                  formikEditUser.errors.national_id ? (
                    <p className="error_text">
                      {formikEditUser.errors.national_id}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    class="form-control inputField mb10px"
                    value={formikEditUser.values.email}
                    onChange={formikEditUser.handleChange}
                    onBlur={formikEditUser.handleBlur}
                    placeholder="Enter First Name"
                  />
                  {formikEditUser.touched.email &&
                  formikEditUser.errors.email ? (
                    <p className="error_text">{formikEditUser.errors.email}</p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    class="form-control inputField mb10px"
                    value={formikEditUser.values.phone}
                    onChange={formikEditUser.handleChange}
                    onBlur={formikEditUser.handleBlur}
                    placeholder="Enter First Name"
                  />
                  {formikEditUser.touched.phone &&
                  formikEditUser.errors.phone ? (
                    <p className="error_text">{formikEditUser.errors.phone}</p>
                  ) : null}
                </div>
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit">Update</Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      ) : btnName === "Change Status" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="">Status</label>
            <select
              name="documentType"
              onChange={dropStatus}
              defaultValue={status}
              className="form-control inputField mb10px"
            >
              <option value="">Select Status</option>
              <option value="Pending">Pending</option>
              <option value="Verified">Verified</option>
              <option value="System on-hold">System on-hold</option>
              <option value="Management on-hold">Management on-hold</option>
              <option value="Late payment hold">Late payment hold</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleUpdateStatus}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Upload Documents" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {type && type == "merchant" ? (
              <select
                name="documentType"
                onChange={dropDoctype}
                className="form-control inputField mb10px"
              >
                <option value="">Select Document Type</option>
                <option value="passport">Passport</option>
                <option value="national id">National ID</option>
                <option value="trade license">Trade License</option>
                <option value="owner id ">Owner ID</option>
                <option value="other documents">Others</option>
              </select>
            ) : (
              <select
                name="documentType"
                onChange={dropDoctype}
                className="form-control inputField mb10px"
              >
                <option value="">Select Document Type</option>
                <option value="Passport">Passport</option>
                <option value="national id">National ID</option>
              </select>
            )}

            <div className="form-control inputField mb10px">
              <label htmlFor="fileUpload">
                Upload file
                <input
                  onChange={handleFileChange}
                  type="file"
                  accept="image/png, application/pdf"
                  id="fileUpload"
                  hidden
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUploadDocument}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Add Customer" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formikCreateUser.handleSubmit}>
              <div>
                <div>
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.firstname.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter First Name"
                  />
                  {formikCreateUser.touched.firstname &&
                  formikCreateUser.errors.firstname ? (
                    <p className="error_text">
                      {formikCreateUser.errors.firstname}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.lastname.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter Last Name"
                  />
                  {formikCreateUser.touched.lastname &&
                  formikCreateUser.errors.lastname ? (
                    <p className="error_text">
                      {formikCreateUser.errors.lastname}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="national_id">National ID</label>
                  <input
                    type="text"
                    id="national_id"
                    name="national_id"
                    maxLength={12}
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.national_id.replace(
                      /[^\w\s\][^,]/gi,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter National ID"
                  />
                  {formikCreateUser.touched.national_id &&
                  formikCreateUser.errors.national_id ? (
                    <p className="error_text">
                      {formikCreateUser.errors.national_id}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="date_of_birth">Date Of Birth</label>
                  <input
                    type="date"
                    id="date_of_birth"
                    name="date_of_birth"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.date_of_birth}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter DOB"
                  />
                  {formikCreateUser.touched.date_of_birth &&
                  formikCreateUser.errors.date_of_birth ? (
                    <p className="error_text">
                      {formikCreateUser.errors.date_of_birth}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.email}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter Email"
                  />
                  {formikCreateUser.touched.email &&
                  formikCreateUser.errors.email ? (
                    <p className="error_text">
                      {formikCreateUser.errors.email}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    maxLength={10}
                    id="phone"
                    name="phone"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.phone.replace(/[^\d]/g, "")}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter Phone"
                  />
                  {formikCreateUser.touched.phone &&
                  formikCreateUser.errors.phone ? (
                    <p className="error_text">
                      {formikCreateUser.errors.phone}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="street_address">Street Address</label>
                  <input
                    type="text"
                    id="street_address"
                    name="street_address"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.street_address.replace(
                      /[^\w\s\][^,]/gi,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter Street Address"
                  />
                  {formikCreateUser.touched.street_address &&
                  formikCreateUser.errors.street_address ? (
                    <p className="error_text">
                      {formikCreateUser.errors.street_address}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.city.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter City"
                  />
                  {formikCreateUser.touched.city &&
                  formikCreateUser.errors.city ? (
                    <p className="error_text">{formikCreateUser.errors.city}</p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.state.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter state"
                  />
                  {formikCreateUser.touched.state &&
                  formikCreateUser.errors.state ? (
                    <p className="error_text">
                      {formikCreateUser.errors.state}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="country">Country</label>
                  <select
                    onChange={(e) => {
                      console.log(e.target.value);
                      formikCreateUser.setFieldValue("country", e.target.value);
                    }}
                    name="country"
                    value={formikCreateUser.values.country}
                    className="form-control inputField mb10px"
                  >
                    <option>Select Country</option>
                    <option value="Afganistan">Afganistan</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="India">India</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                  </select>
                  {formikCreateUser.touched.country &&
                  formikCreateUser.errors.country ? (
                    <p className="error_text">
                      {formikCreateUser.errors.country}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="zipcode">Zip Code</label>
                  <input
                    type="text"
                    id="zipcode"
                    maxLength={6}
                    name="zipcode"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.zipcode.replace(
                      /[^0-9\\.]+/g,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter zip code"
                  />
                  {formikCreateUser.touched.zipcode &&
                  formikCreateUser.errors.zipcode ? (
                    <p className="error_text">
                      {formikCreateUser.errors.zipcode}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="annualIncome">
                    Individual Annual Income (AED)
                  </label>
                  <input
                    type="text"
                    id="annualIncome"
                    name="annualIncome"
                    class="form-control inputField mb10px"
                    value={formikCreateUser.values.annualIncome.replace(
                      /[^0-9\\.]+/g,
                      ""
                    )}
                    onChange={formikCreateUser.handleChange}
                    onBlur={formikCreateUser.handleBlur}
                    placeholder="Enter Annual Income"
                  />
                  {formikCreateUser.touched.annualIncome &&
                  formikCreateUser.errors.annualIncome ? (
                    <p className="error_text">
                      {formikCreateUser.errors.annualIncome}
                    </p>
                  ) : null}
                </div>
                {/* <div>
                                    <input 
                                        type="checkbox"
                                        name="nymcard_allow"
                                        id="nymcard_allow"
                                        value={formikCreateUser.values.nymcard_allow}
                                        onChange={formikCreateUser.handleChange}
                                        onBlur={formikCreateUser.handleBlur} />
                                    <label htmlFor="nymcard_allow" style={{marginLeft: "20px"}}>Nym Card</label>
                                </div> */}
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CustomCustomerModal;
