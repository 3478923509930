import React, { useEffect, useState } from "react";
import breadcrumb_dash from '../../assets/new/breadcrumb_dash.svg';
import file_open_active from '../../assets/new/file_open_active.svg';
import search from '../../assets/new/search.svg';
import plus from '../../assets/new/plus.svg';
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

function AuditLog() {
  const navigate = useNavigate();
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);



  useEffect(() => {
    getPracticeList();
  }, []);



  const getPracticeList = async () => {
    await AuthGet(`auditlogs`, 'admin')
      .then((res) => {
        console.log("AUDIT_LOG", res?.data)
        setLoanData(res?.data);
        setPage(Math.ceil(loanData.length / 10));
        setCurrentPage(1);
      })
  }







  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: '50px',
      }
    },
    {
      dataField: "user", text: 'User', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "module", text: 'Activity', sort: true,
      headerStyle: {
        width: '150px',
      }
    },
    {
      dataField: "createdat", text: 'Activity date', sort: true,
      headerStyle: {
        width: '150px',
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    },
    {
      dataField: 'loan_id',
      order: 'desc'
    },
    {
      dataField: 'module',
      order: 'desc'
    },
    {
      dataField: 'user',
      order: 'desc'
    },
    {
      dataField: 'createdat',
      order: 'desc'
    }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;





  return (<div>

    <div className="breadcrumbsection">

      <div>
        <div className="titleText "> Trace</div>
        {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /  Audit Log */}


      </div>


    </div>







    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={loanData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>

  </div>);
}

export default AuditLog;







