import React, { useState, useEffect } from "react";
import PhoneValidator from "./PhoneValidator";
import { useStateValue } from "../../Context/StateProvider";
import PhoneNumberFormatter from "./Formatter";

const Phone = ({ name, labelName, placeholder, handleChange, required }) => {
  const [isTouched, setIsTouched] = useState(false);
  const [{ errors, formData, formSubmitted }, dispatch] = useStateValue();
  return (
    <>
      <div className="col-md-6 mb_24">
        {errors[name] ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <input
          type="text"
          id={name}
          placeholder={placeholder}
          name={name}
          maxLength={14}
          className="MainInput"
          value={PhoneNumberFormatter(formData[name])}
          onChange={(e) => handleChange(e)}
          onBlur={() => setIsTouched(true)}
          style={errors[name] ? { border: "1px solid #f44336" } : null}
        />
        {required ? (
          <div style={{ display: "none" }}>
            <PhoneValidator
              name={name}
              value={formData[name]}
              labelname={labelName}
              isTouched={isTouched}
              formSubmitted={formSubmitted}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Phone;
