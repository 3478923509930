import React from 'react'

const Offer = () => {
    return (
        <>
            <div className='frameshadow mb_30'>Offer</div>
            <div className='frameshadow mb_30'>Deals</div>
        </>
    )
}

export default Offer