import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Get, Post } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import DateField from "../../Components/Date/DateField";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Email from "../../Components/Email/Email";
import SSN from "../../Components/SSN/SSN";
import Text from "../../Components/Text/Text";
import States from "../../Components/UsStates/States";
import { useStateValue } from "../../Context/StateProvider";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";

const PersonalInformation = ({ apiUrl, setStep }) => {
  const [{ errors, formData, step, cosignerCheckbox }, dispatch] =
    useStateValue();
  const { loanid } = useParams();
  const [userDetails, setUserDetails] = useState({
    email: "",
    mobile: "",
  });

  const getDetails = async () => {
    const userId = sessionStorage.getItem("userId");
    try {
      let resp = await Get(`loan/getuser/${userId}`, "admin");
      setUserDetails(resp.data[0]);
      if (step === 1) {
        let res = await Get(`loan/getpersonalinfo/${loanid}`, "admin");
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0]
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    if (!formData.agree) {
      dispatch({
        type: "SET_ERRORS",
        payload: { personalCheckbox: "not checked" },
      });
    }
    getDetails();
  }, [formData.agree]);

  const handleChange = (e) => {
    const name = e.target.name;
    let val = e.target.value;
    
    if (name === "zipcode" || name === "coSignor_zipcode") {
      val = val.replace(/[^\d]/g, "");
    }

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    if (Object.keys(errors).length === 0) {
      const bodyData = {
        ...formData,
        loan_id: loanid,
        isCosignor: cosignerCheckbox,
        email: userDetails.email,
        phone: userDetails.mobile,
      };
      try {
        const resp = await Post("loan/personalInfo", bodyData, "admin");
        if (resp.statusCode === 200) {
          toast.success(resp.message);
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          // setTimeout(() => {
          dispatch({
            type: "SET_STEP",
            payload: 2,
          });
          // }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCheckBox = (val) => {
    if (val === true) {
      dispatch({
        type: "REMOVE_ERROR",
        payload: "personalCheckbox",
      });
    } else if (val === false) {
      dispatch({
        type: "SET_ERRORS",
        payload: { personalCheckbox: "not checked" },
      });
    }
  };

  const today = new Date();
  let maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  let minDate = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );

  return (
    <>
      <Toast />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <Text
            name={"firstname"}
            labelName={"First Name"}
            placeholder={"First Name"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            name={"middle_initial"}
            labelName={"Middle Initials"}
            placeholder={"Middle Initials"}
            handleChange={handleChange}
          />
          <Text
            name={"lastname"}
            labelName={"Last Name"}
            placeholder={"Last Name"}
            handleChange={handleChange}
            required={true}
          />
          <DateField
            minYear={minDate.getFullYear()}
            minDate={minDate}
            maxDate={maxDate}
            name={"date_of_birth"}
            labelName={"Date of Birth"}
            placeholder="Date of Birth (MM-DD-YYYY)"
            handleChange={handleChange}
            required={true}
          />
          <SSN
            name={"ssn"}
            placeholder={"Social Security Number"}
            labelName={"Social Security Number "}
            handleChange={handleChange}
            required={true}
          />
          <Text
            name={"street_address"}
            labelName={"Street Address"}
            placeholder={"Example: 123 Main Street"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            name={"unit"}
            labelName={"Unit"}
            placeholder={"Example: 10B"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            name={"city"}
            labelName={"City"}
            placeholder={"Example: Alaska"}
            handleChange={handleChange}
            required={true}
          />
          <States
            name={"state"}
            placeholder={"Select State"}
            labelName={"State"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            maxLen={6}
            name={"zipcode"}
            labelName={"Zipcode"}
            placeholder={"Example: 12345"}
            handleChange={handleChange}
            required={true}
          />
          <Dropdown
            data={[
              { key: "married", value: "Married" },
              { key: "unmarried", value: "Unmarried" },
            ]}
            name={"marital_status"}
            labelName={"Marital Status"}
            handleChange={handleChange}
            required={true}
          />
          <Dropdown
            data={[
              { key: "resident", value: "Resident" },
              { key: "non-resident", value: "Non-Resident" },
              { key: "citizen", value: "Citizen" },
            ]}
            name={"usresidency_status"}
            labelName={"US Residency Status"}
            handleChange={handleChange}
            required={true}
          />
          <Dropdown
            data={[
              { key: "english", value: "English" },
              { key: "spanish", value: "Spanish" },
            ]}
            name={"spoken_language"}
            labelName={"Spoken Language"}
            handleChange={handleChange}
            required={true}
          />
          <Email
            name={"sales_rep_email"}
            labelName={"Sales Rep Email"}
            placeholder={"Sales Rep Email"}
            handleChange={handleChange}
          />
          <div className="customCheckbox mb_24">
            <input
              checked={formData.agree}
              type="checkbox"
              name="agree"
              id="PersonalCheck"
              onChange={(e) => handleCheckBox(e.target.checked)}
            />
            <label htmlFor="PersonalCheck">
              I consent to the{" "}
              <a
                href={`${process.env.REACT_APP_ADMIN_API_URL}/files/download/othermessaging_v1-${loanid}.pdf`}
                style={{ color: "blue" }}
                target={"_blank"}
              >
                SMS Policy
              </a>
              ,&nbsp;
              <a
                href={`${process.env.REACT_APP_ADMIN_API_URL}/files/download/creditpull-${loanid}.pdf`}
                style={{ color: "blue" }}
                target={"_blank"}
              >
                Credit Pull Consent
              </a>
              &nbsp;and,&nbsp;
              <a
                href={`${process.env.REACT_APP_ADMIN_API_URL}/files/download/esignature-${loanid}.pdf`}
                style={{ color: "blue" }}
                target={"_blank"}
              >
                E-signature agreements
              </a>
            </label>
          </div>
          <Button
            type={"submit"}
            value={"Continue"}
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default PersonalInformation;
