import React, { useState, useEffect } from "react";
// import { useStateValue } from "../../Context/StateProvider";
// import NumberValidator from "./NumberValidator";
import Modal from "react-bootstrap/Modal";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
import toast from "react-hot-toast";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import * as Yup from "yup";
import updateIcon from "../../assets/images/updateIcon.svg";

import { useFormik } from "formik";
// import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const Balancelimit = ({}) => {
  const navigate = useNavigate();
  const [data2, setData2] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  //formik addsubmit
  const formik = useFormik({
    initialValues: {
      Description: "",
      type: "",
      Minimunamount: 0,
      Maximumamount: 0,
      Currency: 0,
    },
    validationSchema: Yup.object({
      Description: Yup.string().required("required"),
      type: Yup.string().required("required"),
      Currency: Yup.string().required("required"),
      Minimunamount: Yup.number().required("required"),
      Maximumamount: Yup.number().required("required"),
    }),
    onSubmit: async (values) => {
      values.role = parseInt(values.role);
      console.log(values);

      try {
        // let post = await AuthPost("users/add", values, "admin");
        // console.log("postssss", post);
        // if (post.statusCode == 200) {
        //   toast.success("User Added Successfully");
        //   setShow(false);
        // }
      } catch (error) {
        // toast.error("Error");
        // console.error("error", error);
      }
    },
  });

  useEffect(() => {
    // getCurrency();
    get();
    // getRoles();
  }, []);

  const get = async () => {
    await AuthGet("nymcard/balancelimits", "admin")
      .then((res) => {
        if (res?.statusCode === 200) console.log("rest", res);
        setData2(res?.data);
        // setcountrycodes()
        console.log("res", res);
      })
      .catch((err) => {
        console.log("hgdfhfdh");
      });
  };

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      classes: "ref_link",
      events: {
        // onClick: (e, column, columnIndex, row, rowIndex) => {
        //   navigate(`/admin/merchant/${row.id}`);
        // },
      },
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "type",
      text: "type",
      sort: true,
      headerStyle: {
        width: "250px",
      },
    },
    // {
    //     dataField: "", text: 'Edit', sort: true,
    //     formatter: () => {
    //         return <button className="btn deleteIcon">
    //             <img src={updateIcon} />
    //         </button>
    //     },
    //     events: {
    //         onClick: (e, column, columnIndex, row, rowIndex) => {
    //             console.log("MANAGE_DATA", row)
    //             // getEditPractice(row?.id);
    //         },
    //     },
    //     headerStyle: {
    //         width: '100px',
    //     }
    // },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
    {
      dataField: "descrption",
      order: "desc",
    },
    {
      dataField: "status",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      <div className="whiteFrame">
        <div className="whiteFrameHeader displayFlex justifyConentEnd mb_12">
          <div>
            <button
              onClick={() => setShow(true)}
              className="mainPrimary_btn greenishTeal_Clr"
            >
              Add Balance Limit
            </button>
          </div>
        </div>
        <div className="TableWrapper">
          <table>
            {/* <thead>
              <tr>
                <th>#</th>
                <th>User ref</th> 
                <th>id</th>
                <th>Descrption</th>
                <th>Type</th>
                <th>Role</th>
              </tr>
            </thead> */}
            {/* <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td onClick={() => navigate(`/admin/users/${item.id}`)}>
                        USR_{item.ref_no}
                      </td>
                      <td  className="ref_link"  onClick={() => navigate(`/admin/users/${item.id}`)}>{item.email}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.role_name}</td>
                    </tr>
                  );
                })}
            </tbody> */}
          </table>
        </div>
      </div>
      {/* Admin Add modal */}
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add ProductManagement</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                Description
              </label>

              <input
                type="text"
                placeholder="Enter Description"
                className="adminInput"
                id="Description"
                name="Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Description}
              />
              {formik.touched.Description && formik.errors.Description && (
                <p className="error_text">{formik.errors.Description}</p>
              )}
            </div>
            <div className="mb_24">
              <label htmlFor="type" className="labelText">
                Type
              </label>
               
              <select
                name="type"
                id="type"
                className="adminInput"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type}
              >
                <option value="1">Select Type</option>
                <option value="PER_ACCOUNT">PER ACCOUNT</option>
                <option value="PER_USER">PER USER</option>

                {/* <option </option> */}
              </select>
            </div>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                Minimum amount
              </label>

              <input
                type="number"
                placeholder="Enter Minimum amount"
                className="adminInput"
                id="Minimunamount"
                name="Minimunamount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Minimunamount}
              />
              {formik.touched.Minimunamount && formik.errors.Minimunamount && (
                <p className="error_text">{formik.errors.Minimunamount}</p>
              )}
            </div>
            <div className="mb_24">
              <label htmlFor="Description" className="labelText">
                Maximum amount
              </label>

              <input
                type="number"
                placeholder="Enter Maximum amount"
                className="adminInput"
                id="Maximumamount"
                name="Maximumamount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Maximumamount}
              />
              {formik.touched.Maximumamount && formik.errors.Maximumamount && (
                <p className="error_text">{formik.errors.Maximumamount}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleClose}
              className="greenishTealClr_hover mainPrimary_btn"
            >
              Close
            </button>
            <button
              type="submit"
              className="mainPrimary_btn greenishTeal_Clr"
              disabled="disabled"
            >
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data2}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default Balancelimit;
