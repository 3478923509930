export const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const SSNRegex = /^(?:[0-9]{3})(?:[0-9]{2})(?:[0-9]{4})$/;
export const onlyNumbers = (data) => data.replace(/[^0-9]/g, '')
export const expiry = (data) =>
  data
    .replace(/[^0-9]/g, '')
    .replace(/^([2-9])$/g, '0$1')
    .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
    .replace(/^0{1,}/g, '0')
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2');
export const csc = (data) => data.replace(/[^0-9]/g, '').substring(0, 3)
export const handleLength = (data, length) => {
  if (data.length > length) {
    return data.substring(0, length);
  }
  return data
}
export const cardNumber = (data) => data?.replace(/[^\dA-Z]/g, '')?.replace(/(.{4})/g, '$1 ')?.trim().substring(0, 19)
export const accountNumber = (data) => data?.replace(/[^0-9]/g, '')?.substring(0, 16)
export const routingNumber = (data) => data?.replace(/[^0-9]/g, '')?.substring(0, 9)
export const name = (data) => {
  data.replace(/[^A-Za-z.]/g, '')
  data = data.charAt(0).toUpperCase() + data.substr(1).toLowerCase()
  return handleLength(data, 50)
}
export const mobile = (data) => {
  data = data.replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  return handleLength(data, 14)
}
export const zipcode = (data) => {
  data = onlyNumbers(data)
  return handleLength(data, 6)
}
