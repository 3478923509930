import React, { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Text from "../../Components/Text/Text";
import Accordion from "react-bootstrap/Accordion";
import { useStateValue } from "../../Context/StateProvider";
import DateField from "../../Components/Date/DateField";
import SSN from "../../Components/SSN/SSN";
import States from "../../Components/UsStates/States";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Email from "../../Components/Email/Email";
import Phone from "../../Components/Phone/Phone";
import { Get, Post } from "../../common_var/httpService";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";

const FinancialDetails = ({ setStep }) => {
  const { loanid } = useParams();
  const [coSigner, setCoSigner] = useState(false);
  const [{ formData, errors, cosignerCheckbox }, dispatch] = useStateValue();
  let payroll_date = [];
  for (let i = 1; i <= 31; i++) {
    payroll_date.push({ key: i, value: i });
  }
  const emp_status_data = [
    { key: "employed", value: "Employed" },
    { key: "self-employed", value: "Self-employed" },
    { key: "military", value: "Military" },
    // { key: "retired", value: "Retired" },
    { key: "currently unemployed", value: "Currently Unemployed" },
  ];
  const emp_type_data = [
    { key: "full time", value: "Full time" },
    { key: "part time", value: "Part time" },
  ];
  const payroll_freq = [
    { key: "B", value: "Bi-weekly" },
    { key: "W", value: "Weekly" },
    { key: "S", value: "Semi-weekly" },
    { key: "M", value: "Monthly" },
  ];
  const handleChange = (e) => {
    const name = e.target.name;
    let val = e.target.value;

    if (name === "zipcode" || name === "coSignor_zipcode") {
      val = val.replace(/[^\d]/g, "");
    }

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };
  const handleCoSigner = (e) => {
    if (e.target.checked) {
      setCoSigner(true);
      dispatch({
        type: "SET_COSIGNER",
        payload: true,
      });
    } else {
      setCoSigner(false);
      dispatch({
        type: "SET_COSIGNER",
        payload: false,
      });
    }
  };

  const calcAnnualIncome = () => {
    if (formData.monthly_income) {
      dispatch({
        type: "SET_VALUES",
        payload: { annual_income: formData.monthly_income * 12 },
      });
    }
    if (formData.coSignor_monthly_income) {
      dispatch({
        type: "SET_VALUES",
        payload: {
          coSignor_annual_income: formData.coSignor_monthly_income * 12,
        },
      });
    }
  };

  useEffect(() => {
    calcAnnualIncome();
  }, [
    formData.monthly_income,
    formData.annual_income,
    formData.coSignor_monthly_income,
  ]);

  const getDetails = async () => {
    try {
      let res = await Get(`loan/getfinancedetails/${loanid}`, "admin");
      let resp = await Get(`loan/getcosignorinfo/${loanid}`, 'admin')
      if (res.statusCode === 200) {
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
      }
      if(resp.statusCode === 200) {
        dispatch({
          type: "SET_VALUES",
          payload: resp.data[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    getDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    const f = formData;
    try {
      const borrowerBodyData = {
        additional_income: +f.additional_income,
        annual_income: +f.annual_income,
        monthly_income: +f.monthly_income,
        monthly_mortgage: +f.monthly_mortgage,
        isCosignor: cosignerCheckbox,
        loan_id: loanid,
      };
      const coSignorBody = {
        loan_id: loanid,
        coSignor_email: f.coSignor_email,
        coSignor_firstname: f.coSignor_firstname,
        coSignor_middle_initial: f.coSignor_middle_initial,
        coSignor_lastname: f.coSignor_lastname,
        coSignor_date_of_birth: f.coSignor_date_of_birth,
        coSignor_unit: f.coSignor_unit,
        coSignor_phone: f.coSignor_phone,
        coSignor_street_address: f.coSignor_street_address,
        coSignor_city: f.coSignor_city,
        coSignor_state: f.coSignor_state,
        coSignor_zipcode: f.coSignor_zipcode,
        coSignor_usresidency_status: f.coSignor_usresidency_status,
        coSignor_ssn: f.coSignor_ssn,
        coSignor_marital_status: f.coSignor_marital_status,
        coSignor_spoken_language: f.coSignor_spoken_language,
        coSignor_monthly_income: +f.coSignor_monthly_income,
        coSignor_monthly_mortgage: +f.coSignor_monthly_mortgage,
        coSignor_additional_income: +f.coSignor_additional_income,
        cosignor_employment: [
          {
            coSignor_employment_status: f.coSignor_employment_status,
            coSignor_phone: f.coSignor_phone,
            coSignor_employment_type: f.coSignor_employment_type,
            coSignor_duration: f.coSignor_duration,
            coSignor_employer_address: f.coSignor_employer_address,
            coSignor_date_of_hire: f.coSignor_date_of_hire,
            coSignor_employer_name: f.coSignor_employer_name,
            coSignor_jobtitle: f.coSignor_jobtitle,
            coSignor_payroll_frequency: f.coSignor_payroll_frequency,
            coSignor_payroll_date: f.coSignor_payroll_date,
          },
        ],
      };
      if (Object.keys(errors).length === 0) {
        const resp = await Post("loan/finance", borrowerBodyData, "admin");
        if (cosignerCheckbox) {
          const res = await Post(
            "loan/cosignor/personalinfo",
            coSignorBody,
            "admin"
          );
        }
        if (resp.statusCode === 200) {
          toast.success(resp.message);
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          // setTimeout(() => {
          dispatch({
            type: "SET_STEP",
            payload: 4,
          });
          // }, 500);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const today = new Date();
  let maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  let maxHireDate = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    today.getDate()
  );
  let minHireDate = new Date(
    today.getFullYear() - 50,
    today.getMonth(),
    today.getDate()
  );
  let minDate = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );

  return (
    <>
      <Toast />
      <form onSubmit={handleSubmit} className="row">
        <Text
          maxLen={7}
          labelName={"Monthly Gross Income $ (Before-Tax)"}
          name="monthly_income"
          placeholder={"Monthly gross income"}
          handleChange={handleChange}
          required={true}
        />
        <Text
          labelName={"Annual Income $ (Automatically calculated)"}
          name="annual_income"
          placeholder={"Annual Income"}
          handleChange={handleChange}
        />
        <Text
          maxLen={7}
          labelName={"Additional Income $ (if any)"}
          name="additional_income"
          placeholder={"Additional Income"}
          handleChange={handleChange}
        />
        <Text
          maxLen={7}
          labelName={"Monthly Mortgage Payment $"}
          name="monthly_mortgage"
          placeholder={"Monthly Mortgage"}
          handleChange={handleChange}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: cosignerCheckbox ? "flex-end" : "space-between",
            marginBottom: cosignerCheckbox ? "10px" : "",
          }}
        >
          {cosignerCheckbox ? (
            <></>
          ) : (
            <Button
              type={"submit"}
              value={"Continue"}
              handleClick={handleSubmit}
            />
          )}
          <Checkbox
            // checked={formData?.isCosignor}
            name={"check"}
            labelName={"Co-borrower"}
            handleChange={handleCoSigner}
          />
        </div>
        {coSigner && (
          <Accordion defaultActiveKey="0" className="mb_24">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Personal </Accordion.Header>
              <Accordion.Body className="row">
                <Text
                  name={"coSignor_firstname"}
                  labelName={"First Name"}
                  placeholder={"First Name"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name={"coSignor_middle_initial"}
                  labelName={"Middle Initials"}
                  placeholder={"Middle Name"}
                  handleChange={handleChange}
                />
                <Text
                  name={"coSignor_lastname"}
                  labelName={"Last Name"}
                  placeholder={"Last Name"}
                  handleChange={handleChange}
                  required={true}
                />
                <Email
                  name={"coSignor_email"}
                  labelName={"Email"}
                  placeholder={"Email"}
                  handleChange={handleChange}
                />
                <DateField
                  minYear={minDate.getFullYear()}
                  minDate={minDate}
                  maxDate={maxDate}
                  name={"coSignor_date_of_birth"}
                  labelName={"Date of Birth"}
                  placeholder="Date of Birth (MM-DD-YYYY)"
                  handleChange={handleChange}
                  required={true}
                />
                <SSN
                  name={"coSignor_ssn"}
                  placeholder={"Social Security Number"}
                  labelName={"Social Security Number"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name={"coSignor_street_address"}
                  labelName={"Street Address"}
                  placeholder={"Example: 123 Main Street"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name={"coSignor_unit"}
                  labelName={"Unit"}
                  placeholder={"Example: 10B"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name={"coSignor_city"}
                  labelName={"City"}
                  placeholder={"Example: Alaska"}
                  handleChange={handleChange}
                  required={true}
                />
                <States
                  name={"coSignor_state"}
                  placeholder={"Select State"}
                  labelName={"State"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  maxLen={6}
                  name={"coSignor_zipcode"}
                  labelName={"Zipcode"}
                  placeholder={"Example: 12345"}
                  handleChange={handleChange}
                  required={true}
                />
                <Dropdown
                  data={[
                    { key: "married", value: "Married" },
                    { key: "unmarried", value: "Unmarried" },
                  ]}
                  name={"coSignor_marital_status"}
                  labelName={"Marital Status"}
                  handleChange={handleChange}
                  required={true}
                />
                <Dropdown
                  data={[
                    { key: "resident", value: "Resident" },
                    { key: "non-resident", value: "Non-Resident" },
                    { key: "citizen", value: "Citizen" },
                  ]}
                  name={"coSignor_usresidency_status"}
                  labelName={"US Residency Status"}
                  handleChange={handleChange}
                  required={true}
                />
                <Dropdown
                  data={[
                    { key: "english", value: "English" },
                    { key: "spanish", value: "Spanish" },
                  ]}
                  name={"coSignor_spoken_language"}
                  labelName={"Spoken Language"}
                  handleChange={handleChange}
                  required={true}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Work</Accordion.Header>
              <Accordion.Body className="row">
                <Dropdown
                  data={emp_status_data}
                  name={"coSignor_employment_status"}
                  labelName={"Employment Status"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name="coSignor_employer_name"
                  labelName={"Employer Name"}
                  placeholder="Employer Name"
                  handleChange={handleChange}
                  required={true}
                />
                <Dropdown
                  data={emp_type_data}
                  name={"coSignor_employment_type"}
                  labelName={"Employment Type"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name="coSignor_jobtitle"
                  labelName={"Job Title/Designation"}
                  placeholder="Job title"
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  name="coSignor_employer_address"
                  labelName={"Employer Address"}
                  placeholder="Employer Address"
                  handleChange={handleChange}
                  required={true}
                />
                <Phone
                  name={"coSignor_phone"}
                  labelName="Employer contact number"
                  placeholder={"Example: (000) 000-0000"}
                  handleChange={handleChange}
                  required={true}
                />
                <DateField
                  minDate={minHireDate}
                  maxDate={maxHireDate}
                  name={"coSignor_date_of_hire"}
                  labelName="Hiring Date"
                  placeholder={"Hiring Date"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  maxLen={3}
                  name={"coSignor_duration"}
                  labelName="Duration with Employer (in months)"
                  placeholder={"Duration with Employer (in months)"}
                  handleChange={handleChange}
                  required={true}
                />
                {/* <Dropdown
                  data={payroll_freq}
                  name="coSignor_payroll_frequency"
                  labelName={"Payroll Frequency"}
                  handleChange={handleChange}
                /> */}
                <Dropdown
                  data={payroll_date}
                  name="coSignor_payroll_date"
                  labelName={"Payroll Date"}
                  handleChange={handleChange}
                  required={true}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Financials</Accordion.Header>
              <Accordion.Body className="row">
                <Text
                  labelName={"Monthly gross income $ (Before-tax)"}
                  name="coSignor_monthly_income"
                  placeholder={"Monthly gross income"}
                  handleChange={handleChange}
                  required={true}
                />
                <Text
                  labelName={"Annual Income $ (Automatically calculated)"}
                  name="coSignor_annual_income"
                  placeholder={"Annual Income $"}
                  handleChange={handleChange}
                />
                <Text
                  labelName={"Additional Income $"}
                  name="coSignor_additional_income"
                  placeholder={"Additional Income $"}
                  handleChange={handleChange}
                />
                <Text
                  labelName={"Monthly Mortgage Payment $"}
                  name="coSignor_monthly_mortgage"
                  placeholder={"Monthly Mortgage Payment $"}
                  handleChange={handleChange}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {cosignerCheckbox && (
          <Button
            type={"submit"}
            value={"Continue"}
            handleClick={handleSubmit}
          />
        )}
      </form>
    </>
  );
};

export default FinancialDetails;
