import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import { Get, Post } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";

const Offers = () => {
  const [{ offers, formData }, dispatch] = useStateValue();
  const { loanid } = useParams();

  const getSelectedOffer = async () => {
    try {
      let resp = await Get(`loan/getselectedoffer/${loanid}`, "admin");
      dispatch({
        type: "SET_VALUES",
        payload: { offer_id: resp?.data?.id },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSelectedOffer();
    if (offers.length === 0) {
      console.log("ERR");
      getSystemGenerateOffers();
    }
  }, []);

  const getSystemGenerateOffers = async () => {
    let res = await Get(`loan/generateOffer/${loanid}`, "admin");
    console.log(res);
    if (res["statusCode"] == 200) {
      let data = res["data"];
      if (data.length > 0) {
        dispatch({
          type: "SET_OFFERS",
          payload: data,
        });
      }
    } else {
      console.log("Invalid");
    }
  };

  const handleSelect = async (offer_id, loanAmount) => {
    console.log(offer_id);
    let data = {
      offer_id: offer_id,
      loanamount: loanAmount,
    };
    dispatch({
      type: "SET_VALUES",
      payload: data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await Post(
        `loan/saveEligibleOffer/${loanid}`,
        formData,
        "admin"
      );
      if (res.statusCode === 200) {
        toast.success(res.data);
        // setTimeout(() => {
        dispatch({
          type: "SET_STEP",
          payload: 7,
        });
        // }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
  };

  return (
    <>
      <Toast />
      <div>
        <div className="offerheader">
          {/* <p>Offers</p> */}
          <p>Loan Amount ${offers[0]?.financialamount}</p>

        </div>



        {offers.map((ele, index) => (
          <div
            key={index}
            onClick={() => handleSelect(ele.id, ele.financialamount)}
          >
            <label htmlFor={ele.id} className='offerSection'>
              <input
                type="radio"
                name="offers"
                id={ele.id}
                checked={formData["offer_id"] === ele.id ? true : false}
              />
              <p name="offers">Tenor {ele.duration}</p>
              <p name="offers">APR %{ele.interestrate}</p>
              <p name="offers">Monthly Payment ${ele.monthlyamount}</p>
            </label>
          </div>
        ))}

        <Button type="submit" value="Continue" handleClick={handleSubmit} />
      </div>
    </>
  );
};

export default Offers;
