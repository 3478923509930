import React, { useEffect, useState } from "react";
import breadcrumb_dash from "../../assets/new/breadcrumb_dash.svg";
import file_open_active from "../../assets/new/file_open_active.svg";
import search from "../../assets/new/search.svg";
import plus from "../../assets/new/plus.svg";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import updateIcon from "../../assets/images/updateIcon.svg";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EmailRegex, onlyNumbers } from "../../common_var/Regex";

function Merchant() {
  const navigate = useNavigate();
  // const [showedit, setShowEdit] = useState(false);

  const [editData, setEditData] = useState({
    contactname: "",
    email: "",
    merchantname: "",
    url: "",
    merchantvatid: "",
    locationname: "",
    streetaddress: "",
    country: "",
    city: "",
    stateCode: "",
    zipcode: "",
    phoneNumber: "",
    merchantpoweredbylogo: "",
  });
  const [loanData, setLoanData] = useState([]);
  // const [method, setMethod] = useState("");
  const [editID, setEditID] = useState("");
  const [page, setPage] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const numberonly = "^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$";
  const URL =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const onlyalphabets =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g;
  const [currentPage, setCurrentPage] = useState(1);
  const [form, setForm] = useState({
    contactname: "",
    email: "",
    merchantname: "",
    url: "",
    country: "",
    merchantvatid: "",
    locationname: "",
    streetaddress: "",
    city: "",
    stateCode: "",
    zipcode: "",
    phoneNumber: "",
    merchantpoweredbylogo: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    formik.errors = {};
    formik.resetForm();
  };
  const handleShow = () => setShow(true);
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    formikedit.resetForm();
  };
  const [rules, setRules] = useState([]);

  let stateList = [
    { id: "AL", displayName: "Alabama" },
    { id: "AK", displayName: "Alaska" },
    { id: "AZ", displayName: "Arizona" },
    { id: "CA", displayName: "California" },
    { id: "CO", displayName: "Colorado" },
    { id: "CT", displayName: "Connecticut" },
    { id: "DE", displayName: "Delaware" },
    { id: "GA", displayName: "Georgia" },
    { id: "HI", displayName: "Hawaii" },
    { id: "ID", displayName: "Idaho" },
    { id: "IL", displayName: "Illinois" },
    { id: "IN", displayName: "Indiana" },
    { id: "IA", displayName: "Iowa" },
    { id: "KS", displayName: "Kansas" },
    { id: "KY", displayName: "Kentucky" },
    { id: "LA", displayName: "Louisiana" },
    { id: "ME", displayName: "Maine" },
    { id: "MA", displayName: "Massachusetts" },
    { id: "MI", displayName: "Michigan" },
    { id: "MS", displayName: "Mississippi" },
    { id: "MO", displayName: "Missouri" },
    { id: "MN", displayName: "Minnesota" },
    { id: "MT", displayName: "Montana" },
    { id: "NE", displayName: "Nebraska" },
    { id: "NV", displayName: "Nevada" },
    { id: "NH", displayName: "New Hampshire" },
    { id: "NJ", displayName: "New Jersey" },
    { id: "NM", displayName: "New Mexico" },
    { id: "NY", displayName: "New York" },
    { id: "NC", displayName: "North Carolina" },
    { id: "ND", displayName: "North Dakota" },
    { id: "OH", displayName: "Ohio" },
    { id: "OK", displayName: "Oklahoma" },
    { id: "OR", displayName: "Oregon" },
    { id: "PA", displayName: "Pennsylvania" },
    { id: "SC", displayName: "South Carolina" },
    { id: "TN", displayName: "Tennessee" },
    { id: "TX", displayName: "Texas" },
    { id: "UT", displayName: "Utah" },
    { id: "VA", displayName: "Virginia" },
    { id: "WA", displayName: "Washington" },
    { id: "WV", displayName: "West Virginia" },
    { id: "WI", displayName: "Wisconsin" },
    { id: "WY", displayName: "Wyoming" },
  ];

  useEffect(() => {
    getPracticeList();
    getPracticerules();
  }, []);

  const formik = useFormik({
    initialValues: {
      contactname: "",
      email: "",
      merchantname: "",
      url: "",
      locationname: "",
      merchantvatid: "",
      country: "",
      streetaddress: "",
      city: "",
      stateCode: "",
      zipcode: "",
      phoneNumber: "",
      merchantpoweredbylogo: "",
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      contactname: Yup.string().required("* contactname is requird"),
      merchantname: Yup.string()
        .required("* Merchantname is required")
        .matches(onlyalphabets, "Number Not accepted"),
      email: Yup.string()
        .required("*  Email is required")
        .email("Invalid Email Address"),
      url: Yup.string()
        .required("*Url  is required")
        .matches(URL, "Enter a valid url"),
      phoneNumber: Yup.string()
        .required("* PhoneNumber is required")
        .min(10, "Phone Number  Min 10 Number "),
      streetaddress: Yup.string().required("*Street address is required"),
      city: Yup.string()
        .required("* City is required")
        .matches(onlyalphabets, "city name  invalid  "),
      stateCode: Yup.string().required("* StateCode is required"),
      zipcode: Yup.string()
        .required("* Zipcode is required")
        .min(6, "Zip code Min 6 Number "),
      merchantvatid: Yup.string().required("* Merchantvatid  is required"),

      country: Yup.string().required("*Country is required"),
    }),

    //     .
    onSubmit: async (values) => {
      let sendData = {
        contactname: values.contactname,
        email: values.email,
        merchantname: values.merchantname,
        url: values.url,
        locationname: values.locationname,
        streetaddress: values.streetaddress,
        city: values.city,
        stateCode: values.stateCode,
        zipcode: values.zipcode,
        phoneNumber: values.phoneNumber,
        merchantvatid: values.merchantvatid,
        country: values.country,
        merchantpoweredbylogo: "",
      };
      await AuthPost(`practice/create`, sendData, "admin").then((res) => {
        if (res?.statusCode === 200) {
          toast.success("Merchant Added");
          handleCloseEdit();
          handleClose();
          getPracticeList();
          // resetForm();
        } else if (res?.statusCode === 500) {
          toast.error("Merchant Already Exist");
        } else {
          toast.error(res.data?.message[0]);
        }
      });
    },
  });
  console.log(formik.errors);
  const formikedit = useFormik({
    initialValues: {
      contactname: editData?.contactname,
      email: editData?.email,
      merchantname: editData?.merchantname,
      url: editData?.url,
      merchantvatid: editData?.merchantvatid,
      country: editData?.country,
      streetaddress: editData?.streetaddress,
      city: editData?.city,
      stateCode: editData?.stateCode,
      zipcode: editData?.zipcode,
      phoneNumber: editData?.phoneNumber,
      merchantpoweredbylogo: editData?.merchantpoweredbylogo,
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      contactname: Yup.string()
        .required("* is required")
        .matches(onlyalphabets, "Name can only contain  alphabets."),
      merchantname: Yup.string()
        .required("* is required")
        .matches(onlyalphabets, "provider name can only contain  alphabets."),
      email: Yup.string()
        .required("* is required")
        .email("Invalid Email Address"),
      url: Yup.string()
        .required("* is required")
        .matches(URL, "Enter a valid url"),
      phoneNumber: Yup.string()
        .required("* is required")
        .min(10, "phone number Min 10 ")
        .max(10, "phone number Max 10 ")
        .matches(numberonly, "Enter a valid phone number"),
      streetaddress: Yup.string().required("* is required"),
      city: Yup.string()
        .required("* is required")
        .matches(onlyalphabets, "City can only contain alphabets."),
      stateCode: Yup.string().required("* is required"),
      zipcode: Yup.string().required("* is required"),
      merchantvatid: Yup.string()
        .required("* is Required")
        .matches(/[A-Z][0-9]{1,20}/, "Enter a valid Id")
        .min(20, "Invalid Merchatvat Id")
        .max(20, "Invalid Merchatvat Id"),
      // .min(12,"merchantvadid min 12 "),
    }),

    onSubmit: async (values) => {
      let sendData = {
        contactname: values?.contactname,
        email: values?.email,
        merchantname: values?.merchantname,
        url: values?.url,
        country: values?.country,
        merchantvatid: values?.merchantvatid,
        locationname: "",
        streetaddress: values?.streetaddress,
        city: values?.city,
        stateCode: values?.stateCode,
        zipcode: values?.zipcode,
        phoneNumber: values?.phoneNumber,
        merchantpoweredbylogo: "",
      };
      await AuthPost(`practice/edit/${editID}`, sendData, "admin").then(
        (res) => {
          if (res?.statusCode === 200) {
            handleCloseEdit();
            handleClose();
            getPracticeList();
          } else {
            toast.error(res.data?.message[0]);
          }
        }
      );
    },
  });
  console.log(formikedit.errors);

  const getPracticeList = async () => {
    await AuthGet(`practice`, "admin").then((res) => {
      if (res?.statusCode === 200) {
        console.log("rr", res);
        setLoanData(res?.allMerchant);
        setPage(Math.ceil(loanData.length / 10));
        setCurrentPage(1);
      }
    });
  };

  const getPracticerules = async () => {
    await AuthGet(`practicerules/getAllPracticeRules`, "admin").then((res) => {
      if (res?.statusCode === 200) {
        setRules(res?.data);
      }
    });
  };

  const getEditPractice = async (id) => {
    await AuthGet(`practice/${id}`, "admin").then((res) => {
      if (res?.statusCode === 200) {
        console.log("DATA___MANAGE", res?.practice);
        let data = res?.practice;
        setEditID(data?.id);
        setEditData({
          contactname: data?.contactname,
          email: data?.email,
          merchantname: data?.merchantname,
          url: data?.url,
          merchantvatid: data?.merchantvatid,
          country: data?.country,
          locationname: data?.locationname,
          streetaddress: data?.streetaddress,
          city: data?.city,
          stateCode: data?.statecode,
          zipcode: data?.zipcode,
          phoneNumber: data?.phonenumber,
          merchantpoweredbylogo: data?.merchantpoweredbylogo,
          id: data?.id,
        });
        setShowEdit(true);
      }
    });
  };

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let prevPage = currentPage - 1;

        let slNo =
          prevPage === 0
            ? prevPage + columnIndex + 1
            : prevPage + pagesize + columnIndex;

        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
    },
    {
      dataField: "merchantname",
      text: "Merchant Name",
      sort: true,
      classes: "ref_link",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/admin/merchant/${row.id}`);
        },
      },
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "contactname",
      text: "Business Contact",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "250px",
      },
    },
    {
      dataField: "",
      text: "Edit",
      sort: true,
      formatter: () => {
        return (
          <button className="btn deleteIcon">
            <img src={updateIcon} />
          </button>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("MANAGE_DATA", row);
          getEditPractice(row?.id);
        },
      },
      headerStyle: {
        width: "100px",
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "merchantname",
      order: "desc",
    },
    {
      dataField: "contactname",
      order: "desc",
    },
    {
      dataField: "email",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setPageSize(page);
      setCurrentPage(1);
    },
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <div>
      <Toast />

      <div className="breadcrumbsection">
        <div>
          <div className="titleText "> Merchant Management</div>
        </div>
        <button className="btn btn-primary" onClick={() => handleShow()}>
          Add Merchant
        </button>
      </div>

      <Modal show={show} onHide={handleClose} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Add New Merchant</h4>
          </div>
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
              <section
                id="createApplicationFromBackend"
                className="start_application"
              >
                {/* <form onSubmit={formik.handleSubmit}> */}
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Business Contact"
                        formControlName="contactname"
                        id="contactname"
                        name="contactname"
                        class={`adminInput ${
                          formik.errors.contactname &&
                          formik.touched.contactname
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.contactname}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.contactname &&
                        formik.touched.contactname && (
                          <span className="errText">
                            {formik.errors.contactname}
                          </span>
                        )}
                    </div>
                  </div>

                  <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Email"
                        formControlName="email"
                        id="email"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.email && formik.touched.email
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        name="email"
                        //   onChange={handleInputChange}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <span className="errText">{formik.errors.email}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Merchant Name"
                        formControlName="merchantname"
                        id="merchantname"
                        class={`adminInput ${
                          formik.errors.merchantname &&
                          formik.touched.merchantname
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.merchantname.replace(
                          /[^A-Za-z]/gi,
                          ""
                        )}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.merchantname &&
                        formik.touched.merchantname && (
                          <span className="errText">
                            {formik.errors.merchantname}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Merchant Url"
                        formControlName="url"
                        id="url"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.url && formik.touched.url
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.url.replace(/[^a-zA-Z]/g, "")}
                        onBlur={formik.handleBlur}
                        name="url"
                        // onChange={handleInputChange}
                      />
                      {formik.errors.url && formik.touched.url && (
                        <span className="errText">{formik.errors.url}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        type="text"
                        formControlName="mobileNumber"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.phoneNumber &&
                          formik.touched.phoneNumber
                            ? "redBorder"
                            : ""
                        }`}
                        placeholder="Phone Number"
                        mask="+0 (000) 000-0000"
                        name="phoneNumber"
                        // maxLength={10}
                        maxlength="12"
                        // onChange={handleInputChange}
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber.replace(/[^\d]/g, "")}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.phoneNumber &&
                        formik.touched.phoneNumber && (
                          <span className="errText">
                            {formik.errors.phoneNumber}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Street Address"
                        formControlName="streetaddress"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.streetaddress &&
                          formik.touched.streetaddress
                            ? "redBorder"
                            : ""
                        }`}
                        name="streetaddress"
                        onChange={formik.handleChange}
                        value={formik.values.streetaddress}
                        onBlur={formik.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formik.errors.streetaddress &&
                        formik.touched.streetaddress && (
                          <span className="errText">
                            {formik.errors.streetaddress}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        type="text"
                        formControlName="merchantvatid"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.merchantvatid &&
                          formik.touched.merchantvatid
                            ? "redBorder"
                            : ""
                        }`}
                        placeholder="Merchant Vat ID"
                        name="merchantvatid"
                        maxLength={12}
                        onChange={formik.handleChange}
                        // mask="+0 (000) 000-0000"
                        // name="phoneNumber"
                        // maxLength=''
                        minLength={12}
                        value={formik.values.merchantvatid}
                        onBlur={formik.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formik.errors.merchantvatid &&
                        formik.touched.merchantvatid && (
                          <span className="errText">
                            {formik.errors.merchantvatid}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Country"
                        formControlName="country"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.country && formik.touched.country
                            ? "redBorder"
                            : ""
                        }`}
                        name="country"
                        onChange={formik.handleChange}
                        value={formik.values.country.replace(/[^A-Za-z]/gi, "")}
                        onBlur={formik.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formik.errors.country && formik.touched.country && (
                        <span className="errText">{formik.errors.country}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="City"
                        formControlName="city"
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.city && formik.touched.city
                            ? "redBorder"
                            : ""
                        }`}
                        onkeydown="return /[a-z ]/i.test(event.key)"
                        name="city"
                        onChange={formik.handleChange}
                        value={formik.values.city.replace(/[^A-Za-z]/gi, "")}
                        onBlur={formik.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formik.errors.city && formik.touched.city && (
                        <span className="errText">{formik.errors.city}</span>
                      )}
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <select
                        // class="adminInput"
                        class={`adminInput ${
                          formik.errors.stateCode && formik.touched.stateCode
                            ? "redBorder"
                            : ""
                        }`}
                        formControlName="stateCode"
                        name="stateCode"
                        // onChange={handleInputChange}
                        onChange={formik.handleChange}
                        value={formik.values.stateCode}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled selected>
                          State
                        </option>
                        {stateList?.map((data, i) => {
                          const { id, displayName } = data;
                          return (
                            <option value={id} key={i}>
                              {displayName}
                            </option>
                          );
                        })}
                      </select>
                      {formik.errors.stateCode && formik.touched.stateCode && (
                        <span className="errText">
                          {formik.errors.stateCode}
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Zipcode"
                        // class="adminInput zipbox"
                        class={`adminInput ${
                          formik.errors.zipcode && formik.touched.zipcode
                            ? "redBorder"
                            : ""
                        }`}
                        formControlName="zipcode"
                        mask="000000"
                        maxlength="6"
                        name="zipcode"
                        onChange={formik.handleChange}
                        value={formik.values.zipcode.replace(/[^\d]/g, "")}
                        onBlur={formik.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formik.errors.zipcode && formik.touched.zipcode && (
                        <span className="errText">{formik.errors.zipcode}</span>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <form />
          </div>
        </form>
        <Modal.Footer>
          <div>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Update Merchant</h4>
          </div>
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
              <section
                id="createApplicationFromBackend"
                className="start_application"
              >
                {/* <form onSubmit={formik.handleSubmit}> */}
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Business Contact"
                        formControlName="contactname"
                        id="contactname"
                        name="contactname"
                        class={`adminInput ${
                          formikedit.errors.contactname &&
                          formikedit.touched.contactname
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formikedit.handleChange}
                        value={formikedit.values.contactname}
                        // value={formikEditUser.values.wallet_creditlimit}

                        onBlur={formikedit.handleBlur}
                      />
                      {formikedit.errors.contactname &&
                        formikedit.touched.contactname && (
                          <span className="errText">
                            {formikedit.errors.contactname}
                          </span>
                        )}
                    </div>
                  </div>

                  <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Email"
                        formControlName="email"
                        id="email"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.email && formikedit.touched.email
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formikedit.handleChange}
                        value={formikedit.values.email}
                        onBlur={formikedit.handleBlur}
                        name="email"
                        //   onChange={handleInputChange}
                      />
                      {formikedit.errors.email && formikedit.touched.email && (
                        <span className="errText">
                          {formikedit.errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Merchant Name"
                        formControlName="merchantname"
                        id="merchantname"
                        class={`adminInput ${
                          formikedit.errors.merchantname &&
                          formikedit.touched.merchantname
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formikedit.handleChange}
                        value={formikedit.values.merchantname}
                        onBlur={formikedit.handleBlur}
                      />
                      {formikedit.errors.merchantname &&
                        formikedit.touched.merchantname && (
                          <span className="errText">
                            {formikedit.errors.merchantname}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Merchant Url"
                        formControlName="url"
                        id="url"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.url && formikedit.touched.url
                            ? "redBorder"
                            : ""
                        }`}
                        onChange={formikedit.handleChange}
                        value={formikedit.values.url}
                        onBlur={formikedit.handleBlur}
                        name="url"
                        // onChange={handleInputChange}
                      />
                      {formikedit.errors.url && formikedit.touched.url && (
                        <span className="errText">{formikedit.errors.url}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        type="text"
                        formControlName="mobileNumber"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.mobileNumber &&
                          formikedit.touched.mobileNumber
                            ? "redBorder"
                            : ""
                        }`}
                        placeholder="Phone Number"
                        mask="+0 (000) 000-0000"
                        name="phoneNumber"
                        // onChange={handleInputChange}
                        onChange={formikedit.handleChange}
                        value={formikedit.values.phoneNumber}
                        onBlur={formikedit.handleBlur}
                      />
                      {formikedit.errors.phoneNumber &&
                        formikedit.touched.phoneNumber && (
                          <span className="errText">
                            {formikedit.errors.phoneNumber}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="StreetAddress"
                        formControlName="streetaddress"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.streetaddress &&
                          formikedit.touched.streetaddress
                            ? "redBorder"
                            : ""
                        }`}
                        name="streetaddress"
                        onChange={formikedit.handleChange}
                        value={formikedit.values.streetaddress}
                        onBlur={formikedit.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikedit.errors.streetaddress &&
                        formikedit.touched.streetaddress && (
                          <span className="errText">
                            {formikedit.errors.streetaddress}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        type="text"
                        formControlName="merchantvatid"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.streetaddress &&
                          formikedit.touched.streetaddress
                            ? "redBorder"
                            : ""
                        }`}
                        placeholder="Merchant Vat ID"
                        name="merchantvatid"
                        onChange={formikedit.handleChange}
                        value={formikedit.values.merchantvatid}
                        onBlur={formikedit.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikedit.errors.merchantvatid &&
                        formikedit.touched.merchantvatid && (
                          <span className="errText">
                            {formikedit.errors.merchantvatid}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Country"
                        formControlName="country"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.streetaddress &&
                          formikedit.touched.streetaddress
                            ? "redBorder"
                            : ""
                        }`}
                        name="country"
                        onChange={formikedit.handleChange}
                        value={formikedit.values.country}
                        onBlur={formikedit.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikedit.errors.country &&
                        formikedit.touched.country && (
                          <span className="errText">
                            {formikedit.errors.country}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="City"
                        formControlName="city"
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.streetaddress &&
                          formikedit.touched.streetaddress
                            ? "redBorder"
                            : ""
                        }`}
                        onkeydown="return /[a-z ]/i.test(event.key)"
                        name="city"
                        onChange={formikedit.handleChange}
                        value={formikedit.values.city}
                        onBlur={formikedit.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikedit.errors.city && formikedit.touched.city && (
                        <span className="errText">
                          {formikedit.errors.city}
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <select
                        // class="adminInput"
                        class={`adminInput ${
                          formikedit.errors.streetaddress &&
                          formikedit.touched.streetaddress
                            ? "redBorder"
                            : ""
                        }`}
                        formControlName="stateCode"
                        name="stateCode"
                        // onChange={handleInputChange}
                        onChange={formikedit.handleChange}
                        value={formikedit.values.stateCode}
                        onBlur={formikedit.handleBlur}
                      >
                        <option value="" disabled selected>
                          State
                        </option>
                        {stateList?.map((data, i) => {
                          const { id, displayName } = data;
                          return (
                            <option value={id} key={i}>
                              {displayName}
                            </option>
                          );
                        })}
                      </select>
                      {formikedit.errors.stateCode &&
                        formikedit.touched.stateCode && (
                          <span className="errText">
                            {formikedit.errors.stateCode}
                          </span>
                        )}
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                      <input
                        placeholder="Zipcode"
                        // class="adminInput zipbox"
                        class={`adminInput ${
                          formikedit.errors.zipcode &&
                          formikedit.touched.zipcode
                            ? "redBorder"
                            : ""
                        }`}
                        formControlName="zipcode"
                        mask="000000"
                        maxlength="6"
                        name="zipcode"
                        onChange={formikedit.handleChange}
                        value={formikedit.values.zipcode}
                        onBlur={formikedit.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikedit.errors.zipcode &&
                        formikedit.touched.zipcode && (
                          <span className="errText">
                            {formikedit.errors.zipcode}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <div>
              <button
                className="btn main_hover_btn"
                type="button"
                typeclassName="btn main_hover_btn"
                onClick={handleCloseEdit}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="btn main_hover_btn"
                type="submit"
                onClick={formikedit.handleSubmit}
              >
                Update
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={loanData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Merchant;

// const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setForm((prevState) => ({
//     ...prevState,
//     [name]: value,
//   }));
// };

// const handleInputEdit = (e) => {
//   const { name, value } = e.target;
//   setEditData((prevState) => ({
//     ...prevState,
//     [name]: value,
//   }));
// };

// const handleSubmit = async (e, method) => {
//   e.preventDefault();
//   console.log("MANAGE_DATA_SUBMIT", form);

//   if (method === "POST") {
//     let sendData = {
//       contactname: form?.contactname,
//       email: form?.email,
//       merchantname: form?.merchantname,
//       url: form?.url,
//       country: form?.country,
//       merchantvatid: form?.merchantvatid,
//       locationname: form?.locationname,
//       streetaddress: form?.streetaddress,
//       city: form?.city,
//       stateCode: form?.stateCode,
//       zipcode: form?.zipcode,
//       phoneNumber: form?.phoneNumber,
//       merchantpoweredbylogo: form?.merchantpoweredbylogo,
//     };
//     await AuthPost(`practice/create`, sendData, "admin").then((res) => {
//       if (res?.statusCode === 200) {
//         toast.success("Merchant Added");
//         handleCloseEdit();
//         handleClose();
//         getPracticeList();
//         setForm({
//           contactname: "",
//           email: "",
//           merchantname: "",
//           url: "",
//           locationname: "",
//           merchantvatid: "",
//           country: "",
//           streetaddress: "",
//           city: "",
//           stateCode: "",
//           zipcode: "",
//           phoneNumber: "",
//           merchantpoweredbylogo: "",
//         });
//       } else if (res?.statusCode === 500) {
//         toast.error("Merchant Already Exist");
//       } else {
//         toast.error(res.data?.message[0]);
//       }
//     });
//   } else if (method === "PUT") {
//     console.log("EDITDATA____MANAGE", editData);
//     let sendData = {
//       contactname: editData?.contactname,
//       email: editData?.email,
//       merchantname: editData?.merchantname,
//       url: editData?.url,
//       country: editData?.country,
//       merchantvatid: editData?.merchantvatid,
//       locationname: editData?.locationname,
//       streetaddress: editData?.streetaddress,
//       city: editData?.city,
//       stateCode: editData?.stateCode,
//       zipcode: editData?.zipcode,
//       phoneNumber: editData?.phoneNumber,
//       merchantpoweredbylogo: editData?.merchantpoweredbylogo,
//     };
//     await AuthPost(`practice/edit/${editData?.id}`, sendData, "admin").then(
//       (res) => {
//         if (res?.statusCode === 200) {
//           console.log("res", res);
//           toast.success("Successfully Updated");
//           handleCloseEdit();
//           handleClose();
//           getPracticeList();
//           setForm({
//             contactname: "",
//             email: "",
//             merchantname: "",
//             url: "",
//             merchantvatid: "",
//             country: "",
//             locationname: "",
//             streetaddress: "",
//             city: "",
//             stateCode: "",
//             zipcode: "",
//             phoneNumber: "",
//             merchantpoweredbylogo: "",
//           });
//         } else {
//           toast.error(res.data?.message[0]);
//         }
//       }
//     );
//   }
// };

{
  /* <section
                id="createApplicationFromBackend"
                className="start_application"
              >
                <form>
                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Business Contact"
                          formControlName="contactname"
                          id="contactname"
                          class="adminInput"
                          name="contactname"
                          onChange={handleInputEdit}
                          defaultValue={editData?.contactname}
                        />
                      </div>
                    </div>

                    <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Email"
                          formControlName="email"
                          class="adminInput"
                          name="email"
                          onChange={handleInputEdit}
                          defaultValue={editData?.email}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Merchant Name"
                          formControlName="merchantname"
                          id="merchantname"
                          class="adminInput"
                          name="merchantname"
                          onChange={handleInputEdit}
                          defaultValue={editData?.merchantname}
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Merchant Url"
                          formControlName="url"
                          id="url"
                          class="adminInput"
                          name="url"
                          onChange={handleInputEdit}
                          defaultValue={editData?.url}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          type="text"
                          name="phoneNumber"
                          formControlName="mobileNumber"
                          class="adminInput"
                          placeholder="Phone Number"
                          mask="+0 (000) 000-0000"
                          onChange={handleInputEdit}
                          defaultValue={editData?.phoneNumber}
                        />
                      </div>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Street"
                          formControlName="streetaddress"
                          class="adminInput"
                          onChange={handleInputEdit}
                          defaultValue={editData?.streetaddress}
                          name="streetaddress"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          type="text"
                          formControlName="merchantvatid"
                          class="adminInput"
                          placeholder="Merchant Vat ID"
                          name="merchantvatid"
                          onChange={handleInputChange}
                          defaultValue={editData?.merchantvatid}
                        />
                      </div>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Country"
                          formControlName="country"
                          class="adminInput"
                          name="country"
                          onChange={handleInputChange}
                          defaultValue={editData?.country}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="City"
                          formControlName="city"
                          class="adminInput"
                          onkeydown="return /[a-z ]/i.test(event.key)"
                          onChange={handleInputEdit}
                          defaultValue={editData?.city}
                          name="city"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <select
                          class="adminInput"
                          formControlName="stateCode"
                          onChange={handleInputEdit}
                          defaultValue={editData?.stateCode}
                          name="stateCode"
                        >
                          <option value="null" disabled selected>
                            State
                          </option>
                          {stateList?.map((data) => {
                            const { id, displayName } = data;
                            return <option value={id}>{displayName}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <input
                          placeholder="Zipcode"
                          class="adminInput zipbox"
                          formControlName="zipcode"
                          mask="00000"
                          maxlength="5"
                          onChange={handleInputEdit}
                          defaultValue={editData?.zipcode}
                          name="zipcode"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </section> */
}

// validate: (values) => {
//   const errors = {};

//   if (!values.contactname) {
//     errors.contactname = "Contact name is required";
//   }
//   if (!values.city) {
//     errors.city = "City name is required";
//   }
//   if (!values.country) {
//     errors.country = "Country is required";
//   }
//   if (!values.url) {
//     errors.url = "url is required";
//   }
//   if (!values.zipcode) {
//     errors.zipcode = "zipcode is required";
//   }
//   if (!values.email) {
//     errors.email = "email is required";
//   }
//   if (!values.streetaddress) {
//     errors.streetaddress = "street address is required";
//   }
//   if (!values.phoneNumber) {
//     errors.phoneNumber = "phoneNumber is required";
//   }
//   if (!values.merchantvatid) {
//     errors.merchantvatid = "merchantVat id  is required";
//   }
//   if (!values.stateCode) {
//     errors.stateCode = "state is required";
//   }
//   if (!values.merchantname) {
//     errors.merchantname = "merchant name is required";
//   }

//   return errors;
// },

//     .
