import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Get, Post } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import DateField from "../../Components/Date/DateField";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Phone from "../../Components/Phone/Phone";
import Text from "../../Components/Text/Text";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";

const EmploymentInformation = ({ setStep }) => {
  const [{ formData, errors, cosignerCheckbox }, dispatch] = useStateValue();
  const { loanid } = useParams();
  let payroll_date = [];
  for (let i = 1; i <= 31; i++) {
    payroll_date.push({ key: i, value: i });
  }
  const emp_status_data = [
    { key: "employed", value: "Employed" },
    { key: "self-employed", value: "Self-employed" },
    { key: "military", value: "Military" },
    // { key: "retired", value: "Retired" },
    { key: "currently unemployed", value: "Currently Unemployed" },
  ];
  const emp_type_data = [
    { key: "full time", value: "Full time" },
    { key: "part time", value: "Part time" },
  ];
  const payroll_freq = [
    { key: "B", value: "Bi-weekly" },
    { key: "W", value: "Weekly" },
    { key: "S", value: "Semi-weekly" },
    { key: "M", value: "Monthly" },
  ];

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const today = new Date();
  let maxHireDate = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    today.getDate()
  );
  let minHireDate = new Date(
    today.getFullYear() - 50,
    today.getMonth(),
    today.getDate()
  );
  const [form, setForm] = useState([
    {
      formComponent: (
        <>
          <Dropdown
            data={emp_status_data}
            name={"employment_status"}
            labelName={"Employment Status"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            name="employer_name"
            labelName={"Employer Name"}
            placeholder="Employer Name"
            handleChange={handleChange}
            required={true}
          />
          <Dropdown
            data={emp_type_data}
            name={"employment_type"}
            labelName={"Employment Type"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            name="jobtitle"
            labelName={"Job Title/Designation"}
            placeholder="Job title/Designation"
            handleChange={handleChange}
            required={true}
          />
          <Text
            name="employer_address"
            labelName={"Employer Address"}
            placeholder="Employer Address"
            handleChange={handleChange}
            required={true}
          />
          <Phone
            name={"phone"}
            labelName="Employer contact number"
            placeholder={"Example: (000) 000-0000"}
            handleChange={handleChange}
            required={true}
          />
          <DateField
            minYear={minHireDate.getFullYear()}
            minDate={minHireDate}
            maxDate={maxHireDate}
            name={"date_of_hire"}
            labelName="Hiring Date"
            placeholder={"MM/DD/YYYY"}
            handleChange={handleChange}
            required={true}
          />
          <Text
            maxLen={3}
            name={"duration"}
            labelName="Duration with Employer (in months)"
            placeholder={"Duration with Employer (in months)"}
            handleChange={handleChange}
            required={true}
          />
          {/* <Dropdown
            data={payroll_freq}
            name="payroll_frequency"
            labelName={"Payroll Frequency"}
            handleChange={handleChange}
          /> */}
          <Dropdown
            data={payroll_date}
            name="payroll_date"
            labelName={"Payroll Date"}
            handleChange={handleChange}
            required={true}
          />
        </>
      ),
    },
  ]);

  const getDetails = async () => {
    try {
      let res = await Get(`loan/getemploymentinfo/${loanid}`, "admin");
      if (res.statusCode === 200) {
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    getDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    if (Object.keys(errors).length === 0) {
      formData.phone = formData.phone.replace(/[^\d]/g, "");
      const bodyData = {
        loan_id: loanid,
        employment: [{ ...formData, isCosignor: cosignerCheckbox }],
      };
      try {
        const resp = await Post("loan/employment", bodyData, "admin");
        if (resp.statusCode === 200) {
          toast.success(resp.message);
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          // setTimeout(() => {
          dispatch({
            type: "SET_STEP",
            payload: 3,
          });
          // }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAdd = () => {
    let prevForm = [...form];
    console.log(prevForm);
    let newForm = prevForm.push(prevForm[0]);
    console.log(newForm);
    setForm(prevForm);
  };
  return (
    <>
      <Toast />
      <form className="row" onSubmit={handleSubmit}>
        {form.map((e, index) => (
            <React.Fragment key={index}>{e.formComponent}</React.Fragment>
        ))}

        <Button type={"submit"} value="Continue" handleClick={handleSubmit} />
      </form>
    </>
  );
};

export default EmploymentInformation;

            {/* <Button type={"button"} value="Add" handleClick={handleAdd} /> */}

