import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Adminsidebar from "../Components/sidebar/adminsidebar";
import logo from "./../assets/images/logo.svg";
import menuicon from "./../assets/images/menu-icon.svg";
import logouticon from "./../assets/images/logout-icon.svg";
import { useNavigate } from "react-router-dom";
import footerlogo from "./../assets/images/whitelogo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import UserProfilelogo from "./../assets/images/userProfile.svg";
import NotificationIcon from "./../assets/images/notification_icon.svg";
import MenuhorizontalIcon from "./../assets/images/menu_horizontal_icon.svg";

const Admin = () => {
  const queryParams = useLocation();
  const navigate = useNavigate()
  //console.log("admin-sidebar------>", queryParams.pathname)
  const [show, setShow] = useState(false);

  const logout = async () => {
    navigate("/admin/login")
    sessionStorage.clear()
  }

  return (
    <div className="bluebackground">
      <div className="logoutblock">

        <div className="logosection">
          <div className="displayFlex">
            <img src={menuicon} className="mobileMenuicon" />
            <img src={footerlogo} className=" " />
          </div>
        </div>


        <ul class="user_admin"  >

          <li className="notificationDropdown">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <img src={NotificationIcon} />

                <span className="count">2</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="displayFlex alignItemscenter spaceBetween mb_12">
                  <div>
                    <h5 className="heading16 mb_4 fontWeight500">
                      Notification <span>(2)</span>
                    </h5>
                  </div>
                  <div>
                    <button className="emptyButton">
                      <img src={MenuhorizontalIcon} />
                    </button>
                  </div>
                </div>

                <div className="notificationItem">
                  <div className="notificationLeft">
                    <img src={UserProfilelogo} />
                  </div>
                  <div className="notification_content">
                    <h5>New Applications Received</h5>
                    <p>3 new applications have been received</p>
                  </div>
                </div>
                <div className="notificationItem">
                  <div className="notificationLeft">
                    <img src={UserProfilelogo} />
                  </div>
                  <div className="notification_content">
                    <h5>Application Triggered</h5>
                    <p>New Application has been triggered</p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className="userProfile">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <div className="displayFlex alignItemscenter">
                  <div className="mr_12">
                    <img src={UserProfilelogo} />
                  </div>
                  <div>
                    <h5>Hello Admin!</h5>
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>

                {/* <Dropdown.Item onClick={() => setShow(true)}>Profile</Dropdown.Item> */}

                <Dropdown.Item onClick={() => { navigate('/admin/admin-security') }}>
                  Security
                </Dropdown.Item>

                <Dropdown.Item onClick={() => {
                  sessionStorage.clear()
                  navigate('/admin/login')
                }}>Logout</Dropdown.Item>
              </Dropdown.Menu>

            </Dropdown>
                  </li>

          {/* <li>
            <a
              class="logoutText"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={logout}
            >
              <img src={logouticon} className=" " /> */}
              {/* <i class="fa fa-fw fa-sign-out"></i> Logout */}


            {/* </a>
          </li> */}
        </ul>
      </div>
      <div
        className={queryParams.pathname !== "/admin/login" && "page_wrapper"}
      >

        {queryParams.pathname !== "/admin/login" && (
          <div>
            <Adminsidebar />
          </div>
        )}
        <div
          className={
            queryParams.pathname !== "/admin/login" && "content_wrapper"
          }
        >
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Admin;
