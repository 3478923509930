import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import SelectLoanAmount from "../../Templates/SelectLoanAmount/SelectLoanAmount";
import StartApplication from '../StartApplication/StartApplication'

const StartPage = ({ show, handleShow, handleClose }) => {
  const [showEmailPhonePopup, setEmailPhonePopup] = useState(false)

  return (
    <>
       <SelectLoanAmount
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        setEmailPhonePopup={setEmailPhonePopup}
      />
      {
        showEmailPhonePopup && <StartApplication />
      }
    </>
  );
};

export default StartPage;
