import React, { useState } from "react";
import RefreshIcon from "../../assets/images/refresh_icon.svg";
import { useStateValue } from "../../Context/StateProvider";
import footerlogo from "../../assets/images/footer-logo.svg";

const MultistepProgressbar = () => {
  const [{ step }, dispatch] = useStateValue();
  const [steps] = useState([
    { id: 1, name: "User Onboarding", step: [1] },
    { id: 2, name: "KYC", step: [2, 3, 4] },
    { id: 3, name: "Underwriting", step: [5] },
    { id: 4, name: "Offers", step: [6] },
    { id: 5, name: "Accept offers", step: [7] },
  ]);

  return (
    <div className="container-fluid displayFlex spaceBetween stepHeader">
      <div className=" ">
        <img src={footerlogo} />
      </div>

      <div className="multistepProgressbar">
        <ul>
          {steps.map((ele, index) => (
            <li
              className={
                step > ele.step[ele.step.length - 1]
                  ? "complete"
                  : ele.step.includes(step)
                  ? "ready"
                  : ""
              }
              key={ele.id}
            >
              <h5>{ele.name}</h5>
              <button>
                <span> {ele.id}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div></div>
    </div>
  );
};

export default MultistepProgressbar;
