import React, { useEffect, useState } from "react";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from "react-bootstrap/Modal";

function Users() {
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  });


  useEffect(() => {
    getUserList();
    getRoles();
  }, []);


  const getUserList = async () => {
    await AuthGet(`users/list`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          setUserData(res?.data);
          setPage(Math.ceil(userData.length / 10));
          setCurrentPage(1);
        }
      })
  }

  const getRoles = async () => {
    await AuthGet(`roles/getadminportalroles`, 'admin')
      .then((res) => {
        if(res?.statusCode === 200){
          setRoleData(res?.data);
        }
      })
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    const input_name = e.target.name;
    const input_value = e.target.value;
    setForm({ ...form, [input_name]: input_value });
  };


  const submit = async (e) => {
    handleClose();
    e.preventDefault();
    let sendData = {
      firstname: form?.firstName.toString(),
      lastname: form?.lastName.toString(),
      email: form?.email.toString(),
      role: parseInt(form?.role)
    }

    await AuthPost(`users/add`, sendData, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          setForm({
            firstName: '',
            lastName: '',
            email: '',
            role: '',
          })
          getUserList();
          getRoles();
        }
      })

  }

  const columns = [
    {
      dataField: "", text: 'S.NO', sort: true, 
      formatter: (e, column, columnIndex, row, rowIndex) => 
      { let total = currentPage * 10;
         total = total - 10; 
         let slNo = total + columnIndex + 1; 
         return slNo; }, headerStyle: { width: '50px', }
    },
      { 
        dataField: "email", text: 'Email', sort: true, 
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            navigate(`/admin/users/${row.id}`);
        },
      },
      style: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline'
      },
      headerStyle: { width: '150px', },
    
    },
       { dataField: "firstname", text: 'First Name', sort: true, headerStyle: { width: '150px', } },
        { dataField: "lastname", text: 'Last Name', sort: true, headerStyle: { width: '100px', } },
         { dataField: "role_name", text: 'Role', sort: true, headerStyle: { width: '150px', } }];

  const defaultSorted = [
    {
      dataField: 'email',
      order: 'desc'
    },
    {
      dataField: 'firstname',
      order: 'desc'
    },
    {
      dataField: 'lastname',
      order: 'desc'
    },
    {
      dataField: 'role_name',
      order: 'desc'
    }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
    }
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (<div>
    <div className="breadcrumbsection">
      <div>
        <div className="titleText ">Portal Users</div>
      </div>
      <button
        className="btn btn-primary  "
        onClick={() => handleShow()}
      >
        Add Portal User
      </button>
    </div>
    <div>
      <Modal show={show} onHide={handleClose} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Add Portal User</h4>
          </div>
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
              <section
                id="createApplicationFromBackend"
                className="start_application"
              >

                <form
                  className="formclass signupForm"
                  style={{ width: '100%' }}
                >
                  <div className="row">
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                        <input
                          placeholder="First Name"
                          id="firstName"
                          name="firstName"
                          className="adminInput form-application"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                        <input
                          placeholder="Last Name"
                          className="adminInput form-application"
                          id="lastName"
                          name="lastName"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                        <input
                          placeholder="Email"
                          className="adminInput form-application"
                          id="email"
                          name="email"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                        <select
                          placeholder="Source of Income"
                          className="adminInput form-application"
                          id="role"
                          name="role"
                          onChange={handleInputChange}
                        >
                          <option disabled selected>Pick Role</option>
                          {roleData.length > 0 && roleData?.map((data) => {
                            const { id, name } = data;
                            return (
                              <option value={id}>{name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            </Modal.Body>
          </div>
        </form>
        <Modal.Footer>
          <div>
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={(e) => submit(e)}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>

    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={userData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  </div>);
}

export default Users;







