import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserInformation from '../Tabs/UserInformation'
import CounterOffer from '../Tabs/CounterOffer'
import Employment from '../Tabs/Employment'
import CreditReport from '../Tabs/CreditReport'
import UploadDoc from '../Tabs/UploadDoc'
import PaymentSchedule from '../Tabs/PaymentSchedule'
import BankAccount from '../Tabs/BankAccount'
import Comments from '../Tabs/Comments'
import Logs from '../Tabs/Logs';
import { AuthGet, AuthPost } from '../../common_var/httpService';
import CustomModal from '../../Components/Modal/Model';
import { useStateValue } from "../../Context/StateProvider";
import BorrowersettingIcon from "../../assets/images/borrower_setting.svg";
import dealIcon2 from "../../assets/images/closebutton.svg";
import pauseicon from "../../assets/images/pause-icon.svg";

import pendingicon from "../../assets/images/pending_icon.svg";

import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
const Loandetails = () => {
    const navigate = useNavigate()
    const queryParams = useLocation()
    const loanParams = queryParams.pathname.slice(7)
    let loanStatus = `${loanParams.charAt(0).toUpperCase() + loanParams.slice(1)} `;
    loanStatus = loanStatus.split('/')[0];
    const [activeTab, setActiveTab] = useState(1);
    const loan_id = sessionStorage.getItem("loan_id");
    const [{ }, dispatch] = useStateValue();

    const handleTabClick = (tabId) => {
        //console.log("ACTIVE", tabId);
        setActiveTab(tabId);
    };

    const handleLoanStageGet = async (URL, navigateTo) => {
        console.log("Loan Id: ", loan_id);
        await AuthGet(`${URL}/${loan_id}`, "admin")
            .then((res) => {
                if (res["statusCode"] == 200) {
                    console.log("Res Data: ", res);
                    navigate(`/admin/${navigateTo}`);
                    toast.success(`Application successfully moved to ${navigateTo}`);
                    // dispatch({
                    //     type: "TOAST",
                    //     toastMessage: `Application successfully moved to ${navigateTo}`,
                    //     toastType: "success",
                    // });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.message);
                // dispatch({
                //     type: "TOAST",
                //     toastMessage: err.message,
                //     toastType: "error",
                // });
            });
    }

    const handleLoanStagePut = async (URL, navigateTo) => {
        console.log("Loan Id: ", loan_id);
        await AuthPost(`${URL}/${loan_id}`, {}, "admin")
            .then((res) => {
                if (res["statusCode"] == 200) {
                    console.log("Res Data: ", res);
                    navigate(`/admin/${navigateTo}`);
                    toast.success(`Application successfully moved to ${navigateTo}`);
                    // dispatch({
                    //     type: "TOAST",
                    //     toastMessage: `Application successfully moved to ${navigateTo}`,
                    //     toastType: "success",
                    // });
                }
            })
            .catch((err) => {
                console.log("Put error: ", err);
                toast.error(err.message);
                // dispatch({
                //     type: "TOAST",
                //     toastMessage: err.message,
                //     toastType: "error",
                // });
            });
    };

    return (
        <div>
            <Toast />
            {/* <section>
        <ol className="breadcrumb">
          <li onClick={() => navigate("/admin/dashboard")}>
            <a>Dashboard</a>
          </li>
          <li className="active">Application Details</li>
          <li className="active">View Application Details</li>
        </ol>
      </section> */}


            <div className="breadcrumbsection">
                <div>   
                    <div className="titleText ">
                        Customer Details
                    </div> 
                </div>
            </div>

            <div className=" ">
                <Tabs
                    transition={true}
                    id="noanim-tab-example"
                    className="mb-3 tabblock  "
                    activeKey={activeTab}
                    onSelect={handleTabClick}
                >
                    <Tab title="Customer Information" eventKey={1}>
                        <UserInformation />
                    </Tab>
                    <Tab title="Credit Check" eventKey={2}>
                        <CreditReport />
                    </Tab>
                    <Tab title="Transactions" eventKey={5}>
                        <PaymentSchedule />
                    </Tab>
                    <Tab title="Doc Vault" eventKey={6}>
                        <UploadDoc />
                    </Tab>
                    <Tab title="Bank Lookup" eventKey={3}>
                        <BankAccount />
                    </Tab>
                    <Tab title="Nymcard" eventKey={4}>
                        <Employment />
                    </Tab>
                    {/* <Tab title="Modify Deal" eventKey={7}>
                        <CounterOffer />
                    </Tab>
                    <Tab title="Ask Customer" eventKey={8}>
                        <Comments />
                    </Tab>
                    <Tab title="Trace Actions" eventKey={9}>
                        <Logs />
                    </Tab> */}
                </Tabs>
            </div>
        </div>
    );
};

export default Loandetails;
