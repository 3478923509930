import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const OnboardingGuard = () => {
  const token = sessionStorage.getItem("borrower_token");
  const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;
  return (
    <>
      <div>
        {expiry > Math.floor(new Date().getTime() / 1000) ? (
          <Outlet />
        ) : (
          <Navigate to="/" />
        )}
      </div>
    </>
  );
};

export default OnboardingGuard;
