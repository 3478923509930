import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import SetPassword from "../../Templates/SetPassword/SetPassword";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import Landing from "../StartPage/Landing";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/images/logo.png";

const SetBorrowerPassword = () => {
  const { token, id } = useParams();
  const [isToken, setIsToken] = useState(false);
  const [{ formData, errors }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const checkToken = async () => {
    const bodyData = {
      token,
      id,
    };
    try {
      const resp = await Post("users/checkToken", bodyData, "borrower");
      if (resp.statusCode === 200) {
        setIsToken(true);
        toast.success(resp.message[0]);
      } else {
        toast.error("Invalid Token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("userId", id);
    checkToken();
  }, []);

  const handleSubmit = async (e) => {
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    e.preventDefault();
    console.log(token);
    if (isToken) {
      try {
        const bodyData = {
          password: formData.newpw,
          confirm_password: formData.confirmpw,
        };
        if (Object.keys(errors).length === 0) {
          const resp = await Post(
            `users/setpassword/${id}`,
            bodyData,
            "borrower"
          );
          if (resp.statusCode === 200) {
            toast.success(resp.message[0]);
            sessionStorage.setItem("borrower_token", resp.data)
            dispatch({
              type: "CLEAR_VALUES",
            });
            dispatch({
              type: "FORM_SUBMIT",
              payload: false,
            });
            // setTimeout(() => {
            navigate("/selectprovider");
            // }, 1500);
          } else if (resp.statusCode === 400) {
            toast.error(resp.message[0]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Toast />
      <Landing />
      <Modal show={true} className="getstartedPopup">
        <Modal.Body>
          <div className="popheader">
            <div>
              <img src={logo} className="logomtop " />
            </div>
            <div></div>
          </div>

          <div>
            <h2>Password Setting </h2>
            <SetPassword
              newPassword={"newpw"}
              confirmPassword={"confirmpw"}
              screenType={"setPassword"}
              handleSubmit={handleSubmit}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SetBorrowerPassword;
