import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Toast from "../../Components/Toaster/Toast";

const MerchantInfo = () => {
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      get(id);
      getLogo(id);
    }
  }, []);

  const getLogo = async (id) => {
    await AuthGet(`practice/getlogo/` + id, "admin")
      .then((res) => {
        if (res["statusCode"] == 200 && res["data"].length > 0) {
          setLogo(res["data"][0]["imageurl"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get = async (id) => {
    await AuthGet(`practice/${id}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setData([res?.practice]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFileChange = async (e) => {
    console.log(e);
    var url = e.target.value;
    var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
    console.log(ext);
    if (
      e.target.files &&
      e.target.files[0] &&
      (ext == "png" || ext == "jpeg" || ext == "jpg")
    ) {
      var reader = new FileReader();

      reader.onload = (e) => {
        setLogo(e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);

      const file = e.target.files[0];
      //  console.log(file)

      let formData = new FormData();
      //  console.log(id)
      formData.append("merchant_id", id);
      formData.append("files", file);
      //  console.log(formData)

      await AuthPost("logoupload/uploads", formData, "admin")
        .then((res) => {
          if (res["statusCode"] == 200) {
            toast.success(res["message"]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Only jpg/jpeg and png files are allowed!");
    }
  };

  return (
    <>
      <Toast />
      {data ? console.log(data) : ""}
      {data?.map((data, i) => {
        return (
          <div heading="Merchant Information" key={data.id}>
            <div className="p10px">
              <div className="displayFlex AlignItemCenter spaceBetween p10px frameshadow">
                <div className="fs22px displayFlex AlignItemCenter spaceBetween mr30px">
                  <div className="row">
                    <div className="col-10">
                      <div className="profilePhoto mr30px">
                        {/* {console.log(logo)} */}
                        {logo ? (
                          <img src={logo} alt="img" />
                        ) : (
                          <i className="fa fa-user" aria-hidden="true"></i>
                        )}
                      </div>
                      <div className="align">
                        <div className="nowrap">
                          {data?.merchantname}
                          {/* ({
                    data?.merchant_id
                  }) */}
                        </div>
                        <div className="col-2">
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            placeholder="change logo"
                            onChange={($event) => onFileChange($event)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <hr />
                    </div>
                    {/* <div className="col-3">Merchant Image</div>
            { merchantImage && merchantImage.length>0 ?   <div className="col-3" >
              <a
                 href={ merchantImage[0]['store_imageurl'] }
                target="_blank"
                >View</a
              >
            </div>: <div className="col-3">
              No Image
            </div>}
          
         
            <div className="col-3">
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                placeholder="change logo"
                // (change)="changeimage($event)"
                onChange={($event)=>changeimage($event)}
              />
            </div> */}
                  </div>
                </div>
              </div>
              <br />
              <br />

              <div className="list frameshadow">
                <ul className="h700">
                  {/* <li>
            <div className="displayFlex listdetails">
              <div className="fontWeight"></div>
              <div>
                <button
                  type="button"
                  className="btn btn-success btn-user-control"
                  onClick={() => handleShow()}
                >
                  <i className="fa fa-user" aria-hidden="true"></i> Edit
                </button>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-success btn-user-control"
                //   (click)="open(change_status_temp)"
                >
                  Change Status
                </button>
                &nbsp;
                {
                    data?.is_popular ?  <button
                    type="button"
                    className="btn btn-success btn-user-control"
                  
                  onClick={()=>{popular(false)}}
                  
                  >
                    Unset popular
                  </button> : <button
                  type="button"
                  className="btn btn-success btn-user-control"
                //   (click)="popular(true)"
                onClick={()=>{popular(true)}}
                //   *ngIf="!data?.['is_popular']"
                >
                  Set popular
                </button>
                }
               
               
               
              </div>
            </div>
          </li> */}
                  {/* <li>
            <div className="displayFlex listdetails">
              <div className="fontWeight">Merchant Reference</div>
              <div>
                { data?.ref_no }
              </div>
            </div>
          </li> */}
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Business Contact</div>
                      <div>{data?.contactname}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Email</div>
                      <div>{data?.email}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Merchant Name</div>
                      <div>{data?.merchantname}</div>
                    </div>
                  </li>

                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Merchant Url</div>
                      <div>{data?.url}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Phone Number</div>
                      <div>{data?.phonenumber}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Street</div>
                      <div>{data?.streetaddress}</div>
                    </div>
                  </li>

                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Merchant Vat ID</div>
                      <div>{data?.merchantvatid}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Country</div>
                      <div>{data?.country}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">City</div>
                      <div>{data?.city}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">State</div>
                      <div>{data?.statecode}</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex listdetails">
                      <div className="fontWeight">Zipcode</div>
                      <div>{data?.zipcode}</div>
                    </div>
                  </li>
                  {/* <li>
            <div className="displayFlex listdetails">
              <div className="fontWeight">Country</div>
              <div>
                { data?.country }
              </div>
            </div>
          </li> */}
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  {/* <li>
            <div className="displayFlex listdetails">
              <div className="fontWeight">Status</div>
              <div>{ data?.status }</div>
            </div>
          </li> */}

                  {/* <li>
            <div className="displayFlex listdetails">
              <div className="fontWeight">Registered Date</div>
              <div>{ data?.createdAt }</div>
            </div>
          </li> */}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MerchantInfo;
