import React from 'react'

const CreditReport = () => {
    return (
        <div>
            <h2>Credit Report</h2>
            <div className="frameshadow mb20px fs16px">No Credit Report Found!</div>
        </div>
    )
}

export default CreditReport
