import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import TextValidator from "./TextValidator";

const Text = ({
  name,
  handleChange,
  labelName,
  placeholder,
  maxLen,
  required,
}) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <>
      <div className="col-md-6 mb_24">
        {errors[name] ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <input
          maxLength={maxLen}
          type="text"
          id={name}
          placeholder={placeholder}
          name={name}
          className="MainInput"
          onChange={(e) => handleChange(e)}
          onBlur={() => setIsTouched(true)}
          value={formData[name] ? `${formData[name]}` : null}
          style={errors[name] ? { border: "1px solid #f44336" } : null}
        />
        {required ? (
          <div style={{ display: "none" }}>
            <TextValidator maxLen={maxLen}
              value={formData[name]}
              name={name}
              labelName={labelName}
              isTouched={isTouched}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Text;
