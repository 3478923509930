import React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../Toaster/Toast";

const Guard = ({ data }) => {
  console.log(data.key)
  let key = data.key;
  
  let navigate = useNavigate();
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  }
  const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;
  console.log(expiry > Math.floor(new Date().getTime() / 1000))
  // const clear = (key) => {   
  //   console.log('working')
  //   let url = `/${key}/login`;
  //   sessionStorage.clear();
  //   navigate(url);
  // };
  return (
    <>
      <Toast />
      <div>
        {expiry > Math.floor(new Date().getTime() / 1000) ? (
          <Outlet />
        ) : key == "admin" ? (
          <Navigate to="/admin/login" />
          // clear(key)
        ) : key == "borrower" ? (
          <Navigate to="/borrower/login" />
          // clear(key)
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Guard;
