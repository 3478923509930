import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet, AuthPost, Get, Post } from "../../common_var/httpService";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import { useStateValue } from "../../Context/StateProvider";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Modal from "react-bootstrap/Modal";
import Landing from "../../Pages/StartPage/Landing";
import logo from "../../assets/images/logo.png";

const SelectDealer = () => {
  const navigate = useNavigate();
  const [dealer, setDealer] = useState([]);
  const [{ formData, errors }, dispatch] = useStateValue();

  const getDealer = async () => {
    try {
      const resp = await Get("loan/getallpractices", "admin");
      if (resp.statusCode === 200) {
        let d = resp.data;
        d.map((ele) => {
          ele.key = ele.id;
          ele.value = `${ele.practicename}`;
        });
        setDealer(d);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDealer();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    const userId = sessionStorage.getItem("userId");
    const loanAmount = localStorage.getItem("loan_amount");
    const bodyData = {
      ...formData,
      loan_amount: +loanAmount,
      user_id: userId,
    };
    try {
      if (Object.keys(errors).length === 0) {
        const resp = await Post(`loan/initiateloan`, bodyData, "admin");
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          sessionStorage.setItem("loan_id", resp.loan_id);
          toast.success(resp.message);
          // setTimeout(() => {
          navigate(`/onboarding/${resp.loan_id}`);
          // }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  return (
    <div>
      <Toast />
      <Landing />
      <Modal show={true} className="getstartedPopup">
        <Modal.Body>
          <div className="popheader">
            <div>
              <img src={logo} className="logomtop " />
            </div>
            <div></div>
          </div>

          <h2 className="p20">Health Care Provider </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb_24">
              <Dropdown
                data={dealer}
                handleChange={handleChange}
                labelName={"Your Health Care Provider"}
                name="practice_id"
              />
            </div>
            <button type="submit" className="MainButton btn_Primary">
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SelectDealer;
