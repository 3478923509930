import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import DropdownValidator from "./DropdownValidator";

const Dropdown = ({ data, handleChange, name, labelName, required }) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div className="col-md-6 mb_24">
      {errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <select
        value={formData[name]}
        className="MainInput"
        name={name}
        id={name}
        onChange={(e) => handleChange(e)}
        onBlur={() => setIsTouched(true)}
        style={errors[name] ? { border: "1px solid #f44336" } : null}
      >
        <option value="">Select {labelName}</option>
        {data?.map((opt, i) => {
          return (
            <option key={i} value={opt.key}>
              {opt.value}
            </option>
          );
        })}
      </select>
      {required ? (
        <div style={{ display: "none" }}>
          <DropdownValidator
            name={name}
            value={formData[name]}
            isTouched={isTouched}
            labelName={labelName}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dropdown;
