import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import UseExternalScript from "../../Components/UseExternalScript/UseExternalScript";
import { useLocation } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import Accordion from "react-bootstrap/Accordion";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
const BankAccount = () => {
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  const customerId = params[params.length - 1];
  const page = 1;

  const [currentPage, setCurrentPage] = useState(1);
  const [dapiData, setDapiData] = useState({});
  const [mail, setMail] = useState("");
  const [accData, setAccData] = useState([]);
  const [cusData, setCusData] = useState([]);
  const [frame, setFrame] = useState(false);

  const checkbank = async () => {
    await AuthGet(`saltedge/checkdata/${customerId}`, "admin")
      .then((res) => {
        console.log("res salt---->", res.data, res.cusdata);
        if (res.data.length > 0) {
          setFrame(true);
          setCusData(res.cusdata);
          setAccData(res.data);
        } else {
          setFrame(false);
          getEmail();
        }
      })
      .catch((err) => {
        console.log("err salt---->", err);
      });
  };

  const getEmail = async () => {
    await AuthGet("saltedge/getEmail/" + customerId, "admin")
      .then((res) => {
        if (res.data != undefined) {
          setMail(res.data[0].email);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitbtn = async () => {
    await AuthGet("saltedge/requestBank/" + customerId, "admin")
      .then((res) => {
        // alert("Email")
        toast.success("Email Sent To Borrower");
        if (res.statusCode === 200) {
          alert("Email Sent successfully")

          // toast.success("Email Sent To Borrower");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        return slNo;
      },
    },
    {
      dataField: "edge_trans_id",
      text: "Transaction ID",
      sort: true,
    },
    {
      dataField: "trans_description",
      text: "Transaction Description",
      sort: true,
    },
    {
      dataField: "trans_amount",
      text: "Transaction Amount",
      sort: true,
    },
    {
      dataField: "payee_name",
      text: "Payment By",
      sort: true,
    },
    {
      dataField: "payer_name",
      text: "Paid To",
      sort: true,
    },
    {
      dataField: "trans_made_on",
      text: "Transaction Date",
      sort: true,
    },
  ];

  //sort
  const defaultSorted = [
    {
      dataField: "edge_trans_id",
      order: "desc",
    },
    {
      dataField: "trans_description",
      order: "desc",
    },
    {
      dataField: "trans_amount",
      order: "desc",
    },
    {
      dataField: "payee_name",
      order: "desc",
    },
    {
      dataField: "payer_name",
      order: "desc",
    },
    {
      dataField: "trans_made_on",
      order: "desc",
    },
  ];

  //pagination
  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {},
  });
  const { SearchBar, ClearSearchButton } = Search;

  useEffect(() => {
    checkbank();
  }, []);

  return (
    // <div className="frameshadow">
    //   <div className="displayFlex flexEnd gap20 AlignItemCenter">
    //     <button
    //       className="btn btn-primary"
    //       onClick={() => ConnectBankUsingDapi()}
    //     >
    //       Connect
    //     </button>
    //     <button className="btn btn-primary">Add Bank Manually</button>
    //   </div>

    //   <div>
    //     <div className="frameshadow mb20px">
    //       <div>
    //         <div className="frameshadow mb20px">
    //           <h5>Bank Accounts</h5>
    //           <div>
    //             <BootstrapTable
    //               bootstrap4
    //               keyField="id"
    //               pagination={pagination}
    //               data={[]}
    //               columns={columns}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <Toast />
      <div>
        {frame == false && (
          <div className="connect_bank_popup whiteFrame p16 ">
            <h5 className="mb_24 heading24 textAlignCenter">
              Ask Customer to link bank account via Email
            </h5>

            <div className="mb_24 heading16 textAlignCenter">{mail}</div>

            <div className="textAlignCenter">
              <button className="MainButton btn_Primary" onClick={submitbtn}>
                Send
              </button>
            </div>
          </div>
        )}
        <div>
          <div>
            {accData?.length > 0 && frame == true ? (
              <div>
                <div className="tabHeader">
                  <div>
                    <h5>Bank Account</h5>
                  </div>
                </div>
                <div className="customTabContent">
                  <div className="customAccordion">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Salt Edge</Accordion.Header>
                        <Accordion.Body>
                          <h5 className="heading16 fontWeight500 mb_12">
                            User Information
                          </h5>

                          <div className="commondetailTable tableResponsive">
                            <table>
                              <tr>
                                <th>Name</th>
                                <td>
                                  {cusData[0]?.firstname} {cusData?.lastname}
                                </td>
                              </tr>
                              <tr>
                                <th>Email</th>
                                <td>{cusData[0]?.email}</td>
                              </tr>
                              <tr>
                                <th>Phone</th>
                                <td>{cusData[0]?.phone}</td>
                              </tr>
                              <tr>
                                <th>Address </th>
                                <td>{cusData[0]?.streetaddress}</td>
                              </tr>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Chase</Accordion.Header>
                        <Accordion.Body>
                          {accData.map((item, index) => {
                            return (
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    {item?.bankdetails.edge_acc_name}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="commondetailTable tableResponsive">
                                      <table>
                                        <tr>
                                          <th> Account Holder Name</th>
                                          <td>
                                            {item?.bankdetails.acc_holder}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Account Type</th>
                                          <td>
                                            {item?.bankdetails.acc_nature}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Account Currency Code</th>
                                          <td>
                                            {
                                              item?.bankdetails
                                                .acc_currency_code
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Account Number</th>
                                          <td>
                                            {item?.bankdetails.acc_number}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Account Current Balance</th>
                                          <td>
                                            {item?.bankdetails.acc_balance}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Account Available Balance</th>
                                          <td>
                                            {item?.bankdetails.acc_balance}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="whiteFrame">
                                      <ToolkitProvider
                                        bootstrap4
                                        keyField="id"
                                        data={item?.transactiondetails}
                                        columns={columns}
                                        search
                                      >
                                        {(props) => (
                                          <>
                                            <div className="mb_12 whiteFrameHeader">
                                              <h3 className="heading18 fontWeight600 textTransform">
                                                Transaction Details -last 90
                                                Days
                                              </h3>
                                            </div>
                                            <div className="p16">
                                              <div className="mb_12 displayFlex justifyConentEnd">
                                                <SearchBar
                                                  {...props.searchProps}
                                                />
                                                <ClearSearchButton
                                                  {...props.searchProps}
                                                />
                                              </div>
                                              <div className="TableWrapper tableResponsive">
                                                <BootstrapTable
                                                  bootstrap4
                                                  key="loan_ref"
                                                  defaultSorted={defaultSorted}
                                                  pagination={pagination}
                                                  {...props.baseProps}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </ToolkitProvider>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccount;
