import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import Password from "../../Components/Password/Password";
import { useStateValue } from "../../Context/StateProvider";

const SetPassword = ({
  oldPassword,
  newPassword,
  confirmPassword,
  screenType,
  handleSubmit,
}) => {
  const [{}, dispatch] = useStateValue();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {screenType !== "setPassword" ? (
          <Password
            name={oldPassword}
            placeholder={"Old Password"}
            labelName="Old Password"
            minLen={8}
            required={true}
          />
        ) : (
          <></>
        )}

        <Password
          name={newPassword}
          placeholder={"Password"}
          labelName={screenType !== "setPassword" ? "New Password" : "Password"}
          minLen={8}
          handleChange={handleChange}
          required={true}
        />
        <small className="smaller">
          <span className="requiredText">*</span> Minimum 8 characters long with
          atleast 1 uppercase, 1 lowercase, 1 numeric and 1 special character
        </small>
        <br />
        <br />
        <Password
          name={confirmPassword}
          placeholder={"Confirm Password"}
          labelName="Confirm Password"
          minLen={8}
          handleChange={handleChange}
          required={true}
        />
        <div></div>
        <br />
        <div>
          <Button
            type={"submit"}
            value={"Continue"}
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default SetPassword;
